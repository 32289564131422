export const config = {
    api: {
        baseUrl: 'https://api.theprintshopmaster.com/v1',
    },
    stripe: {
        key: 'pk_live_51OtWvyHCvwOMIt4q9llT5VNe6Hd1zqudrEgWpFEtzzvywOpMm5azDq7nph2oFBFwihteQtwa0ssmGtZjb6TEmqQQ00h1o1B7CK'
    }
}

export class AUTHENTICATION {
    static get LOGIN_URL() {
        return config.api.baseUrl + '/auth/login';
    }

    static get RESET_PASSWORD() {
        return config.api.baseUrl + '/auth/reset-forgotten-password';
    }

    static get FORGET_PASSWORD() {
        return config.api.baseUrl + '/auth/send-forgot-password-verification-code';
    }

    static get SIGN_UP() {
        return config.api.baseUrl + '/auth/sign-up';
    }
}

export class USERS {
    static get USER() {
        return config.api.baseUrl + '/user';
    }
    static get VALID_PASSWORD() {
        return config.api.baseUrl + '/user/validate-password'
    }

    static get USER_DETAILS_BY_USER_UUID() {
        return config.api.baseUrl + '/user/{user_uuid}'
    }

    static get CREATE_CUSTOMER_CARD_PAYMENT_METHOD() {
        return config.api.baseUrl + '/user/create-card-payment-method';
    }

    static get CUSTOMER_PAYMENT_METHOD_LIST() {
        return config.api.baseUrl + '/user/{user_uuid}/list-card-payment-methods';
    }

    static get DELETE_CARD_PAYMENT_METHOD() {
        return config.api.baseUrl + '/user/delete-customer-card-payment-method';
    }
}

export class Quote {
    static get QUOTE() {
        return config.api.baseUrl + '/quote';
    }

    static get SAVE_QUOTE() {
        return config.api.baseUrl + '/quote';
    }

    static get DELETE_QUOTE() {
        return config.api.baseUrl + '/quote/{quote_item_uuid}/delete-quote-item';
    }

    static get SAVE_ITEM_TO_QUOTE() {
        return config.api.baseUrl + '/quote/add-item-to-quote';
    }

    static get ADD_ITEM_IMAGE() {
        return config.api.baseUrl + '/quote/add-item-image';
    }

    static get DELETE_ITEM_IMAGE() {
        return config.api.baseUrl + '/quote/delete-item-image';
    }

    static get EDIT_QUOTE() {
        return config.api.baseUrl + '/quote/{quote_uuid}';
    }

    static get Get_sent_quote_client_identity_info_by_Quote_ID() {
        return config.api.baseUrl + '/sent-quote-client-identity';
    }

    static get Submit_sent_quote_client_identity_info() {
        return config.api.baseUrl + '/sent-quote-client-identity/submit';
    }
    static get Change_quote_status() {
        return config.api.baseUrl + '/quote/change-quote-status';
    }
    static get GET_CUSTOMER_QUOTE_CLIENT_LIST() {
        return config.api.baseUrl + '/quote/{customer_uuid}/customer-quote-client-list';
    }
}

export class CUSTOMERS_CLIENT {
    static get DELETE() {
        return config.api.baseUrl + '/customer-client';
    }
    static get CREATE_CUSTOMER() {
        return config.api.baseUrl + '/customer-client';
    }
    static get UPDATE_CLIENT() {
        return config.api.baseUrl + '/customer-client';
    }
}

export class QUOTE_MASTER {
    static get GARMENT_MASTER_LIST() {
        return config.api.baseUrl + '/quote-master/{user_uuid}/garments';
    }

    static get STICH_COUNTS_MASTER_LIST() {
        return config.api.baseUrl + '/quote-master/{user_uuid}/stitch-counts';
    }

    static get SETUP_MASTER_LIST() {
        return config.api.baseUrl + '/quote-master/{user_uuid}/setup';
    }

    static get TECHNICAL_SPEFICFICATION_MASTER_LIST() {
        return config.api.baseUrl + '/quote-master/{user_uuid}/technical-specification';
    }

    static get PRICING_MATRIX_MASTER_LIST() {
        return config.api.baseUrl + '/quote-master/pricing-matrix';
    }

    static get SETUP_DEFAULT_RATE() {
        return config.api.baseUrl + '/quote-master/{user_uuid}/update-setup-rates';
    }

    static get RESET_TO_DEFAULT() {
        return config.api.baseUrl + '/quote-master/reset-to-default';
    }
}

export class SHOP_TAX {
    static get ADD_TAX() {
        return config.api.baseUrl + '/customer-shop-tax-master/add-tax';
    }

    static get EDIT_TAX() {
        return config.api.baseUrl + '/customer-shop-tax-master/edit-tax';
    }

    static get DELETE_TAX() {
        return config.api.baseUrl + '/customer-shop-tax-master/{customer_shop_tax_master_uuid}/delete-tax';
    }

    static get TAX_LIST() {
        return config.api.baseUrl + '/customer-shop-tax-master/{customer_shop_info_uuid}/list-customer-shop-tax';
    }
}

export class INVOICE {
    static get INVOICE_LIST() {
        return config.api.baseUrl + '/invoice/{user_uuid}/invoice-list';
    }

    static get GET_INVOICE_DETIAL() {
        return config.api.baseUrl + '/invoice/{invoice_uuid}/get-invoice-details';
    }

    static get CREATE_INVOICE() {
        return config.api.baseUrl + '/invoice';
    }

    static get EDIT_INVOICE() {
        return config.api.baseUrl + '/invoice/{invoice_uuid}';
    }

    static get DELETE_INVOICE() {
        return config.api.baseUrl + '/invoice/{invoice_uuid}'
    }

    static get DELETE_INVOICE_ITEM() {
        return config.api.baseUrl + '/invoice/{invoice_item_uuid}/delete-invoice-item';
    }

    static get SEND_INVOICE() {
        return config.api.baseUrl + '/invoice/send-invoice';
    }

    static get CHANGE_INVOICE_STATUS() {
        return config.api.baseUrl + '/invoice/change-invoice-status';
    }

    static get DOWNLOAD_INVOICE() {
        return config.api.baseUrl + '/invoice/download-invoice';
    }

    static get MARK_AS_READ() {
        return config.api.baseUrl + '/invoice/mark-as-read';
    }

    static get PAY_INVOICE() {
        return config.api.baseUrl + '/invoice/pay-invoice';
    }
}

export class USER_SUBSCRIPTION_PLAN {
    static get CREATE_USER_SUBSCRIPTION_PLAN(){
        return config.api.baseUrl + '/user-subscription-plan';
    }

    static get GET_SUBSCRIPTION_DETAIL_BY_USER_ID() {
        return config.api.baseUrl + '/user-subscription-plan/{user_uuid}/user-subscription-plan-by-user-id';
    }

    static get CREATE_SUBSCRIPTION_PAYMENT() {
        return config.api.baseUrl + '/user-subscription-plan/create-subscription-payment';
    }

    static get UPDATE_USER_SUBSCRIPTION_PLAN() {
        return config.api.baseUrl + '/user-subscription-plan/{user_uuid}/edit-user-subscription';
    }
}
export class SUBSCRIPTION_PLAN {
    static get GET_SUBSCRIPTION_PLAN_LIST() {
        return config.api.baseUrl + '/subscription-plan';
    }

    static get GET_DISCOUNT_INFO_BY_SUBSCRIPTION_ID(){
        return config.api.baseUrl + '/subscription-plan/{subscription_uuid}/discount-list';
    }
}

export class DASHBOARD {
    static get ADMIN_DASHBOARD_STATISCRICS() {
        return config.api.baseUrl + '/user/{user_uuid}/admin-dashboard-statistics';
    }

    static get TOTAL_SUBSCRIBERS_STATISCTICS() {
        return config.api.baseUrl + '/user/{user_uuid}/total-subscribers-statistics';
    }

    static get GET_CUSTOMER_DASHBOARD_STATISTIC() {
        return config.api.baseUrl + '/user/{user_uuid}/customer-dashboard-statistics';
    }
    static get GET_CUSTOMER_SENT_QUOTE_AND_INVOICE() {
        return config.api.baseUrl + '/user/{user_uuid}/customer-sent-quote-invoice-statistics';
    }
}

export class SHOP_OWNER_PAYMENT {
    static get VERIFY_STRIPE_ACCOUNT_ONBOARDING() {
        return config.api.baseUrl + '/customer-stripe-connect/{user_uuid}/verify-account-onboarding-completion';
    }

    static get GENERATE_STRIPE_CONNECT_ACCOUNT_ONBOARDING_LINK() {
        return config.api.baseUrl + '/customer-stripe-connect/generate-account-onboarding-link';
    }

    static get UNLINK_ACCOUNT() {
        return config.api.baseUrl + '/customer-stripe-connect/{user_uuid}/unlink-account';
    }

    static get UPDATE_BANK_ACCOUNT() {
        return config.api.baseUrl + '/customer-stripe-connect/update-account-bank-details';
    }
}

export class TAX_DEDUCTION {
    static get TAX_DEDUCTION_LIST() {
        return config.api.baseUrl + '/user/{user_uuid}/customers-subscription-payment-list';
    }
}