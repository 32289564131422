import React, { useEffect, useState, useMemo } from "react";
import { Card, CardBody, Col, Container, Row, UncontrolledDropdown, UncontrolledTooltip, DropdownToggle, DropdownMenu, DropdownItem, Spinner, Badge, Modal, ModalBody, ModalHeader } from "reactstrap";
import TableContainer from "../../../shared/components/common/tableContainer";
import Breadcrumb from "../../../shared/components/common/breadcrumb";
import { useNavigate } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import { toast, ToastContainer } from "react-toastify";
import './invoiceList.scss';
import { QuoteService } from '../../../quotes/service/quote.service';
import { InvoiceService } from '../../service/invoice.service'
import ConfirmationModal from '../../../shared/components/common/confirmationModal';
import moment from 'moment';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';
import { ImageURLTOBase64Convertor } from "../../../shared/helpers/imageUrlToBase64";

const InvoiceList = () => {
    const quoteService = new QuoteService();
    const [invoiceList, setInvoiceList] = useState([]);
    const [allInvoiceList, setAllInvoiceList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    // check device
    const [isMediumDevice, setIsMediumDevice] = useState(false);
    const [isExtraSmallDevice, setIsExtraSmallDevice] = useState(false);
    const [isExtraExtraSmallDevice, setIsExtraExtraSmallDevice] = useState(false);

    // for filter
    const [invoiceListClientFilter, setInvoiceListClientFilter] = useState('');
    const [quoteStatusFilter, setQuoteStatusFilter] = useState('All statuses');

    //delete invoice
    const [deleteModal, setDeleteModal] = useState(false);
    const [afterAction, setAfterAction] = useState(false);
    const [invoiceId, setInvoiceId] = useState();

    // send quote
    const [isSendInvoice, setIsSendInvoice] = useState(false);
    const [invoice, setInvoice] = useState();
    const [customerQuoteClientList, setCustomerQuoteClientList] = useState([]);
    const [actionQuote, setActionQuote] = useState('');

    // navigation
    const navigate = useNavigate();
    const invoiceService = new InvoiceService();

    const handleDropdownToggleClick = (event) => {
        // Prevent the event from propagating to the table row
        event.stopPropagation();
    };

    useEffect(() => {
        geInvoiceListInfo();
    }, []);

    // get quote list
    const geInvoiceListInfo = (searchedText) => {
        setLoading(true);
        invoiceService.getInvoiceList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid, searchedText).then((response) => {
            if (response.status === 200) {
                setAllInvoiceList(response.data);
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        filteredQuoteList();
    }, [invoiceListClientFilter, quoteStatusFilter, allInvoiceList]);

    const filteredQuoteList = () => {
        let filteredList = allInvoiceList;
        // Filter by client name
        if (invoiceListClientFilter !== '') {
            filteredList = filteredList.filter(obj => obj.client_name === invoiceListClientFilter);
        }
        // Filter by quote status
        if (quoteStatusFilter !== 'All statuses' && (quoteStatusFilter === "Draft" || quoteStatusFilter === 'Sent' || quoteStatusFilter === 'Read' || quoteStatusFilter === 'Paid' || quoteStatusFilter === 'Cancelled')) {
            filteredList = filteredList.filter(obj => obj.invoice_status.toLowerCase() === quoteStatusFilter.toLowerCase());
        } else if (quoteStatusFilter === 'Overdue') {
            let requiredStatus = filteredList.filter(obj => {
                return (
                    (obj.invoice_status === 'draft' || obj.invoice_status === 'read' || obj.invoice_status === 'sent')
                );
            });
            filteredList = requiredStatus.filter((obj) => {
                return (
                    (moment(obj.due_date).isBefore(moment(), 'day'))
                )
            });
        } else if (quoteStatusFilter === 'All statuses') {
            filteredList = filteredList
        }
        // Update the state with the filtered list
        setInvoiceList(filteredList);
    }

    const onSelectedStatus = (select) => {
        setQuoteStatusFilter(select.label);
    }

    // for check device
    useEffect(() => {
        function handleResize() {
            window.innerWidth <= 1024 ? setIsMediumDevice(true) : setIsMediumDevice(false);
            window.innerWidth <= 610 ? setIsExtraSmallDevice(true) : setIsExtraSmallDevice(false);
            window.innerWidth <= 281 ? setIsExtraExtraSmallDevice(true) : setIsExtraExtraSmallDevice(false);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onSelectedClient = (select) => {
        select.label !== 'All client' ? setInvoiceListClientFilter(select.label) : setInvoiceListClientFilter('');
    }

    const onselectedDurationForDownlaod = (duration) => {
        let startDate, endDate;
        // select start and end date according to selected duration!
        switch (duration) {
            case 'Today':
                startDate = moment().startOf('day').toDate();
                endDate = moment().endOf('day').toDate();
                break;
            case 'Week':
                startDate = moment().startOf('isoWeek').toDate();
                endDate = moment().endOf('isoWeek').toDate();
                break;
            case 'Month':
                startDate = moment().startOf('month').toDate();
                endDate = moment().endOf('month').toDate();
                break;
            case 'Year':
                startDate = moment().startOf('year').toDate();
                endDate = moment().endOf('year').toDate();
                break;
            default:
                startDate = moment(duration[0]).toDate();
                endDate = moment(duration[1]).toDate();
                break;
        }

        // filter out invoice based on paid status and between selected duration
        let filteredInvoices = invoiceList.filter((obj) => obj.invoice_status === 'paid' && moment(UTCtoLocal(obj.issued_on)).isBetween(startDate, endDate, null, '[]'));
        if (filteredInvoices.length === 0) {
            toast.warn('No paid invoices are available within the selected time period.');
        } else {
            setIsDownloadLoading(true);
            getDetailsForFilteredInvoicesAsync(filteredInvoices).then(invoiceDetail => {
                let invoiceData = transformInvoiceItems(invoiceDetail);
                // make array of field to export
                const fieldToExport = invoiceData.map((invoiceInfo) => ({
                    ContactName: invoiceInfo.clientInfo.name || "null",
                    EmailAddress: invoiceInfo.clientInfo.email || "null",
                    AddressLine1: invoiceInfo.clientInfo.address || "null",
                    AddressLine2: "null",
                    AddressLine3: "null",
                    AddressLine4: "null",
                    City: invoiceInfo.clientInfo.city || "null",
                    Region: invoiceInfo.clientInfo.state || "null",
                    PostalCode: invoiceInfo.clientInfo.zipCode || "null",
                    Country: invoiceInfo.clientInfo.country || "null",
                    InvoiceNumber: invoiceInfo.invoiceMeta.number || "null",
                    Reference: invoiceInfo.invoiceMeta.referenceNumber || "null",
                    InvoiceDate: invoiceInfo.dateInfo.issuedOn,
                    DueDate: invoiceInfo.dateInfo.dueDate,
                    InventoryItemCode: "null",
                    Description: invoiceInfo.invoiceItem.description || "null",
                    Quantity: invoiceInfo.invoiceItem.quantity || "null",
                    UnitAmount: invoiceInfo.invoiceItem.price || "null",
                    Discount: "null",
                    AccountCode: "null",
                    TaxType: invoiceInfo.invoiceItem.taxDetails.map(tax => `${tax?.tax_name}(${tax?.tax_percentage}%)`)
                        .join(', ') || "null",
                    TrackingName1: "null",
                    TrackingOption1: "null",
                    TrackingName2: "null",
                    TrackingOption2: "null",
                    Currency: "USD",
                    BrandingTheme: "null"
                }));

                // make csv to export
                const workSheet = XLSX.utils.json_to_sheet(fieldToExport);
                const workBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workBook, workSheet, 'sheet1');
                XLSX.writeFile(workBook, `Paid invoices from ${moment(startDate).format('YYYY-MM-DD')} to ${moment(endDate).format('YYYY-MM-DD')}.csv`);
                setIsDownloadLoading(false);
            });

        }
    };

    async function getDetailsForFilteredInvoicesAsync(filteredInvoices) {
        const detailedInvoices = await Promise.all(filteredInvoices.map(async (obj) => {
            const invoice = await invoiceService.getInvoiceDetailByInvoiceId(obj.invoice_uuid);
            return { ...obj, details: invoice ? invoice.data : null };
        }));
        return detailedInvoices;
    }

    function transformInvoiceItems(invoices) {
        const transformedItems = [];
        invoices.forEach(invoice => {
            const clientInfo = {
                companyName: invoice.client_company_name,
                email: invoice.client_email,
                name: invoice.client_name,
                address: invoice.details.client_address,
                city: invoice.details.client_city,
                state: invoice.details.client_state,
                country: invoice.details.client_country,
                zipCode: invoice.details.client_zip_code,
            };

            const dateInfo = {
                createdAt: invoice.details.created_at,
                dueDate: invoice.details.due_date,
                issuedOn: invoice.details.issued_on,
                updatedAt: invoice.details.updated_at,
            };

            invoice.details.invoice_items.forEach(item => {
                const newItem = {
                    clientInfo,
                    dateInfo,
                    invoiceItem: {
                        uuid: item.invoice_item_details.invoice_item_uuid,
                        name: item.invoice_item_details.item_name,
                        description: item.invoice_item_details.item_description || "null",
                        price: item.invoice_item_details.item_price,
                        quantity: item.invoice_item_details.item_quantity,
                        detailsCreatedAt: item.invoice_item_details.created_at,
                        taxDetails: item.invoice_item_details.invoice_item_tax_details || [],
                    },
                    invoiceMeta: {
                        uuid: invoice.details.invoice_uuid,
                        number: invoice.details.invoice_number,
                        status: invoice.details.invoice_status,
                        notes: invoice.details.invoice_notes,
                        paymentAmount: invoice.details.invoice_payment_amount,
                        paymentMethod: invoice.details.invoice_payment_method,
                        paymentStatus: invoice.details.invoice_payment_status,
                        isSent: invoice.details.is_sent,
                        quoteClientInfoUuid: invoice.details.quote_client_info_uuid,
                        quoteUuid: invoice.details.quote_uuid,
                        referenceNumber: invoice.details.reference_number,
                        sentOn: invoice.details.sent_on,
                    }
                };
                transformedItems.push(newItem);
            });
        });
        return transformedItems;
    }

    // for preview qute
    const previewInvoice = (event, data) => {
        event.stopPropagation();
        navigate(`/preview-invoice`, { state: { mode: 'previewInvoice', invoice_uuid: data.invoice_uuid } });
    }

    const isDeleteHandle = () => {
        invoiceService.deleteInvoice(invoiceId).then((response) => {
            if (response.status === 200) {
                setAfterAction(true);
                geInvoiceListInfo();
            }
        });
    }

    const isSendHandle = () => {
        const sendInvoiceId = {
            "invoice_uuid": invoice.invoice_uuid
        }
        invoiceService.sendInvoice(sendInvoiceId).then((response) => {
            if (response.status === 200) {
                setAfterAction(true);
                geInvoiceListInfo();
                setIsSendInvoice(false);
            }
        });
    }

    const changeInvoiceStatus = () => {
        const sendQuoteInfo = {
            "invoice_uuid": invoice.invoice_uuid,
            "status": actionQuote === 'void' ? 'cancelled' : (actionQuote === 'paid' ? 'paid' : 'unpaid'),
        }
        if (actionQuote === 'paid') {
            sendQuoteInfo.payment_amount = parseFloat(parseFloat(+invoice.total_amount).toFixed(2));
        }
        invoiceService.changeInvoiceStatus(sendQuoteInfo).then((response) => {
            if (response.status === 200) {
                geInvoiceListInfo();
            }
        }).finally(() => {
            setAfterAction(true);
        });
    }

    // for delete invoice & send invoice
    const onClickAction = (event, data, isSend) => {
        event.stopPropagation();
        setAfterAction(false);
        setDeleteModal(true);
        setInvoiceId(data.invoice_uuid);
        setIsSendInvoice(isSend);
        setInvoice(data);
    }

    // Utility function to convert HTML string to PDF and download it
    const downloadPdf = async (htmlString, file_name) => {
        // Create a temporary container to hold the HTML content
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
         
        document.body.appendChild(tempDiv);

        // Find all images in the HTML and convert them to base64
        const images = tempDiv.querySelectorAll('img');
        const imagePromises = Array.from(images).map(async (img) => {
            const url = img.src;
            if (url.startsWith('http')) {
                const base64 = await ImageURLTOBase64Convertor.convertImageUrlToBase64(url);
                img.src = base64;
            }
        });

        // Wait for all images to be converted
        await Promise.all(imagePromises);

        // Set up options for html2pdf
        const opt = {
            margin: [0.2, 0.1, 0.2, 0.1],
            filename: file_name,
            html2canvas: {
                scale: 2,
            },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
            enableLinks: true
        };

        // Convert the HTML to PDF
        try {
            await html2pdf().from(tempDiv).set(opt).save();
        } catch (error) {
            console.error("PDF generation error:", error);
        } finally {
            document.body.removeChild(tempDiv);
        }
    };

    // for download quote
    const onClickDownload = (event, selectedInvoice) => {
        setIsDownloadLoading(true);
        const invoice = {
            "invoice_uuid": selectedInvoice.invoice_uuid
        }
        invoiceService.downloadInvoice(invoice).then(async (response) => {
            if (response.status === 200) {
                setTimeout(async () => {
                    await downloadPdf(response.data,  `${'invoice'}_${selectedInvoice.invoice_number}.pdf`)
                    setIsDownloadLoading(false);
                }, 2000);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.errors[0]?.message, { autoClose: 4000 });
            setIsDownloadLoading(false);
        });
        event.stopPropagation();
    }

    // for convert date to local
    const UTCtoLocal = (utcDate) => {
        const date = new Date(utcDate);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(localDate);
        return formattedDate;
    }

    const [isNoteModel, setIsNoteModel] = useState();
    const [invoice_notes, setJobNotes] = useState();

    const openNoteModel = (event, data) => {
        event.stopPropagation();
        setIsNoteModel(true);
        setJobNotes(data.invoice_notes);
    }

    // quote list Column
    const columns = useMemo(
        () => [
            {
                Header: 'Invoice no',
                accessor: 'invoice_number',
                Cell: (cellProps) => {
                    return <div>
                        {!isLoading && <div className="position-relative d-flex align-items-center">{cellProps.row.original.invoice_number}<i className="bx bx-info-circle position-relative cursor-pointer quote-id-info ms-1 text-yellow mb-2px" id="quoteInfo">
                            <div className="quote-id-tooltip">
                                <p className="mb-0">No. of items: {cellProps.row.original.invoice_item_count}</p>
                            </div>
                        </i></div>}
                        {isLoading && <Skeleton height={20} width={60} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton>}
                    </div>
                }
            },
            {
                Header: 'Recipient',
                accessor: 'job_name',
                filterable: true,
                maxWidth: 400,
                minWidth: 140,
                width: 200,
                Cell: (cellProps) => {
                    return <>
                        {(!isLoading && cellProps.row.original?.client_name) && <span className={`job-name ${cellProps.row.original?.client_name?.length > (isExtraExtraSmallDevice ? 17 : (isExtraSmallDevice ? 17 : (isMediumDevice ? 17 : 28))) ? 'customer_ellipsis' : ''}`} data-toggle='tooltip'
                            data-title={cellProps.row.original?.client_name}>{cellProps.row.original?.client_name}</span>}
                        {
                            !isLoading && (
                                <p className={`mb-0 gray-email job-name ${cellProps.row.original.client_email?.length > (isExtraExtraSmallDevice ? 18 : (isExtraSmallDevice ? 18 : (isMediumDevice ? 15 : 25))) ? 'customer_ellipsis shop-email-tooltip' : ''}`} id="emailTooltip"
                                    data-toggle='tooltip' data-title={cellProps.row.original.client_email}>{cellProps.row.original.client_email}
                                </p>
                            )
                        }
                        {isLoading &&
                            <div>
                                <Skeleton className={`mb-0 ${isMediumDevice ? 'width-100' : ''} `} width={195} height={20} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton>
                                <Skeleton className={`mb-0 ${isMediumDevice ? 'width-100' : ''} `} width={195} height={20} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton>
                            </div>
                        }
                    </>
                }
            },
            {
                Header: 'Issued on',
                accessor: 'issued_on',
                filterable: true,
                Cell: (cellProps) => {
                    return <div>
                        {(!isLoading && cellProps.row.original.issued_on) && <p className="mb-0 quote-date-div">{UTCtoLocal(cellProps.row.original.issued_on)}</p>}
                        {isLoading &&
                            <div><Skeleton className='mb-0' width={80} height={20} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton></div>
                        }
                    </div >;
                }
            },
            {
                Header: 'Due on',
                accessor: 'due_date',
                filterable: true,
                sortBy: true,
                Cell: (cellProps) => {
                    return <>
                        {(!isLoading && cellProps.row.original.due_date) && (
                            <p className={`mb-0 quote-date-div ${(
                                cellProps.row.original.invoice_status === 'read' ||
                                cellProps.row.original.invoice_status === 'sent' ||
                                cellProps.row.original.invoice_status === 'draft'
                            ) && moment(cellProps.row.original.due_date).isBefore(moment(), 'day') ? 'text-danger' : ''}`}>
                                {UTCtoLocal(cellProps.row.original.due_date)}
                            </p>
                        )}
                        {isLoading &&
                            <div><Skeleton className='mb-0' width={80} height={20} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton></div>
                        }
                    </>;
                }
            },
            {
                Header: 'Total',
                accessor: 'total_amount',
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        <>
                            {!isLoading ? (
                                <p className={`mb-1 company-name-email ${cellProps?.row?.original?.total_amount?.length > (isExtraExtraSmallDevice ? 20 : (isExtraSmallDevice ? 20 : (isMediumDevice ? 20 : 26))) ? 'customer_ellipsis company-name-tooltip shop-email-tooltip' : ''}`}
                                    data-toggle='tooltip' data-title={cellProps?.row?.original?.total_amount}>
                                    ${cellProps?.row?.original?.total_amount === undefined ? '00.00' : cellProps?.row?.original?.total_amount}
                                </p>
                            ) : (
                                <Skeleton className={`mb-1 ${isMediumDevice ? 'company-name-email' : ''} `} height={20} width={80} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton>
                            )}
                        </>
                    </>;
                }
            },
            {
                Header: 'Status',
                accessor: 'invoice_status',
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {!isLoading && (cellProps.row.original.invoice_status) && <>
                            <Badge className={` ${cellProps.row.original.invoice_status === 'draft' ? 'draft-status' : cellProps.row.original.invoice_status === 'sent' ? 'sent-status' : cellProps.row.original.invoice_status === 'read' ? 'read-status' : cellProps.row.original.invoice_status === 'paid' ? 'approved-status' : cellProps.row.original.invoice_status === 'cancelled' ? 'declined-status' : ''}   me-1 quote-date-div status-badge text-dark w-100`}>
                                {cellProps.row.original.invoice_status.toUpperCase()}
                            </Badge>
                        </>
                        }
                        {isLoading && <>
                            <Skeleton className={`mb-1 ${isMediumDevice ? 'company-name-email' : ''} `} height={20} width={100} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton>
                        </>
                        }
                    </>;
                }
            },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    return <div className="d-flex align-items-center justify-content-center">
                        {!isLoading &&
                            <>
                                <div className="invoice-notes-container ps-1 me-2">
                                    {
                                        (cellProps.row.original.invoice_notes !== "" && cellProps.row.original.invoice_notes !== 'null' && cellProps.row.original.invoice_notes !== '<p><br></p>' && cellProps.row.original.invoice_notes !== null) && (
                                            <span>
                                                <i className="bx bx-file-blank notes-icon position-relative cursor-pointer me-1  quote-note-info" id={`invoice_notes${cellProps.row.original.invoice_uuid}`} onClick={(e) => { e.stopPropagation(); openNoteModel(e, cellProps.row.original) }}></i>
                                                <UncontrolledTooltip placement="bottom" target={`invoice_notes${cellProps.row.original.invoice_uuid}`}>
                                                    Invoice notes
                                                </UncontrolledTooltip>
                                            </span>
                                        )
                                    }
                                </div>
                                <UncontrolledDropdown className="action-dropdown">
                                    <DropdownToggle tag="a" className="card-drop" onClick={(e) => handleDropdownToggleClick(e)}>
                                        <i className="mdi mdi-dots-horizontal font-size-18 action-icon "></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem
                                            onClick={(e) => {
                                                const invoiceData = cellProps.row.original;
                                                previewInvoice(e, invoiceData)
                                            }} className="d-flex align-items-center"
                                        >
                                            <i className="dripicons-preview font-size-16 me-2 dropdown-menu-icon" id="previewtooltip"></i>
                                            <span className="dropdown-menu-text">Preview</span>
                                        </DropdownItem>
                                        {cellProps.row.original.invoice_status !== 'cancelled' && <DropdownItem
                                            onClick={(e) => {
                                                const invoiceData = cellProps.row.original;
                                                onClickAction(e, invoiceData, true);
                                                setActionQuote('send');
                                            }} className="d-flex align-items-center">
                                            <i className="bx bx-send font-size-16 me-2 dropdown-menu-icon" id="sendtooltip"></i>
                                            <span className="dropdown-menu-text">Send</span>
                                        </DropdownItem>}
                                        <DropdownItem
                                            onClick={(e) => {
                                                const invoiceData = cellProps.row.original;
                                                onClickDownload(e, invoiceData);
                                            }} className="d-flex align-items-center">
                                            <i className="bx bx-download font-size-16 me-2 dropdown-menu-icon" id="downloadtooltip"></i>
                                            <span className="dropdown-menu-text">Download</span>
                                        </DropdownItem>
                                        {cellProps.row.original.invoice_status !== 'cancelled' && <DropdownItem
                                            onClick={(e) => {
                                                const invoiceData = cellProps.row.original;
                                                onClickAction(e, invoiceData, false);
                                                setActionQuote('void');
                                            }} className="d-flex align-items-center">
                                            <i className="bx bx-x-circle font-size-18 me-2 dropdown-menu-icon" id="downloadtooltip"></i>
                                            <span className="dropdown-menu-text">Void</span>
                                        </DropdownItem>}
                                        {
                                            (cellProps.row.original.invoice_status === 'paid') && (
                                                <DropdownItem onClick={(e) => {
                                                    const invoiceData = cellProps.row.original;
                                                    onClickAction(e, invoiceData, false);
                                                    setActionQuote('unpaid');
                                                }}
                                                    className="d-flex align-items-center">
                                                    <i className="fab fa-creative-commons-nc font-size-16 me-2 dropdown-menu-icon" id="downloadtooltip"></i>
                                                    <span className="dropdown-menu-text">Mark as unpaid</span>
                                                </DropdownItem>
                                            )
                                        }
                                        {
                                            !(cellProps.row.original.invoice_status === 'paid' || cellProps.row.original.invoice_status === 'cancelled') && (
                                                <DropdownItem onClick={(e) => {
                                                    const invoiceData = cellProps.row.original;
                                                    onClickAction(e, invoiceData, false);
                                                    setActionQuote('paid');
                                                }} className="d-flex align-items-center">
                                                    <i className="bx bx-dollar-circle font-size-18 me-2 dropdown-menu-icon" id="downloadtooltip"></i>
                                                    <span className="dropdown-menu-text">Mark as paid</span>
                                                </DropdownItem>
                                            )
                                        }
                                        {
                                            ((cellProps.row.original.invoice_status === 'sent') || (cellProps.row.original.invoice_status === 'read') || (cellProps.row.original.invoice_status === 'draft')) && (
                                                <DropdownItem
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        navigate('/preview-invoice', { state: { mode: 'editMode', invoice_uuid: cellProps.row.original.invoice_uuid } });
                                                    }} className="d-flex align-items-center">
                                                    <i className="mdi mdi-pencil font-size-16 me-2 text-success dropdown-menu-icon" id="edittooltip"></i>
                                                    <span className="dropdown-menu-text">Edit</span>
                                                    <p>{cellProps.row.original.invoice_status === 'cancelled'}</p>
                                                </DropdownItem>
                                            )
                                        }
                                        <DropdownItem
                                            onClick={(e) => {
                                                const quoteData = cellProps.row.original;
                                                onClickAction(e, quoteData, false);
                                                setActionQuote('delete')
                                            }} className="d-flex align-items-center">
                                            <i className="mdi mdi-trash-can font-size-16 me-2 text-danger dropdown-menu-icon" id="deletetooltip"></i>
                                            <span className="dropdown-menu-text">Delete</span>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </>
                        }
                        {isLoading && <Skeleton className="mb-1" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" width={20}></Skeleton>}
                    </div>
                }
            },
        ],
        [isLoading, isExtraSmallDevice, isExtraExtraSmallDevice, isMediumDevice]
    );

    useEffect(() => {
        getCustomerQuoteClientList();
    }, []);

    const getCustomerQuoteClientList = () => {
        quoteService.getCustomerQuoteClientList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
            if (response.status === 200) {
                response.data.unshift({ client_name: 'All client' });
                setCustomerQuoteClientList(response.data);
            }
        });
    }

    return (
        <React.Fragment>
            {/* quote list table */}
            <div className="page-content invoice-list-page pb-0">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb
                        breadcrumbItem="Invoices"
                        buttonName="Create Invoice"
                        handleCreate={() => { navigate('/preview-invoice', { state: 'create-new' }); }}
                        downloadInvoiceCSVButton={true}
                        onselectedDuartionForDownload={onselectedDurationForDownlaod}
                    />
                    <Row>
                        <Col xs="12" className="invoice-list">
                            <Card className="position-relative mb-0 list-card">
                                {<CardBody>
                                    {/* list table */}
                                    {<TableContainer className='w-100'
                                        columns={columns}
                                        data={invoiceList.length < 6 && isLoading ? [0, 1, 2, 3] : invoiceList}
                                        isPagination={false}
                                        isAddOptions={false}
                                        searchPlaceholder={'Search by invoice no, Recipient name, company name & email'}
                                        tableClass="align-middle table-nowrap"
                                        theadClass="sticky-header"
                                        onSearch={(search) => { geInvoiceListInfo(search); }}
                                        selectedClientValue={onSelectedClient}
                                        selectedStatusValue={onSelectedStatus}
                                        isLoading={isLoading}
                                        customerQuoteClientList={customerQuoteClientList}
                                        listName={'invoice-list'}
                                    />}
                                </CardBody>}
                                {isDownloadLoading && <Spinner className='me-2 position-absolute download-quote-spinner' />}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* toast message */}
            <ToastContainer />
            {/* invoice notes */}
            <Modal className='delete-customer-modal' size="md" isOpen={isNoteModel} centered={true}>
                <ModalHeader toggle={() => setIsNoteModel(false)} tag="h4">
                    Invoice Notes
                </ModalHeader>
                <ModalBody>
                    {
                        invoice_notes && (
                            <div dangerouslySetInnerHTML={{ __html: invoice_notes.toString('html') }}></div>
                        )
                    }
                </ModalBody>
            </Modal>
            <ConfirmationModal
                show={deleteModal}
                onCloseClick={() => setDeleteModal(false)}
                title={actionQuote === 'send' ? 'Send invoice' : actionQuote === 'delete' ? 'Delete Invoice' : actionQuote === 'unpaid' ? 'Mark as unpaid' : actionQuote === 'paid' ? 'Mark as paid' : 'Cancel invoice'}
                description={actionQuote === 'send' ? '' : actionQuote === 'delete' ? 'Are you sure you want to delete this invoice?' : actionQuote === 'unpaid' ? 'Are you sure you want to mark this invoice as unpaid?' : actionQuote === 'paid' ? 'Are you sure you want to mark this Invoice as paid?' : 'Are you sure you want to mark this invoice as cancelled?'}
                onAction={() => actionQuote === 'send' ? isSendHandle() : actionQuote === 'delete' ? isDeleteHandle() : (actionQuote === 'void' || actionQuote === 'paid' || actionQuote === 'unpaid') ? changeInvoiceStatus() : ''}
                isQuoteDelete={true}
                afterAction={afterAction}
                isSendQuote={isSendInvoice}
                actionName={actionQuote === 'send' ? 'Send' : actionQuote === 'delete' ? 'Delete' : 'Confirm'}
                quote={invoice}
            />
            <ToastContainer />
        </React.Fragment>
    );
}

export default InvoiceList;