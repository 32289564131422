import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
// loader
import Spinners from './spinner';
// for css
import '../../../quotes/component/quoteList/quoteList.scss';

const ConfirmationModal = ({ show, onCloseClick, title, description, onAction, afterAction, actionName, isSendQuote, quote }) => {
  const [isLoading, setLoading] = useState(false);

  // api call for action
  const onDeleteClick = () => {
    setLoading(true);
    onAction();
  };

  // after action api call
  useEffect(() => {
    if (afterAction) {
      setLoading(false);
      onCloseClick();
    }
  }, [afterAction]);

  return (
    <Modal className='delete-customer-modal' size="md" isOpen={show} centered={true}>
      {/* header */}
      <ModalHeader toggle={() => onCloseClick()} tag="h4">
        {/* Delete {isQuoteDelete ? 'Quote' : 'customer'} */}
        {title}
      </ModalHeader>
      <div className="modal-content">
        <ModalBody>
          {!isSendQuote ? <p className="text-muted font-size-16 mb-4">
            {description}
          </p> :
            <>
              {
                title === 'Send quote' ? <p className="text-muted font-size-16 mb-4">Are you sure you want to send quote to<span className='color-primary'> {quote?.client_name}</span>?</p> :
                <p className="text-muted font-size-16 mb-4">Are you sure you want to send invoice to<span className='color-primary'> {quote?.client_name}</span>?</p>
              }
              {/* company details */}
              <p className='mb-4'>Client Email:<span className='color-secondary email-break'> {quote?.client_email}</span></p>
            </>
          }
          {/* footer */}
          <div className="hstack justify-content-end mb-0">
            <button type="button" className={`${title === 'Confirmation' ? 'btn btn-outline' : 'btn-delete-cancel'}  me-3`} onClick={onCloseClick}>Cancel</button>
            <button type="button" className={`btn ${isSendQuote || title === 'Confirmation' ? 'btn-fill' : (actionName === 'Decline' || actionName === 'Delete') ? 'btn-danger' : 'btn-fill'} d-flex`} onClick={onDeleteClick}>
              {isLoading ? <Spinners setLoading={setLoading} /> : ''}
              {actionName}</button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
}

export default ConfirmationModal
