import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { AWS_S3_URL, SUBSCRIPTION_PLAN_UUID } from "../../static/constants/constant";

import { Link } from "react-router-dom";
import withRouter from "./withRouter";

import { useUser } from '../../../../../src/module/profile/component//userContext';

import { CustomerService } from "../../../customers/service/customer.service";

import { useAtom } from 'jotai';

import { authenticatedUserInfo } from '../../../../../src/atoms'
// css
import './profileMenu.scss';

const ProfileMenu = props => {


  const customerService = new CustomerService();

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [userName, setuserName] = useState("");
  const [getProfilePicture, setProfilePicture] = useState();

  // get session storage
  const [getUserInfo, setUserInfo] = useAtom(authenticatedUserInfo);
  // for  update header after edit profile
  const { userData } = useUser();

  useEffect(() => {
    setuserName(userData.username);
    if (userData.profilePicture !== '') {
      setProfilePicture(userData.profilePicture);
    }
  }, [userData]);

  // clear session storage and global varibale
  const onclickLogOut = () => {
    sessionStorage.clear();
    setUserInfo(null);
    window.location.reload();
  }

  useEffect(() => {
    getCustomerDetail();
  }, []);

  // for set user name and profile pic in header
  const getCustomerDetail = () => {
    customerService.getCustomerDetail(getUserInfo?.data.user_uuid).then(response => {
      if (response.status === 200) {
        setuserName(response.data[0].name);
        setProfilePicture(response.data[0].profile_pic);
      }
    });
  }

  return (
    <React.Fragment>
      {/* header dropdown */}
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block profile-dropdown"
      >
        <DropdownToggle
          className="btn header-item d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          {(getProfilePicture !== 'null' && getProfilePicture !== null) && <img className='rounded-circle header-profile-user' src={`${getProfilePicture && getProfilePicture.includes('blob:') ? getProfilePicture : AWS_S3_URL + getProfilePicture}`} alt="Profile_icon" />}
          {(getProfilePicture === 'null' || getProfilePicture === null) &&
            <div className="avtar-x">
              <div className="rounded-circle header-profile-user avatar-title bg-primary-subtle text-primary bg-soft p-2">{userName.split(" ")[1] ? userName.split(" ")[0].charAt(0) + '' + userName.split(" ")[1].charAt(0) : userName.split(" ")[0]?.slice(0, 2)}</div>
            </div>
          }
          {/* <span className="d-none d-xl-inline-block ms-2 me-1 username-text" id="userName">{userName}</span> */}
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile" className="dropdown-item" onClick={() => setMenu(!menu)}>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-2" />
            <span className="profile-menu-item-text">Profile</span>
          </Link>
          {
            getUserInfo?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.STANDARD_PLAN && getUserInfo?.data?.role_name === 'customer' && (
              <Link to='/get-paid' className="dropdown-item" onClick={() => setMenu(!menu)} >
                <i className="mdi-bank-plus mdi home-icon font-size-16 align-middle pe-1" />
                <span className="profile-menu-item-text"> Get paid</span>
              </Link>
            )
          }
          <div onClick={onclickLogOut}>
            <Link to="/login" className="dropdown-item" onClick={() => {
              localStorage.clear();
              sessionStorage.clear();
              window.location.reload();
            }}>
              {" "}
              <i className="bx bx-power-off font-size-16 align-middle me-2 text-danger" />
              <span className="profile-menu-item-text">Logout</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any
};



export default withRouter(ProfileMenu);
