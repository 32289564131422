import { Col, Row, Modal, ModalBody, ModalHeader, Label, Input, FormFeedback } from "reactstrap";
import { useFormik } from "formik";
import { NEW_RECIPIENT } from "../../static/constants/constant";
import * as Yup from 'yup';
import Spinners from './spinner';
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { PeopleType } from "../../../customersClientList/model/people.model";
import './addClientModal.scss';
// validation error 
import { registerValidationErr, Regex } from '../../../shared/static/constants/constant';

const AddClientModal = ({ show, title, onCloseClick, afterAction, actionName, onAction, fromPeople, people, getError }) => {

    const [isLoading, setLoading] = useState(false);
    // for select dropdown
    const [selectedGroup, setselectedGroup] = useState();
    const peopleTypeOption = [
        { label: PeopleType.Lead, value: PeopleType.Lead },
        { label: PeopleType.Client, value: PeopleType.Client },
        { label: PeopleType.Other, value: PeopleType.Other }];

    useEffect(() => {
        if (people) {
            let selectedType = peopleTypeOption.find((e) => e.value?.toLowerCase() === people.type?.toLowerCase());
            setselectedGroup(selectedType);
        } else {
            setselectedGroup({ label: PeopleType.Lead, value: PeopleType.Lead });
        }
    }, [show, people]);

    const addRecipientForm = useFormik({
        initialValues: {
            client_company_name: people ? people.client_company_name : null,
            client_email: people ? people.client_email : null,
            client_address: people ? people.client_address : '',
            client_name: people ? people.client_name : '',
            client_city: people ? people.client_city : '',
            client_state: people ? people.client_state : '',
            client_country: people ? people.client_country : '',
            client_zip_code: people ? people.client_zip_code : null,
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            client_company_name: Yup.string().required(NEW_RECIPIENT.ERR_NAME_REQUIRED).matches(/[^\d]/, NEW_RECIPIENT.ERR_INVALID_NAME),
            client_email: Yup.string().required(NEW_RECIPIENT.ERR_EMAIL_REQUIRED).email(NEW_RECIPIENT.ERR_INVALID_EMAIL),
            client_address: Yup.string() // Address validation
                .max(80, NEW_RECIPIENT.ERR_ADD_LENGTH)
                .nullable(),
            client_name: Yup.string().required(NEW_RECIPIENT.ERR_INVALID_CLIENT_NAME).matches(/^[A-Za-z ]*$/, NEW_RECIPIENT.ERR_INVALID_CLIENT_NAME),
            client_city: Yup.string().matches(Regex.ONLY_CHAR, registerValidationErr.ERR_CITY_INVALID).nullable(),
            client_state: Yup.string().matches(Regex.ONLY_CHAR, registerValidationErr.ERR_STATE_INVALID).nullable(),
            client_country: Yup.string().matches(Regex.ONLY_CHAR, registerValidationErr.ERR_COUNTRY_INVALID).nullable(),
            client_zip_code: Yup.string().matches(Regex.ZIP_CODE, registerValidationErr.ERR_INVALID_ZIP_CODE).nullable()
        }),
        onSubmit: (values) => {
            delete values.quote_client_info_uuid
            if (fromPeople) {
                const client = {
                    client_company_name: addRecipientForm.values.client_company_name,
                    client_email: addRecipientForm.values.client_email,
                    client_address: addRecipientForm.values.client_address ? addRecipientForm.values.client_address : '',
                    client_name: addRecipientForm.values.client_name,
                    type: selectedGroup?.value.toLowerCase(),
                    client_city: addRecipientForm.values.client_city ? addRecipientForm.values.client_city : '',
                    client_state: addRecipientForm.values.client_state ? addRecipientForm.values.client_state : '',
                    client_country: addRecipientForm.values.client_country ? addRecipientForm.values.client_country : '',
                    client_zip_code: addRecipientForm.values.client_zip_code ? addRecipientForm.values.client_zip_code : ''
                };
                onAction(client);
            } else {
                onAction({ invoice_client_details: values });
            }
            setLoading(true);
        },
    });

    // after action api call
    useEffect(() => {
        if (afterAction) {
            setLoading(false);
            onCloseClick();
            addRecipientForm.resetForm();
            setselectedGroup({ label: PeopleType.Lead, value: PeopleType.Lead })
        }
    }, [afterAction]);

    // after action api call
    useEffect(() => {
        if (getError) {
            setLoading(false);
        }
    }, [getError]);

    return (
        <Modal className='add-client' size="md" isOpen={show} centered={true}>
            {/* header */}
            <ModalHeader toggle={() => { onCloseClick(); addRecipientForm.resetForm(); }} tag="h4">
                {title}
            </ModalHeader>
            <div className="modal-content">
                <ModalBody>
                    <form onSubmit={addRecipientForm.handleSubmit}>
                        <Row className="increaseHeight">
                            <Col>
                                <Row>
                                    <div className="mb-3 col-lg-6">
                                        <label htmlFor="clientName" className="form-label">Client Name<span className='text-danger'> *</span></label>
                                        <input
                                            type="text"
                                            id="clientName"
                                            name="client_name"
                                            placeholder="Enter Client Name"
                                            className={`form-control ${addRecipientForm.touched.client_name && addRecipientForm.errors.client_name ? 'is-invalid' : ''}`}
                                            onChange={addRecipientForm.handleChange}
                                            onBlur={addRecipientForm.handleBlur}
                                            value={addRecipientForm.values.client_name}
                                        />
                                        {addRecipientForm.touched.client_name && addRecipientForm.errors.client_name && <div className="invalid-feedback">{addRecipientForm.errors.client_name}</div>}
                                    </div>
                                    {/* </Row>
                                <Row> */}
                                    <div className="mb-3 col-lg-6">
                                        <label htmlFor="taxName" className="form-label">Company Name<span className='text-danger'> *</span></label>
                                        <input
                                            type="text"
                                            id="taxName"
                                            name="client_company_name"
                                            placeholder='Enter Company Name'
                                            className={`form-control ${addRecipientForm.touched.client_company_name && addRecipientForm.errors.client_company_name ? 'is-invalid' : ''}`}
                                            onChange={addRecipientForm.handleChange}
                                            onBlur={addRecipientForm.handleBlur}
                                            value={addRecipientForm.values.client_company_name}
                                        />
                                        {addRecipientForm.touched.client_company_name && addRecipientForm.errors.client_company_name && <div className="invalid-feedback">{addRecipientForm.errors.client_company_name}</div>}
                                    </div>
                                </Row>
                                <Row className='mb-3'>
                                    <Col className={fromPeople ? 'col-lg-6 col-12 mb-lg-0 mb-3' : 'col-12'}>
                                        <label htmlFor="taxRate" className="form-label">{fromPeople ? 'Contact Email' : 'Client Email'}<span className='text-danger'> *</span></label>
                                        <input
                                            type="text"
                                            id="taxName"
                                            name="client_email"
                                            placeholder='Enter Email'
                                            className={`form-control ${addRecipientForm.touched.client_email && addRecipientForm.errors.client_email ? 'is-invalid' : ''}`}
                                            onChange={addRecipientForm.handleChange}
                                            onBlur={addRecipientForm.handleBlur}
                                            value={addRecipientForm.values.client_email}
                                        />
                                        {addRecipientForm.touched.client_email && addRecipientForm.errors.client_email && <div className="invalid-feedback">{addRecipientForm?.errors?.client_email}</div>}
                                    </Col>

                                    {/* {fromPeople && <Row className='mb-3'> */}
                                    {fromPeople && <Col className={fromPeople ? 'col-lg-6 col-12' : 'col-12'}>
                                        <label htmlFor="taxRate" className="form-label">Type</label>
                                        <Select
                                            value={selectedGroup}
                                            onChange={(selectedGroupName) => {
                                                setselectedGroup(selectedGroupName);
                                            }}
                                            options={peopleTypeOption.map((e, key) => {
                                                return { label: e.label, value: e.value }
                                            })}
                                            className="form-control select2-selection p-0"
                                        />
                                    </Col>}
                                </Row>
                                <Row className="mb-3">
                                    <Col className="">
                                        <label className="form-label">Address</label>
                                        <textarea
                                            name="client_address"
                                            id="client_address"
                                            value={addRecipientForm.values.client_address}
                                            onChange={addRecipientForm.handleChange}
                                            onBlur={addRecipientForm.handleBlur}
                                            placeholder="Enter address"
                                            className={`form-control ${addRecipientForm.errors.client_address ? 'is-invalid' : ''}`}
                                        ></textarea>
                                        {addRecipientForm.errors.client_address && <div className="invalid-feedback">{addRecipientForm?.errors?.client_address}</div>}
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    {/* client city */}
                                    <Col lg={6} className="mb-lg-0 mb-3">
                                        <Label for="clientCity">
                                            City
                                        </Label>
                                        <Input
                                            type="text"
                                            className={`form-control ${addRecipientForm.touched.client_city && addRecipientForm.errors.client_city ? 'is-invalid' : ''}`}
                                            id="clientCity"
                                            name="client_city"
                                            placeholder="Enter City"
                                            value={addRecipientForm.values.client_city || ''}
                                            onChange={addRecipientForm.handleChange}
                                            onBlur={addRecipientForm.handleBlur}
                                            invalid={addRecipientForm.touched.client_city && addRecipientForm.errors.client_city ? true : false}
                                        />
                                        {addRecipientForm.touched.client_city && addRecipientForm.errors.client_city ? (<FormFeedback type="invalid">{addRecipientForm.errors.client_city}</FormFeedback>) : null}
                                    </Col>
                                    {/* client state */}
                                    <Col lg={6}>
                                        <Label for="clientState">
                                            State
                                        </Label>
                                        <Input
                                            type="text"
                                            className={`form-control ${addRecipientForm.touched.client_state && addRecipientForm.errors.client_state ? 'is-invalid' : ''}`}
                                            id="clientState"
                                            placeholder="Enter State"
                                            name="client_state"
                                            value={addRecipientForm.values.client_state || ''}
                                            onChange={addRecipientForm.handleChange}
                                            onBlur={addRecipientForm.handleBlur}
                                            invalid={addRecipientForm.touched.client_state && addRecipientForm.errors.client_state ? true : false}
                                        />
                                        {addRecipientForm.touched.client_state && addRecipientForm.errors.client_state ? (<FormFeedback type="invalid">{addRecipientForm.errors.client_state}</FormFeedback>) : null}
                                    </Col>
                                </Row>
                                {/* client country */}
                                <Row className="mb-3">
                                    <Col lg={6} className="mb-lg-0 mb-3">
                                        <Label for="clientCountry">
                                            Country
                                        </Label>
                                        <Input
                                            type="text"
                                            className={`form-control ${addRecipientForm.touched.client_country && addRecipientForm.errors.client_country ? 'is-invalid' : ''}`}
                                            id="clientCountry"
                                            placeholder="Enter Country"
                                            name="client_country"
                                            value={addRecipientForm.values.client_country || ''}
                                            onChange={addRecipientForm.handleChange}
                                            onBlur={addRecipientForm.handleBlur}
                                            invalid={addRecipientForm.touched.client_country && addRecipientForm.errors.client_country ? true : false}
                                        />
                                        {addRecipientForm.touched.client_country && addRecipientForm.errors.client_country ? (<FormFeedback type="invalid">{addRecipientForm.errors.client_country}</FormFeedback>) : null}
                                    </Col>
                                    {/* client zip code */}
                                    <Col lg={6}>
                                        <Label for="clientZipCode">
                                            Zip Code
                                        </Label>
                                        <Input
                                            type="text"
                                            className={`form-control ${addRecipientForm.touched.client_zip_code && addRecipientForm.errors.client_zip_code ? 'is-invalid' : ''}`}
                                            id="clientZipCode"
                                            placeholder="Enter Zip Code"
                                            name="client_zip_code"
                                            value={addRecipientForm.values.client_zip_code || ''}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                // Remove non-numeric characters and limit to 10 characters
                                                let formattedValue = value.replace(/[^0-9]/g, '').slice(0, 10);

                                                // Add hyphen after the fifth character, but only if it's not already there
                                                if (formattedValue.length > 5) {
                                                    formattedValue = `${formattedValue.slice(0, 5)}-${formattedValue.slice(5)}`;
                                                }

                                                // Update the form state
                                                addRecipientForm.handleChange({
                                                    target: {
                                                        name: 'client_zip_code',
                                                        value: formattedValue,
                                                    },
                                                });
                                            }}
                                            onBlur={addRecipientForm.handleBlur}
                                            onKeyDown={(e) => {
                                                // Allow Backspace if the length is greater than 0
                                                if (e.key === 'Backspace' && e.target.value.length > 0) {
                                                    return;
                                                }

                                                // Limit to 10 characters including hyphen
                                                if (e.target.value.length >= 10 && e.key !== 'Backspace') {
                                                    e.preventDefault();
                                                }
                                                // Allow numeric digits, hyphen, and Backspace
                                                if (!(
                                                    (e.key >= '0' && e.key <= '9') ||
                                                    e.key === 'Backspace' || e.key === '-'
                                                )) {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                        {addRecipientForm.touched.client_zip_code && addRecipientForm.errors.client_zip_code ? (<FormFeedback type="invalid">{addRecipientForm.errors.client_zip_code}</FormFeedback>) : null}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* footer */}
                        <div className="hstack justify-content-end mb-0">
                            <button type="button" className="btn-outline me-3" onClick={onCloseClick}>Cancel</button>
                            <button type="submit" className={`btn btn-fill  d-flex`} disabled={!addRecipientForm.isValid || addRecipientForm.values.client_company_name === null || addRecipientForm.values.client_email === null || addRecipientForm.values.client_company_name === ' '}>
                                {isLoading ? <Spinners setLoading={setLoading} /> : ''}
                                {actionName}
                            </button>
                        </div>
                    </form>
                </ModalBody>
            </div>
        </Modal>
    )
}

export default AddClientModal;