
import * as React from 'react';
import { useState } from "react";
// navigation
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Container, Form, Input, FormFeedback, Label } from "reactstrap";
import withRouter from "../../../shared/components/common/withRouter";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
// style
import './login.scss';
// constant validation error file
import { loginValidationErr, SUBSCRIPTION_PLAN_UUID } from '../../../shared/static/constants/constant';
// auth service
import { AuthenticationService } from '../../service/authentication.service';
// toast message
import { ToastContainer } from "react-toastify";
// loader
import Spinners from '../../../shared/components/common/spinner';

import { useAtom } from 'jotai';
import { authenticatedUserInfo } from '../../../../atoms';
import { Role } from '../../model/authentication.model';

const Login = () => {
  const [, setUserInfo] = useAtom(authenticatedUserInfo);
  // loader
  const [isLoading, setLoading] = useState(false);
  // navigation
  const navigate = useNavigate();
  // auth service
  const authenticationService = new AuthenticationService();
  // form
  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required(loginValidationErr.ERR_EMAIL_REQUIRED)
        .email(loginValidationErr.ERR_EMAIL_INVALID),
      password: Yup.string().required(loginValidationErr.ERR_PASSWORD_REQUIRED)
    }),
    // submit form
    onSubmit: (userCredential) => {
      userLoginAndRedirect(userCredential);
    }
  });

  // api call & redirect to page according to loggedin user role
  const userLoginAndRedirect = (userCredentials) => {
    setLoading(true);
    authenticationService.logIn(userCredentials).then(response => {
      // store user data in session storage
      if (response.status === 200) {
        const loggedinUserData = {
          token: response.data.access_token,
          data: {
            user_uuid: response.data.data.user_uuid,
            role_uuid: response.data.data.role_uuid,
            name: response.data.data.name,
            email: response.data.data.email,
            role_name: response.data.data.role_name,
            subscription_details: response.data.data.subscription_details,
            created_at: response.data.data.created_at
          },
        };
        sessionStorage.setItem('userLogin', JSON.stringify(loggedinUserData));
        setUserInfo(JSON.parse(sessionStorage.getItem('userLogin') || ''));
        // IF USER IS IN TRIAL PERIOD OR HAS PREMIUM PLAN
        if(loggedinUserData.data.role_name === Role.Admin || loggedinUserData.data.subscription_details.subscription_plan_details === null || loggedinUserData.data.subscription_details.subscription_plan_details.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.STANDARD_PLAN ){
          navigate("/dashboard");
          sessionStorage.setItem('shouldRefresh', JSON.stringify(false));
        } else {
          navigate('/quote-calculator');
        }
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  // for toggle password show to hide
  const [visiblePassword, setVisiblePassword] = useState(false);

  return (
    <React.Fragment>
      <div className="login-page">
        <Container className=''>
          <Row className="justify-content-center">
            <Col className='col-lg-6 col-md-8 col-12'>
              <Card className='overflow-hidden mb-0 px-lg-5 px-3 py-5'>
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div className='text-center'>
                    <img src="/assets/images/Print_Shop_Master_svg_logo.svg" alt="logo" className="img-fluid logo-img" />
                  </div>

                  <h3 className='text-center my-4'>Login</h3>
                  {/* email */}
                  <div className="mb-3">
                    <Label className="form-label">Email <span className='text-danger'>*</span></Label>
                    <Input
                      name="email"
                      className="form-control"
                      placeholder="Enter email"
                      type="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email ? true : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                    ) : null}
                  </div>
                  {/* password */}
                  <div className="mb-3 position-relative">
                    <Label className="form-label">Password <span className='text-danger'>*</span></Label>
                    <Input
                      name="password"
                      value={validation.values.password || ""}
                      type={visiblePassword ? "text" : "password"}
                      placeholder="Enter Password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.password && validation.errors.password ? true : false
                      }
                    />
                    {validation.values.password.length > 0 && (<i className={`bi login-eye ${!(validation.touched.password && validation.errors.password) ? 'right-5px' : ''}  ${visiblePassword ? 'bi-eye' : 'bi-eye-slash'}`} onClick={() => { setVisiblePassword(!visiblePassword) }}></i>)}
                    {validation.touched.password && validation.errors.password ? (
                      <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                    ) : null}
                  </div>
                  {/* forgot password link */}
                  <div className="mt-0 text-end">
                    <Link to="/forgot-password" className="text-muted forgot-password-link">
                      <span><i className="mdi mdi-lock me-1" /></span>
                      <span className='forgot-password-text'>Forgot your password?</span>
                    </Link>
                  </div>
                  {/* login button */}
                  <div className="mt-3 d-grid ">
                    <button
                      className="btn-fill btn-block position-relative cursor-pointer"
                      type="submit"
                      disabled={!validation.isValid || !validation.dirty}
                    >
                      <div className='d-flex justify-content-center align-items-center'>
                        {/* loader */}
                        {isLoading ? <Spinners className="" setLoading={setLoading} /> : ''}
                        Log In
                      </div>
                    </button>
                  </div>
                  {/* sign up/register link */}
                  <div className="mt-5 text-center">
                    <p className='mb-0'>
                      Don&#39;t have an account ?{" "}
                      <Link to="/register" className="fw-medium auth-link color-secondary">
                        {" "}
                        Signup now
                      </Link>{" "}
                    </p>
                  </div>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* toast message */}
      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(Login);


