// UserContext.js
import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userData, setUserData] = useState({
        username: '',
        profilePicture: '',
    });

    const updateUser = (newData) => {
        const data = {
            username: newData.username,
            profilePicture: newData.profilePicture,
        }
        setUserData((prevData) => ({ ...prevData, ...data }));
    };

    return (
        <UserContext.Provider value={{ userData, updateUser }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
