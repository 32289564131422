import React, { useEffect, useState, useRef } from "react";
import { Card, CardBody, Col, Container, Row, Table, Spinner, UncontrolledTooltip, Alert, Collapse, DropdownItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, Input } from "reactstrap";
import classnames from "classnames";
import { NumericFormat } from "react-number-format";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Formik, Form, FieldArray } from "formik";
import { useLocation, useNavigate } from 'react-router-dom';
import TaxSelect from "../../../shared/components/common/addTax";
import QuoteProgressBar from "../../../shared/components/common/quoteProgressbar";
import * as Yup from 'yup';
import { InvoiceService } from '../../service/invoice.service';
import { CustomerService } from "../../../customers/service/customer.service";
import { QuoteService } from "../../../quotes/service/quote.service";
import './previewInvoice.scss';
import { AWS_S3_URL, PREVIEW_INVOICE } from "../../../shared/static/constants/constant";
import ImagePreviewModal from '../../../shared/components/common/imagePreviewModal';
import ConfirmationModal from '../../../shared/components/common/confirmationModal';
import ChooseClientDropdown from "../../../shared/components/common/chooseClientDropdwon";
import { ToastContainer, toast } from "react-toastify";
import RichTextEditor from "react-rte";
import { QuoteCalculatorService } from "../../../quoteCalculator/service/quoteCalculator.service";
import html2pdf from 'html2pdf.js';
import { ImageURLTOBase64Convertor } from "../../../shared/helpers/imageUrlToBase64";

const PreviewInvoice = () => {
    const { state } = useLocation();
    const [col1, setcol1] = useState(false);
    const [getDeleteInfo, setDeleteInfo] = useState();
    const [currentMode, setCurrentMode] = useState();
    const [showDropdown, setShowDropdown] = useState();
    const [isSaveInvoiceIsLoading, setisSaveInvoiceIsLoading] = useState();
    const [getTaxInfo, setTaxInfo] = useState([]);
    const [getSelectedTaxInfo, setSelectedTaxInfo] = useState([]);
    const [get_customer_shop_tax_master_uuid, set_customer_shop_tax_master_uuid] = useState('');
    const [getTaxArray, setTaxArray] = useState([]);
    // service
    const invoiceService = new InvoiceService();
    const customerService = new CustomerService();
    const quoteService = new QuoteService();
    const quoteCalculatorService = new QuoteCalculatorService();
    const [getPreviewInvoiceInfo, setPreviewInvoiceInfo] = useState();

    const [editModeInvoiceDetails, setEditModeInvoiceDetails] = useState({});

    const [userDetails, setUserDetails] = useState('');
    const [isMobileDevice, setisMobiledevice] = useState(false);
    const [isValidPreviewForm, setisValidPreviewForm] = useState(true);

    const [isLoading, setLoading] = useState(true);
    const [isEditMode, setisEditMode] = useState(true);

    // navigation
    const navigate = useNavigate();
    const flatpickrRef = useRef(null);

    const [afterAction, setAfterAction] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState();
    const [isModal, setIsModal] = useState('');
    const [isSendQuote, setIsSendQuote] = useState(false);
    const [customerQuoteClientList, setCustomerQuoteClientList] = useState([]);

    // for status step
    const [currentStatus, setCurrentStatus] = useState('');
    const [statuses, setStatuses] = useState(['draft', 'sent', 'read', 'paid']);

    const [isVerticalCollapsed, setIsVerticalCollapsed] = useState(document.body.classList.contains('vertical-collapsed'));
    const [previewImage, setPreviewImage] = useState(null);

    const [getFormKey, setFormKey] = useState(0);
    const [isActionisLoading, setisActionisLoading] = useState();

    useEffect(() => {
        if (state === 'create-new') {
            setCurrentStatus('draft');
            getCustomerClientList();
            customerService.getCustomerDetail(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then(response => {
                if (response.status === 200) {
                    quoteCalculatorService.getuserDetails(JSON.parse(sessionStorage.getItem('userLogin'))?.data?.user_uuid).then((response) => {
                        if (response.status === 200) {
                            setEditModeInvoiceDetails((prev) => {
                                return {
                                    ...prev,
                                    "invoice_notes": RichTextEditor.createValueFromString(response.data[0].invoice_notes === null || response.data[0].invoice_notes === "null" ? '' : response.data[0].invoice_notes, 'html'),
                                }
                            })
                        }
                    }).catch((error) => {
                        console.error(error);
                    });
                    setEditModeInvoiceDetails(prevData => ({
                        ...(prevData || {}),
                        invoice_name: 'Invoice',
                        user_uuid: JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid,
                        reference_number: null,
                        invoice_notes: RichTextEditor.createEmptyValue(),
                        quote_uuid: null,
                        due_date: null,
                        invoice_client_details: '',
                        invoice_items: [],
                        "shop_logo_url": response.data[0].shop_logo_url,
                        "shop_email": response.data[0].shop_email,
                        "shop_contact_number": response.data[0].shop_contact_number,
                        "shop_address": response.data[0].shop_address,
                        "shop_city": response.data[0].shop_city,
                        "shop_state": response.data[0].shop_state,
                        "shop_country": response.data[0].shop_country,
                        "shop_zip_code": response.data[0].shop_zip_code,
                        "name": response.data[0].name,
                        "issued_on": displayUTCDate(new Date().toUTCString()),
                        "shop_name": response.data[0].shop_name
                    }));
                    setPreviewInvoiceInfo((prev) => {
                        return {
                            ...prev,
                            "shop_logo_url": response.data[0].shop_logo_url,
                        }
                    });
                    set_customer_shop_tax_master_uuid(response.data[0].customer_shop_info_uuid);
                    quoteService.getTaxInfo(response.data[0].customer_shop_info_uuid).then(response => {
                        setTaxInfo(response.data);
                        setFormKey(getFormKey => getFormKey + 1);
                        setLoading(false);
                    })
                    setisEditMode(true);
                    setCurrentMode('add');
                }
            });
        } else if (state.quote_uuid !== null && state.mode === 'create-from-list') {
            setLoading(true);
            setisEditMode(true);
            setCurrentStatus('draft');
            setCurrentMode('add');
            quoteService.getQuoteDetailsByQuoteId(state.quote_uuid).then((response) => {
                if (response.status === 200) {
                    let quoteDetails = response.data;
                    let invoice_items = quoteDetails.quote_items.map(obj => {
                        const { gross_cost, gross_sale, created_at, quote_item_garments_details, quoted_sales_percentage, quote_item_technical_specification_details, quote_item_setup_details, quote_item_markup_details, cost_per_item, net_profit, gross_hourly_rate, quote_item_setup_detials, quote_item_stitch_counts_details, net_hourly_rate, item_image_url, ...rest } = obj.quote_item_details;
                        const renamedRest = {
                            item_name: rest.item_name,
                            item_description: rest.item_description,
                            item_price: rest.quoted_sales_price,
                            invoice_item_uuid: rest.quote_item_uuid,
                            invoice_item_tax_details: rest.quote_item_tax_details === null ? [] : rest.quote_item_tax_details?.map((obj) => {
                                delete obj?.quote_item_tax_mapping_uuid;
                                return obj;
                            }),
                            ...Object.keys(rest).reduce((acc, key) => {
                                if (key !== 'item_description' && key !== 'quoted_sales_price' && key !== 'quote_item_uuid' && key !== 'quote_item_tax_details') {
                                    acc[key] = rest[key];
                                }
                                return acc;
                            }, {})
                        };
                        return { invoice_item_details: renamedRest }
                    });
                    Subtotal(invoice_items);
                    getCustomerClientList();
                    customerService.getCustomerDetail(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then(response => {
                        if (response.status === 200) {
                            setUserDetails(response.data[0]);
                            setLoading(false);
                            setEditModeInvoiceDetails(prev => ({
                                ...(prev || {}),
                                "invoice_name": 'Invoice',
                                "user_uuid": JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid,
                                "quote_uuid": state.quote_uuid,
                                "reference_number": null,
                                "invoice_client_details": {
                                    client_company_name: quoteDetails.client_company_name,
                                    client_email: quoteDetails.client_email,
                                    client_address: quoteDetails.client_address,
                                    client_name: quoteDetails?.client_name,
                                    client_city: quoteDetails?.client_city,
                                    client_state: quoteDetails?.client_state,
                                    client_country: quoteDetails?.client_country,
                                    client_zip_code: quoteDetails?.client_zip_code,
                                },
                                "invoice_items": invoice_items,
                                "invoice_notes": RichTextEditor.createValueFromString(response.data[0].invoice_notes === null || response.data[0].invoice_notes === "null" ? '' : response.data[0].invoice_notes, 'html'),
                                "due_date": null,
                                "issued_on": displayUTCDate(response.data[0].issued_on),
                                "shop_logo_url": response.data[0].shop_logo_url,
                                "shop_email": response.data[0].shop_email,
                                "shop_contact_number": response.data[0].shop_contact_number,
                                "shop_address": response.data[0].shop_address,
                                "shop_city": response.data[0].shop_city,
                                "shop_state": response.data[0].shop_state,
                                "shop_country": response.data[0].shop_country,
                                "shop_zip_code": response.data[0].shop_zip_code,
                                "name": response.data[0].name,
                                "shop_name": response.data[0].shop_name
                            }));
                            setPreviewInvoiceInfo((prev) => {
                                return {
                                    ...prev,
                                    "shop_logo_url": response.data[0].shop_logo_url,
                                }
                            })
                            set_customer_shop_tax_master_uuid(response.data[0].customer_shop_info_uuid);
                            quoteService.getTaxInfo(response.data[0].customer_shop_info_uuid).then(response => {
                                setTaxInfo(response.data);
                                setFormKey(getFormKey => getFormKey + 1);
                                setLoading(false);
                            })
                        }
                    });
                    setisEditMode(true);
                }
            });
        } else if (state.mode === 'previewInvoice' && state.invoice_uuid !== null) {
            setLoading(true);
            getInvoiceDetailByInvoiceId(state.invoice_uuid);
        } else if (state.mode === 'editMode' && state.invoice_uuid !== null) {
            setLoading(true);
            setisEditMode(true);
            setCurrentMode('edit');
            customerService.getCustomerDetail(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then(response => {
                if (response.status === 200) {
                    setUserDetails(response.data[0]);
                    setLoading(false);
                    set_customer_shop_tax_master_uuid(response.data[0].customer_shop_info_uuid);
                    quoteService.getTaxInfo(response.data[0].customer_shop_info_uuid).then(response => {
                        setTaxInfo(response.data);
                        setFormKey(getFormKey => getFormKey + 1);
                        setLoading(false);
                    });
                }
            });
            setLoading(true);
            invoiceService.getInvoiceDetailByInvoiceId(state.invoice_uuid).then((response) => {
                if (response.status === 200) {
                    Subtotal(response.data.invoice_items)
                    setFormKey(getFormKey => getFormKey + 1);
                    if (response.data.invoice_status === 'cancelled') {
                        setStatuses(['draft', 'sent', 'read', 'cancelled']);
                    }
                    setCurrentStatus(response.data.invoice_status);
                    let editData = response.data;
                    setPreviewInvoiceInfo(response.data);
                    setEditModeInvoiceDetails(prev => ({
                        ...(prev || {}),
                        "invoice_name": editData.invoice_name,
                        "invoice_status": editData.invoice_status,
                        "due_date": displayUTCDate(editData.due_date),
                        "issued_on": displayUTCDate(editData.issued_on),
                        "shop_logo_url": editData.shop_logo_url,
                        "shop_email": editData.shop_email,
                        "shop_contact_number": editData.shop_contact_number,
                        "shop_address": editData.shop_address,
                        "shop_city": editData.shop_city,
                        "shop_state": editData.shop_state,
                        "shop_country": editData.shop_country,
                        "shop_zip_code": editData.shop_zip_code,
                        "name": editData.name,
                        "reference_number": null,
                        "invoice_client_details": {
                            client_company_name: editData.client_company_name,
                            client_email: editData.client_email,
                            client_address: editData.client_address,
                            client_name: editData.client_name,
                            client_city: editData?.client_city,
                            client_state: editData?.client_state,
                            client_country: editData?.client_country,
                            client_zip_code: editData?.client_zip_code,
                        },
                        "invoice_items": editData.invoice_items,
                        "invoice_notes": RichTextEditor.createValueFromString(editData?.invoice_notes === null ? '' : editData?.invoice_notes, 'html'),
                        "shop_name": editData.shop_name
                    }));
                    setisEditMode(true);
                    getCustomerClientList();
                    setCurrentMode('edit');
                }
            }).finally(() => {
                setLoading(false);
                setisSaveInvoiceIsLoading(false);
            });
        }
    }, [state]);

    const getInvoiceDetailByInvoiceId = (invoice_uuid) => {
        setLoading(true);
        invoiceService.getInvoiceDetailByInvoiceId(invoice_uuid).then((response) => {
            if (response.status === 200) {
                setisEditMode(false);
                setPreviewInvoiceInfo(response.data);
                Subtotal(response.data.invoice_items)
                setFormKey(getFormKey => getFormKey + 1);
                if (response.data.invoice_status === 'cancelled') {
                    setStatuses(['draft', 'sent', 'read', 'cancelled']);
                }
                setCurrentStatus(response.data.invoice_status);
                setCurrentMode('let-edit');
                setLoading(false);
                setisSaveInvoiceIsLoading(false);
            }
        });
    }

    const handleEditInvoiceItem = (index, field, value) => {
        setEditModeInvoiceDetails(prevData => {
            const updatedItems = [...prevData.invoice_items];
            let quoteDetails = updatedItems[index]?.invoice_item_details;
            // if item is already added
            if (quoteDetails.quote_item_uuid !== undefined) {
                updatedItems[index] = {
                    ...updatedItems[index],
                    invoice_item_details: {
                        ...quoteDetails,
                        [field]: value,
                    }
                };
                Subtotal(updatedItems);
            } else {
                updatedItems[index] = {
                    ...updatedItems[index],
                    invoice_item_details: {
                        ...quoteDetails,
                        [field]: value,
                    }
                };
                Subtotal(updatedItems);
            }
            return { ...prevData, invoice_items: updatedItems };
        });
    }

    // check sidebar collpsed
    const checkBodyClass = () => {
        if (document.body.classList.contains('vertical-collpsed')) {
            setIsVerticalCollapsed(true);
        } else {
            setIsVerticalCollapsed(false);
        }
    }

    useEffect(() => {
        const observer = new MutationObserver(() => {
            checkBodyClass();
        });
        observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });
        // Clean up the observer when the component unmounts
        return () => observer.disconnect();
    }, [isVerticalCollapsed]);

    const getCustomerClientList = () => {
        quoteService.getCustomerQuoteClientList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
            setCustomerQuoteClientList(response.data);
        });
    }

    useState(() => {
        checkBodyClass();
    }, []);

    const changeInvoiceStatus = () => {
        const sendQuoteInfo = {
            "invoice_uuid": getPreviewInvoiceInfo?.invoice_uuid,
            "status": isModal === 'void' ? 'cancelled' : (isModal === 'paid' ? 'paid' : 'unpaid'),
        }
        if (isModal === 'paid') {
            let totalAmount = isEditMode ? +(getTaxArray.reduce((acc, data) => acc + data.Amount, 0) + (editModeInvoiceDetails.invoice_items?.reduce((acc, v) => acc + (+v.invoice_item_details.item_quantity * +v.invoice_item_details.item_price), 0) || 0)).toFixed(2) :
                +(getTaxArray.reduce((acc, data) => acc + data?.Amount, 0) + (getPreviewInvoiceInfo?.invoice_items?.reduce((acc, v) => acc + (+v.invoice_item_details.item_quantity * +v.invoice_item_details.item_price), 0) || 0)).toFixed(2);
            sendQuoteInfo.payment_amount = parseFloat(parseFloat(totalAmount).toFixed(2));
        }
        invoiceService.changeInvoiceStatus(sendQuoteInfo).then((response) => {
            if (response.status === 200) {
                getInvoiceDetailByInvoiceId(getPreviewInvoiceInfo?.invoice_uuid);
            }
        }).finally(() => {
            setAfterAction(true);
        });
    }

    // for convert date to local
    const UTCtoLocal = (utcDate) => {
        utcDate = utcDate?.slice(0, -1) + 'Z';
        let localDate = new Date(utcDate);
        return localDate.getFullYear() + '-' + (localDate.getMonth() + 1).toString().padStart(2, '0') + '-' + localDate.getDate().toString().padStart(2, '0');
    }

    const handleFlatpickrInstance = (flatpickr) => {
        flatpickrRef.current = flatpickr;
    };

    const Subtotal = (invoice_items) => {
        let taxIdentifier = [];
        invoice_items.forEach((e) => {
            e?.invoice_item_details?.invoice_item_tax_details?.filter(tax => tax.tax_name !== null && tax.tax_percentage !== null).forEach((taxInfo) => {
                const taxPercentage = taxInfo.tax_percentage;
                // Find if there's already a tax with the same percentage in taxIdentifier
                const existingTaxIndex = taxIdentifier.findIndex(tax => +tax.tax_percentage === +taxPercentage && tax.tax_name === taxInfo.tax_name);
                const taxAmount = (+e.invoice_item_details.item_quantity * +e.invoice_item_details.item_price) * (+taxInfo.tax_percentage / 100);
                if (existingTaxIndex !== -1) {
                    // If tax with the same percentage already exists, add the amount to it
                    taxIdentifier[existingTaxIndex].Amount += taxAmount;
                } else {
                    // Otherwise, add a new entry
                    taxIdentifier.push({
                        tax_name: taxInfo.tax_name,
                        tax_percentage: taxPercentage,
                        Amount: +taxAmount
                    });
                }
            });
        });
        setTaxArray(taxIdentifier);
    }

    const onClickSaveInvoice = () => {
        setisSaveInvoiceIsLoading(true);
        if (currentMode === 'add') {
            if ((state === 'create-new') || (state.quote_uuid !== null && state.mode === 'create-from-list')) {
                const updatedInvoiceItems = editModeInvoiceDetails.invoice_items?.map(item => ({
                    invoice_item_details: {
                        item_name: item.invoice_item_details?.item_name,
                        item_description: item.invoice_item_details?.item_description,
                        item_quantity: item.invoice_item_details?.item_quantity,
                        item_price: item.invoice_item_details.item_price
                    },
                    invoice_item_tax_details: item.invoice_item_details?.invoice_item_tax_details.map(tax => ({
                        customer_shop_tax_master_uuid: tax?.customer_shop_tax_master_uuid
                    })) || []
                }));
                const addInvoiceItem = {
                    "invoice_name": editModeInvoiceDetails.invoice_name,
                    "user_uuid": JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid,
                    "issued_on": (editModeInvoiceDetails.issued_on === null || editModeInvoiceDetails.issued_on === undefined)
                        ? new Date().toISOString().split('T')[0]
                        : UTCtoLocal(new Date(editModeInvoiceDetails.issued_on).toISOString()),
                    "invoice_notes": editModeInvoiceDetails.invoice_notes.toString('html') === '<p><br></p>' || editModeInvoiceDetails.invoice_notes.toString('html') === "" ? null : editModeInvoiceDetails.invoice_notes.toString('html'),
                    "due_date": (editModeInvoiceDetails.due_date === null || editModeInvoiceDetails.due_date === undefined)
                        ? new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().split('T')[0]
                        : UTCtoLocal(new Date(editModeInvoiceDetails.due_date).toISOString()),
                    "invoice_client_details": {
                        client_company_name: editModeInvoiceDetails.invoice_client_details.client_company_name,
                        client_email: editModeInvoiceDetails.invoice_client_details.client_email,
                        client_name: editModeInvoiceDetails?.invoice_client_details.client_name,
                        client_address: editModeInvoiceDetails.invoice_client_details.client_address === null ? '' : editModeInvoiceDetails.invoice_client_details.client_address,
                        client_city: editModeInvoiceDetails.invoice_client_details.client_city === null ? '' : editModeInvoiceDetails.invoice_client_details.client_city,
                        client_state: editModeInvoiceDetails.invoice_client_details.client_state === null ? '' : editModeInvoiceDetails.invoice_client_details.client_state,
                        client_country: editModeInvoiceDetails.invoice_client_details.client_country === null ? '' : editModeInvoiceDetails.invoice_client_details.client_country,
                        client_zip_code: editModeInvoiceDetails.invoice_client_details.client_zip_code === null ? '' : editModeInvoiceDetails.invoice_client_details.client_zip_code,
                    },
                    ...((editModeInvoiceDetails.quote_uuid !== null || editModeInvoiceDetails.quote_uuid !== undefined)
                        ? { "quote_uuid": editModeInvoiceDetails.quote_uuid }
                        : {}),
                    "invoice_items": updatedInvoiceItems,
                    ...((editModeInvoiceDetails.reference_number !== null || editModeInvoiceDetails.reference_number !== undefined)
                        ? { "reference_number": editModeInvoiceDetails.reference_number }
                        : {}),
                };
                invoiceService.createInvoice(addInvoiceItem).then((response) => {
                    if (response.status === 200) {
                        getInvoiceDetailByInvoiceId(response.data.invoice_uuid);
                    }
                }).catch(() => {
                    setisSaveInvoiceIsLoading(false);
                });
            }
        } else if (isEditMode) {
            editModeInvoiceDetails.invoice_items.forEach((obj) => delete obj.invoice_item_details.id);
            const updatedInvoiceItems = editModeInvoiceDetails.invoice_items?.map(item => {
                const invoice_item_uuid = item.invoice_item_details?.invoice_item_uuid;
                return {
                    invoice_item_details: {
                        ...(invoice_item_uuid && { invoice_item_uuid }),
                        item_name: item.invoice_item_details?.item_name,
                        item_description: item.invoice_item_details?.item_description,
                        item_quantity: item.invoice_item_details?.item_quantity,
                        item_price: item.invoice_item_details.item_price
                    },
                    invoice_item_tax_details: (item.invoice_item_details?.invoice_item_tax_details || []).map(tax => ({
                        customer_shop_tax_master_uuid: tax?.customer_shop_tax_master_uuid
                    }))
                };
            });
            const editedInvoiceDetails = {
                ...((editModeInvoiceDetails.reference_number !== null || editModeInvoiceDetails.reference_number !== undefined)
                    ? { "reference_number": editModeInvoiceDetails.reference_number }
                    : {}),
                "invoice_notes": editModeInvoiceDetails.invoice_notes.toString('html') === '<p><br></p>' || editModeInvoiceDetails.invoice_notes.toString('html') === "" ? null : editModeInvoiceDetails.invoice_notes.toString('html'),
                "issued_on": (editModeInvoiceDetails.issued_on === null || editModeInvoiceDetails.issued_on === undefined)
                    ? new Date().toISOString().split('T')[0]
                    : UTCtoLocal(new Date(editModeInvoiceDetails.issued_on).toISOString()),
                "due_date": (editModeInvoiceDetails.due_date === null || editModeInvoiceDetails.due_date === undefined)
                    ? new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().split('T')[0]
                    : UTCtoLocal(new Date(editModeInvoiceDetails.due_date).toISOString()),
                "invoice_name": editModeInvoiceDetails.invoice_name,
                "invoice_client_details": {
                    client_company_name: editModeInvoiceDetails.invoice_client_details.client_company_name,
                    client_email: editModeInvoiceDetails.invoice_client_details.client_email,
                    client_name: editModeInvoiceDetails?.invoice_client_details.client_name,
                    client_address: editModeInvoiceDetails.invoice_client_details.client_address === null ? '' : editModeInvoiceDetails.invoice_client_details.client_address,
                    client_city: editModeInvoiceDetails.invoice_client_details.client_city === null ? '' : editModeInvoiceDetails.invoice_client_details.client_city,
                    client_state: editModeInvoiceDetails.invoice_client_details.client_state === null ? '' : editModeInvoiceDetails.invoice_client_details.client_state,
                    client_country: editModeInvoiceDetails.invoice_client_details.client_country === null ? '' : editModeInvoiceDetails.invoice_client_details.client_country,
                    client_zip_code: editModeInvoiceDetails.invoice_client_details.client_zip_code === null ? '' : editModeInvoiceDetails.invoice_client_details.client_zip_code,
                },
                ...((editModeInvoiceDetails.quote_uuid !== null || editModeInvoiceDetails.quote_uuid !== undefined)
                    ? {}
                    : { "quote_uuid": editModeInvoiceDetails.quote_uuid }),
                "invoice_items": updatedInvoiceItems,
            };
            invoiceService.editInvoice(getPreviewInvoiceInfo?.invoice_uuid, editedInvoiceDetails).then((response) => {
                if (response.status === 200) {
                    getInvoiceDetailByInvoiceId(getPreviewInvoiceInfo?.invoice_uuid);
                }
            }).catch(() => {
                setisSaveInvoiceIsLoading(false);
            });
        }
    }

    const displayUTCDate = (utcDate) => {
        if (utcDate !== undefined) {
            const date = new Date(utcDate);
            const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
            const options = {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Dynamic time zone based on user's location
            };
            const formattedDate = new Intl.DateTimeFormat('en-US', options).format(localDate);
            return formattedDate;
        }
    }

    // Utility function to convert HTML string to PDF and download it
    const downloadPdf = async (htmlString, file_name) => {
        // Create a temporary container to hold the HTML content
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
         
        document.body.appendChild(tempDiv);

        // Find all images in the HTML and convert them to base64
        const images = tempDiv.querySelectorAll('img');
        const imagePromises = Array.from(images).map(async (img) => {
            const url = img.src;
            if (url.startsWith('http')) {
                const base64 = await ImageURLTOBase64Convertor.convertImageUrlToBase64(url);
                img.src = base64;
            }
        });

        // Wait for all images to be converted
        await Promise.all(imagePromises);

        // Set up options for html2pdf
        const opt = {
            margin: [0.2, 0.1, 0.2, 0.1],
            filename: file_name,
            html2canvas: {
                scale: 2,
            },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
            enableLinks: true
        };

        // Convert the HTML to PDF
        try {
            await html2pdf().from(tempDiv).set(opt).save();
        } catch (error) {
            console.error("PDF generation error:", error);
        } finally {
            document.body.removeChild(tempDiv);
        }
    };

    // for download quote
    const onClickDownload = () => {
        setisActionisLoading(true);
        const invoice_uuid_obj = {
            "invoice_uuid": getPreviewInvoiceInfo?.invoice_uuid
        }
        invoiceService.downloadInvoice(invoice_uuid_obj).then(async (response) => {
            if (response.status === 200) {
                setTimeout(async () => {
                    await downloadPdf(response.data, `${'invoice'}_${getPreviewInvoiceInfo?.invoice_number}.pdf`);
                    setisActionisLoading(false);
                }, 2000);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.errors[0]?.message, { autoClose: 4000 });
            setisActionisLoading(false);
        });
    }

    // for delete quote
    const onClickAction = (data, isSend) => {
        setIsModal(data);
        setAfterAction(false);
        setConfirmationModal(true);
        setIsSendQuote(isSend);
    }

    const validationPreviewQuoteItemSchema = Yup.object().shape({
        invoiceItem: Yup.array().of(
            Yup.object().shape({
                invoice_item_details: Yup.object().shape({
                    item_quantity: Yup.number().min(1, PREVIEW_INVOICE.ERR_INVALID_ITEM_QUANTITY).required(PREVIEW_INVOICE.ERR_REQUIRED_ITEM_QUANTITY),
                    item_price: Yup.number().min(1, PREVIEW_INVOICE.ERR_INVALID_ITEM_RATE).required(PREVIEW_INVOICE.ERR_REQUIRED_ITEM_RATE),
                    item_name: Yup.string().required(PREVIEW_INVOICE.ERR_ITEM_NAME_REQUIRED)
                })
            })
        )
    });

    const updatedTaxInfo = (newTaxInfo, isDeletedMode) => {
        setTaxInfo(newTaxInfo);
        if (isDeletedMode !== undefined) {
            const updatedItems = editModeInvoiceDetails.invoice_items.map(item => {
                const taxDetails = item.invoice_item_details.invoice_item_tax_details;

                // Filter out the tax details that do not match any tax detail in newTaxInfo
                const updatedTaxDetails = isDeletedMode
                    ? taxDetails?.filter(taxDetail =>
                        newTaxInfo.some(newTax => newTax.customer_shop_tax_master_uuid === taxDetail.customer_shop_tax_master_uuid)
                    )
                    : taxDetails?.map(taxDetail => {
                        const updatedTax = newTaxInfo.find(tax => tax.customer_shop_tax_master_uuid === taxDetail.customer_shop_tax_master_uuid);
                        return updatedTax ? { ...taxDetail, ...updatedTax } : taxDetail;
                    }) || [];
                return {
                    ...item,
                    invoice_item_details: {
                        ...item.invoice_item_details,
                        invoice_item_tax_details: updatedTaxDetails
                    }
                };
            });
            Subtotal(updatedItems);
            setFormKey(getFormKey => getFormKey + 1);
            setEditModeInvoiceDetails({
                ...editModeInvoiceDetails,
                invoice_items: updatedItems
            });
        }
    };

    const selectedTaxInfo = (selectedTaxInfo) => {
        setSelectedTaxInfo(selectedTaxInfo);
        const index = editModeInvoiceDetails.invoice_items.findIndex(item => {
            if (item.invoice_item_details.invoice_item_uuid !== undefined) {
                return item.invoice_item_details.invoice_item_uuid === selectedTaxInfo.quote_item_uuid;
            } else {
                return item.invoice_item_details.id === +selectedTaxInfo.quote_item_uuid;
            }
        });

        if (index !== -1) { // Check if the element with the target quote_item_uuid exists
            setFormKey(getFormKey => getFormKey + 1);
            setEditModeInvoiceDetails(prevData => {
                const updatedItems = [...prevData.invoice_items];
                let invoiceDetails = updatedItems[index]?.invoice_item_details;
                // if item is already added
                if (invoiceDetails.invoice_item_uuid !== undefined) {
                    updatedItems[index] = {
                        ...updatedItems[index],
                        invoice_item_details: {
                            ...invoiceDetails,
                            invoice_item_tax_details: selectedTaxInfo.quote_item_tax_details
                        }
                    };
                } else {
                    updatedItems[index] = {
                        ...updatedItems[index],
                        invoice_item_details: {
                            ...invoiceDetails,
                            invoice_item_tax_details: selectedTaxInfo.quote_item_tax_details
                        }
                    };
                }
                Subtotal(updatedItems);
                return { ...prevData, invoice_items: updatedItems };
            });
        }
    }

    const isDeleteInvoiceItem = () => {
        setFormKey(getFormKey => getFormKey + 1);
        setAfterAction(false);
        if (getDeleteInfo.invoice_item_uuid !== undefined) {
            invoiceService.deleteInvoiceItem(getDeleteInfo.invoice_item_uuid).then(response => {
                if (response.status === 200) {
                    setAfterAction(true);
                    setTimeout(() => { setAfterAction(true); }, 1000);
                    setFormKey(prevKey => prevKey + 1);
                    setEditModeInvoiceDetails(prevData => {
                        const updatedArray = prevData.invoice_items.filter(obj => {
                            return obj.invoice_item_details.invoice_item_uuid !== getDeleteInfo.invoice_item_uuid;
                        });
                        Subtotal(updatedArray);
                        return { ...prevData, invoice_items: updatedArray };
                    });
                }
            });
        } else {
            setFormKey(prevKey => prevKey + 1);
            setEditModeInvoiceDetails(prevData => {
                const updatedArray = editModeInvoiceDetails.invoice_items.filter((obj) => obj.invoice_item_details.id !== getDeleteInfo.id);
                Subtotal(updatedArray);
                return { ...prevData, invoice_items: updatedArray };
            });
            setAfterAction(true);
            setTimeout(() => { setAfterAction(false); }, 1000);
        }
    }

    const isSendHandle = () => {
        setAfterAction(false);
        const sendInvoiceId = {
            "invoice_uuid": getPreviewInvoiceInfo?.invoice_uuid
        }
        invoiceService.sendInvoice(sendInvoiceId).then((response) => {
            if (response.status === 200) {
                getInvoiceDetailByInvoiceId(getPreviewInvoiceInfo?.invoice_uuid);
                setAfterAction(true);
            }
        });
    }

    const isDeleteInvoiceHandle = () => {
        setAfterAction(false);
        invoiceService.deleteInvoice(getPreviewInvoiceInfo?.invoice_uuid).then((response) => {
            if (response.status === 200) {
                navigate('/invoice-list')
            }
        }).finally(() => {
            setAfterAction(true);
            setTimeout(() => { setAfterAction(false); }, 1000);
        })
    }

    return (
        <React.Fragment>
            <div className="page-content invoice-page pb-0">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <div className="row page-title-box d-flex align-items-center">
                        {
                            !isLoading && (
                                <h4 className="col-md-4 col-6 my-2 font-size-18">{currentMode === 'add' ? 'create invoice' : currentMode === 'let-edit' ? 'Preview invoice' : 'edit invoice'}</h4>
                            )
                        }
                        {
                            !isLoading && (
                                <div className="col-md-4 col-6 desktop-statusbar"> <QuoteProgressBar statuses={statuses ? statuses : []} currentStatus={currentStatus} /></div>
                            )
                        }
                        {
                            !isLoading && (
                                <div className="col-md-4 col-6 page-title-right py-1 d-flex justify-content-end">
                                    <div className="d-flex">
                                        {
                                            !isEditMode && ((getPreviewInvoiceInfo?.invoice_status === 'sent') || (getPreviewInvoiceInfo?.invoice_status === 'read') || (getPreviewInvoiceInfo?.invoice_status === 'draft')) && (
                                                <ol className="breadcrumb m-0 d-flex align-items-center position-relative">
                                                    <button type="button" onClick={async () => {
                                                        setisEditMode(true);
                                                        if (userDetails === '' && getTaxInfo.length === 0) {
                                                            setLoading(true);
                                                            getCustomerClientList();
                                                            customerService.getCustomerDetail(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then(response => {
                                                                if (response.status === 200) {
                                                                    setUserDetails(response.data[0]);
                                                                    setLoading(false);
                                                                    set_customer_shop_tax_master_uuid(response.data[0].customer_shop_info_uuid);
                                                                    quoteService.getTaxInfo(response.data[0].customer_shop_info_uuid).then(response => {
                                                                        setTaxInfo(response.data);
                                                                        setFormKey(getFormKey => getFormKey + 1);
                                                                        setLoading(false);
                                                                    });
                                                                }
                                                            });
                                                        }
                                                        setEditModeInvoiceDetails((prev) => {
                                                            return {
                                                                ...prev,
                                                                "invoice_client_details": {
                                                                    "client_company_name": getPreviewInvoiceInfo?.client_company_name,
                                                                    "client_email": getPreviewInvoiceInfo?.client_email,
                                                                    "client_name": getPreviewInvoiceInfo?.client_name,
                                                                    "client_address": getPreviewInvoiceInfo?.client_address === null ? '' : getPreviewInvoiceInfo?.client_address,
                                                                    "client_city": getPreviewInvoiceInfo?.client_city === null ? '' : getPreviewInvoiceInfo?.client_city,
                                                                    "client_state": getPreviewInvoiceInfo?.client_state === null ? '' : getPreviewInvoiceInfo?.client_state,
                                                                    "client_country": getPreviewInvoiceInfo?.client_country === null ? '' : getPreviewInvoiceInfo?.client_country,
                                                                    "client_zip_code": getPreviewInvoiceInfo?.client_zip_code === null ? '' : getPreviewInvoiceInfo?.client_zip_code,
                                                                },
                                                                "due_date": displayUTCDate(getPreviewInvoiceInfo?.due_date),
                                                                "issued_on": displayUTCDate(getPreviewInvoiceInfo?.issued_on),
                                                                "invoice_name": getPreviewInvoiceInfo?.invoice_name,
                                                                "invoice_notes": RichTextEditor.createValueFromString(getPreviewInvoiceInfo?.invoice_notes === null ? '' : getPreviewInvoiceInfo?.invoice_notes, 'html'),
                                                                "invoice_items": getPreviewInvoiceInfo?.invoice_items,
                                                                "reference_number": getPreviewInvoiceInfo?.reference_number,
                                                                "invoice_number": getPreviewInvoiceInfo?.invoice_number,
                                                                "shop_logo_url": getPreviewInvoiceInfo?.shop_logo_url,
                                                                "shop_email": getPreviewInvoiceInfo?.shop_email,
                                                                "shop_contact_number": getPreviewInvoiceInfo?.shop_contact_number,
                                                                "shop_address": getPreviewInvoiceInfo?.shop_address,
                                                                "shop_city": getPreviewInvoiceInfo?.shop_city,
                                                                "shop_state": getPreviewInvoiceInfo?.shop_state,
                                                                "shop_country": getPreviewInvoiceInfo?.shop_country,
                                                                "shop_zip_code": getPreviewInvoiceInfo?.shop_zip_code,
                                                                "name": getPreviewInvoiceInfo?.name,
                                                                "shop_name": getPreviewInvoiceInfo?.shop_name
                                                            }
                                                        })
                                                        setFormKey(getFormKey => getFormKey + 1);
                                                        setCurrentMode('edit')
                                                    }} className="add-customer py-1 btn btn-success">
                                                        <i className="mdi mdi-pencil me-2" />
                                                        Edit
                                                    </button>
                                                </ol>
                                            )
                                        }
                                        {
                                            currentMode !== 'add' && (
                                                <UncontrolledDropdown className="action-dropdown ms-2 d-flex justify-content-center">
                                                    <DropdownToggle tag="a" className="card-drop">
                                                        <i className="mdi mdi-dots-vertical font-size-18 dropdown-icon"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-end mt-2">
                                                        {
                                                            getPreviewInvoiceInfo?.invoice_status !== 'cancelled' && (
                                                                <DropdownItem
                                                                    onClick={(e) => {
                                                                        onClickAction('Sent invoice', true);
                                                                    }} className="d-flex align-items-center">
                                                                    <i className="bx bx-send font-size-16 me-2 dropdown-menu-icon" id="edittooltip"></i>
                                                                    <span className="dropdown-menu-text">Send</span>
                                                                </DropdownItem>
                                                            )
                                                        }
                                                        <DropdownItem
                                                            onClick={(e) => {
                                                                onClickDownload();
                                                            }} className="d-flex align-items-center">
                                                            <i className="bx bx-download font-size-16 me-2 dropdown-menu-icon" id="downloadtooltip"></i>
                                                            <span className="dropdown-menu-text">Download</span>
                                                        </DropdownItem>
                                                        {
                                                            getPreviewInvoiceInfo?.invoice_status !== 'cancelled' && (
                                                                <DropdownItem
                                                                    onClick={(e) => {
                                                                        onClickAction('void', false);
                                                                    }} className="d-flex align-items-center">
                                                                    <i className="bx bx-x-circle font-size-18 me-2 dropdown-menu-icon" id="edittooltip"></i>
                                                                    <span className="dropdown-menu-text">Void</span>
                                                                </DropdownItem>
                                                            )
                                                        }
                                                        {
                                                            getPreviewInvoiceInfo?.invoice_status === 'paid' && (
                                                                <DropdownItem onClick={() => {
                                                                    onClickAction('unpaid', false);
                                                                }} className="d-flex align-items-center">
                                                                    <i className="fab fa-creative-commons-nc font-size-16 me-2 dropdown-menu-icon" id="downloadtooltip"></i>
                                                                    <span className="dropdown-menu-text">Mark as unpaid</span>
                                                                </DropdownItem>
                                                            )
                                                        }
                                                        {
                                                            !(getPreviewInvoiceInfo?.invoice_status === 'paid' || getPreviewInvoiceInfo?.invoice_status === 'cancelled') && (
                                                                <DropdownItem onClick={() => {
                                                                    onClickAction('paid', false)
                                                                }} className="d-flex align-items-center">
                                                                    <i className="bx bx-dollar-circle font-size-18 me-2 dropdown-menu-icon" id="downloadtooltip"></i>
                                                                    <span className="dropdown-menu-text">Mark as paid</span>
                                                                </DropdownItem>
                                                            )
                                                        }
                                                        <DropdownItem
                                                            onClick={() => {
                                                                onClickAction('Delete invoice', false);
                                                            }} className="d-flex align-items-center">
                                                            <i className="mdi mdi-trash-can font-size-16 mt-0 me-2 text-danger dropdown-menu-icon" id="deletetooltip"></i>
                                                            <span className="dropdown-menu-text">Delete</span>
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                        {
                            !isLoading && (
                                <div className="text-center col-12 d-flex justify-content-center mobile-statusbar mt-3"> <QuoteProgressBar statuses={statuses ? statuses : []} currentStatus={currentStatus} /></div>
                            )
                        }
                    </div>
                    {
                        getPreviewInvoiceInfo?.invoice_status === 'cancelled' && (
                            <Alert className={`${isVerticalCollapsed ? "preview-quote-card" : ""} border-0 aler-message alert py-3 alert-danger fade show mb-4 px-md-3 px-1 `}>
                                <p className="mb-0">This invoice has been voided. you may still view or download it.</p>
                            </Alert>
                        )
                    }
                    {
                        !isLoading && !isEditMode && getPreviewInvoiceInfo?.invoice_payment_method !== null && (
                            <div className={isVerticalCollapsed ? "preview-quote-card accordion mb-4" : " accordion mb-4"} id="accordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className={classnames(
                                                "accordion-button payment_info-button",
                                                { collapsed: !col1 }
                                            )}
                                            type="button"
                                            onClick={() => { setcol1(!col1); }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {!col1 ? <i className="bx font-size-18 me-2 bx-caret-down"></i> : <i className="bx me-2 font-size-18 bx-caret-up"></i>}    Payment Information
                                        </button>
                                    </h2>
                                    <Collapse isOpen={col1} className="accordion-collapse">
                                        <div className="accordion-body">
                                            <div className="d-flex">
                                                <p className="me-2 fw-bold payment_method_title me-sm-5">Payment method</p>
                                                {
                                                    getPreviewInvoiceInfo?.invoice_payment_method === 'card' ? (
                                                        <p>Invoice was paid using card on  <strong className="fw-bold">
                                                            {displayUTCDate(getPreviewInvoiceInfo?.invoice_paid_on)}
                                                        </strong>.</p>
                                                    ) : (
                                                        <p>Invoice was {getPreviewInvoiceInfo?.invoice_payment_method} marked as paid on <strong className="fw-bold">
                                                            {displayUTCDate(getPreviewInvoiceInfo?.invoice_paid_on)}
                                                        </strong>.</p>
                                                    )
                                                }
                                            </div>
                                            <div className="d-flex">
                                                <p className="me-2 fw-bold mb-0 net_amount_title me-sm-5">Net Amount</p>
                                                <p className="mb-0">${(getTaxArray.reduce((acc, data) => acc + data?.Amount, 0) + (getPreviewInvoiceInfo?.invoice_items?.reduce((acc, v) => acc + (+v.invoice_item_details.item_quantity * +v.invoice_item_details.item_price), 0) || 0)).toFixed(2)}</p>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        )
                    }
                    <Row>
                        <Col lg="12">
                            <Card className={isVerticalCollapsed ? "preview-quote-card mb-4" : "mb-4"}>
                                <CardBody className="px-lg-5 px-3 position-relative">
                                    <div className="preview-quote-title d-flex justify-content-between align-items-center">
                                        {/* shop logo image */}
                                        <div className="shop-logo d-flex align-items-center">
                                            {/* <i className="bx bx-arrow-back font-size-22 me-2 me-md-3 cursor-pointer back-arrow" onClick={() => { navigate(-1); }}></i> */}
                                            <span className="font-size-30 font-weight-100 me-2 me-md-3 cursor-pointer back-arrow" onClick={() => { navigate(-1); }}> {'<'} </span>
                                            {!isLoading && getPreviewInvoiceInfo?.shop_logo_url !== null && getPreviewInvoiceInfo?.shop_logo_url !== 'null' &&
                                                <img src={AWS_S3_URL + getPreviewInvoiceInfo?.shop_logo_url} alt="shop-img" className="logo-dark-element preview-quote-shop-logo cursor-pointer" onClick={() => setPreviewImage(AWS_S3_URL + getPreviewInvoiceInfo?.shop_logo_url)} />
                                            }
                                        </div>
                                        {
                                            isEditMode && (
                                                <div>
                                                    <div className="d-flex justify-content-end">
                                                        <h4 className={`font-size-16 my-0 invoice_name_container color-secondary mt-1`}>
                                                            {
                                                                !isLoading && (
                                                                    <input type="text" className="form-control font-size-16 text-end invoice_name_field fw-bold" value={editModeInvoiceDetails.invoice_name} onChange={(e) => {
                                                                        setEditModeInvoiceDetails((prevData) => {
                                                                            return {
                                                                                ...prevData,
                                                                                invoice_client_details: '',
                                                                                due_date: editModeInvoiceDetails.due_date,
                                                                                invoice_items: editModeInvoiceDetails.invoice_items,
                                                                                invoice_name: e.target.value,
                                                                                invoice_client_details: editModeInvoiceDetails.invoice_client_details
                                                                            }
                                                                        })
                                                                    }} />
                                                                )
                                                            }
                                                            {
                                                                !isLoading && editModeInvoiceDetails?.invoice_name === '' && (
                                                                    <p className="mb-0 error-message invoice-name-error-message">invoice name can not be empty!</p>
                                                                )
                                                            }
                                                            {
                                                                !isLoading && editModeInvoiceDetails?.invoice_name?.length > 30 && (
                                                                    <p className="mb-0 error-message invoice-name-error-message">invoice name can length can not be greater then 30 character!</p>
                                                                )
                                                            }
                                                        </h4>
                                                    </div>
                                                    {
                                                        isEditMode && !isLoading && (
                                                            <div className="d-flex align-items-center justify-content-end mt-2">
                                                                <p className="mb-0 me-3 invoice-item_description ps-3 text-end">Reference#</p>
                                                                <input
                                                                    type="text"
                                                                    id="reference_number"
                                                                    value={editModeInvoiceDetails.reference_number}
                                                                    className="form-control reference-flatpicker flatpicker-field"
                                                                    onKeyDown={(e) => {
                                                                        const enteredValue = e.target.value.toString().trim();
                                                                        if (enteredValue.length >= 10 && e.keyCode !== 8 && e.keyCode !== 46) {
                                                                            // Prevent input if length is 10 or more and the key is not backspace (8) or delete (46)
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                    onChange={(e) => {
                                                                        const enteredValue = e.target.value.toString().trim();
                                                                        // Allow any character and truncate to 10
                                                                        const truncatedValue = enteredValue.slice(0, 10);
                                                                        setEditModeInvoiceDetails((prev) => ({
                                                                            ...prev,
                                                                            reference_number: truncatedValue
                                                                        }));
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                        {
                                            !isEditMode && (
                                                <div>
                                                    <h4 className="font-size-16 my-0 text-end text-success">{getPreviewInvoiceInfo?.invoice_name}</h4>
                                                    {
                                                        !isLoading && !isEditMode && getPreviewInvoiceInfo?.invoice_number && (
                                                            <div className="d-flex align-items-center justify-content-end mt-2 mb-2 ps-sm-5 ps-1">
                                                                <p className="mb-0 due-text me-1 text-end " >Invoice #</p>
                                                                <p className="mb-0 text-end preview-invoice-info invoice-item_description">
                                                                    {getPreviewInvoiceInfo?.invoice_number || ""}
                                                                </p>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        !isLoading && !isEditMode && getPreviewInvoiceInfo.reference_number && (
                                                            <div className="d-flex align-items-center justify-content-end mt-2 mb-2 ps-sm-5 ps-1">
                                                                <p className="mb-0 due-text me-1 text-end" >Reference #</p>
                                                                <p className="mb-0 text-end preview-invoice-info invoice-item_description">
                                                                    {getPreviewInvoiceInfo?.reference_number || ""}
                                                                </p>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                    <hr />
                                    <Row className="mt-3">
                                        {/* shop details */}
                                        <Col md="6" >
                                            <address className="mb-3 mb-md-5">
                                                <p className="gray-text mb-3">From</p>
                                                {
                                                    !isLoading && isEditMode && (
                                                        <div>
                                                            <p className="mb-2 name-font-weight">{editModeInvoiceDetails?.name}</p>
                                                            <p className="mb-2">{editModeInvoiceDetails?.shop_name}</p>
                                                            <p className="mb-0">{editModeInvoiceDetails?.shop_address}</p>
                                                            <p className="mb-0">{editModeInvoiceDetails?.shop_city}{editModeInvoiceDetails?.shop_city && ','} {editModeInvoiceDetails?.shop_state}</p>
                                                            <p className="mb-2">{editModeInvoiceDetails?.shop_zip_code} {editModeInvoiceDetails?.shop_country && ', ' + editModeInvoiceDetails.shop_country} </p>
                                                            <p className="mb-0">{editModeInvoiceDetails?.shop_contact_number}</p>
                                                            <p className="email-break mb-1">{editModeInvoiceDetails?.shop_email}</p>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    !isLoading && !isEditMode && (
                                                        <div>
                                                            <p className="mb-2 name-font-weight">{getPreviewInvoiceInfo?.name}</p>
                                                            <p className="mb-2">{getPreviewInvoiceInfo?.shop_name}</p>
                                                            <p className="mb-0">{getPreviewInvoiceInfo?.shop_address}</p>
                                                            <p className="mb-0">{getPreviewInvoiceInfo?.shop_city}{getPreviewInvoiceInfo?.shop_city && ','} {getPreviewInvoiceInfo?.shop_state}</p>
                                                            <p className="mb-2">{getPreviewInvoiceInfo?.shop_zip_code} {getPreviewInvoiceInfo?.shop_country && ', ' + getPreviewInvoiceInfo?.shop_country} </p>
                                                            <p className="mb-0">{getPreviewInvoiceInfo?.shop_contact_number}</p>
                                                            <p className="email-break mb-1">{getPreviewInvoiceInfo?.shop_email}</p>
                                                        </div>
                                                    )
                                                }
                                            </address>
                                        </Col>
                                        <Col md="6" className="d-flex justify-content-md-end ps-">
                                            <div className={`billed-to-container ${isEditMode ? '' : ' mb-3'}`}>
                                                <p className={`gray-text  ${!isEditMode && state.mode === 'previewInvoice' ? 'mb-3' : ''}  ${isEditMode ? 'billed-to-title' : 'ps-0 text-md-end text-start'} ${showDropdown ? 'mb-3' : ''} ${!showDropdown && editModeInvoiceDetails?.invoice_client_details !== '' ? 'mb-1' : ''}`}>Billed To</p>
                                                {
                                                    !isLoading && isEditMode && !showDropdown && editModeInvoiceDetails?.invoice_client_details !== '' && (
                                                        <div className="d-flex selected-recipient-container form-control justify-content-between border-1 pt-2 pb-2 pe-2">
                                                            <div className="selected-client-name-container">
                                                                <p className="mb-1 selected-client-info name-font-weight" id="client_company_name">{editModeInvoiceDetails?.invoice_client_details?.client_company_name}</p>
                                                                <p className="mb-1 selected-client-info" id="client_name">{editModeInvoiceDetails?.invoice_client_details?.client_name}</p>
                                                                {
                                                                    editModeInvoiceDetails?.invoice_client_details?.client_company_name.length > 33 && (
                                                                        <UncontrolledTooltip target='client_company_name' style={{ background: 'black' }}>
                                                                            {editModeInvoiceDetails?.invoice_client_details?.client_company_name}
                                                                        </UncontrolledTooltip>
                                                                    )
                                                                }
                                                                {
                                                                    editModeInvoiceDetails?.invoice_client_details?.client_name?.length > 33 && (
                                                                        <UncontrolledTooltip target="client_name" style={{ background: 'black' }}>
                                                                            {editModeInvoiceDetails?.invoice_client_details?.client_name}
                                                                        </UncontrolledTooltip>
                                                                    )
                                                                }
                                                                <p className="mb-0 selected-client-info" id="client_email">{editModeInvoiceDetails?.invoice_client_details?.client_email}</p>
                                                                {
                                                                    editModeInvoiceDetails?.invoice_client_details?.client_email.length > 33 && (
                                                                        <UncontrolledTooltip target='client_email' style={{ background: 'black' }}>
                                                                            {editModeInvoiceDetails?.invoice_client_details?.client_email}
                                                                        </UncontrolledTooltip>
                                                                    )
                                                                }

                                                            </div>
                                                            {
                                                                (getPreviewInvoiceInfo?.invoice_status === 'draft' || currentStatus === 'draft') && (
                                                                    <div>
                                                                        <i className="mdi mdi-file-replace-outline font-size-16 me-2 text-success choose-recipient-icon cursor-pointer" id="Change-recipient" onClick={() => {
                                                                            setEditModeInvoiceDetails(prevData => {
                                                                                return {
                                                                                    ...prevData,
                                                                                    invoice_client_details: '',
                                                                                    due_date: editModeInvoiceDetails.due_date,
                                                                                    invoice_items: editModeInvoiceDetails.invoice_items
                                                                                }
                                                                            });
                                                                        }} />
                                                                        <UncontrolledTooltip target="Change-recipient" style={{ background: 'black' }}>
                                                                            change
                                                                        </UncontrolledTooltip>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }
                                                {
                                                    isEditMode && !isLoading && (
                                                        <ChooseClientDropdown customerQuoteClientList={customerQuoteClientList} showDropdown={showDropdown} toggleDropdown={() => { setShowDropdown(!showDropdown) }} button_title={'Choose a recipient'} invoice_client_details={editModeInvoiceDetails.invoice_client_details} onAction={(option) => {
                                                            setEditModeInvoiceDetails(prev => {
                                                                return {
                                                                    ...prev,
                                                                    invoice_client_details: option
                                                                };
                                                            });
                                                            if (!customerQuoteClientList.some(obj => obj.client_name === option.client_name && obj.client_email === option.client_email)) {
                                                                getCustomerClientList();
                                                            }
                                                        }} />
                                                    )
                                                }
                                                {
                                                    isEditMode && !isLoading && (
                                                        <p className="mb-0 client-address">{editModeInvoiceDetails?.invoice_client_details?.client_address}</p>
                                                    )
                                                }
                                                {
                                                    isEditMode && !isLoading && (
                                                        <p className="mb-0 client-address">
                                                            {editModeInvoiceDetails?.invoice_client_details?.client_city &&
                                                                `${editModeInvoiceDetails.invoice_client_details.client_city}, `}
                                                            {editModeInvoiceDetails?.invoice_client_details?.client_state}
                                                        </p>
                                                    )
                                                }
                                                {
                                                    isEditMode && !isLoading && (
                                                        <p className="mb-2 client-address">{editModeInvoiceDetails?.invoice_client_details?.client_zip_code}{editModeInvoiceDetails?.invoice_client_details?.client_zip_code !== '' && editModeInvoiceDetails?.invoice_client_details?.client_zip_code && ','} {editModeInvoiceDetails?.invoice_client_details?.client_country}</p>
                                                    )
                                                }
                                                {
                                                    isEditMode && !isLoading && (
                                                        <div className="d-flex align-items-center mt-2 text-md-end text-start">
                                                            <p className="mb-0 client-address me-3 invoice-item_description date-selector-title text-md-end text-start">Issused</p>
                                                            <Flatpickr
                                                                className="form-control  d-block flatpicker-field"
                                                                placeholder="M dd, yyyy"
                                                                value={editModeInvoiceDetails?.issued_on === null || editModeInvoiceDetails?.issued_on === undefined ? new Date().setMonth(new Date().getMonth()) : new Date(editModeInvoiceDetails.issued_on)}
                                                                id="issued_on"
                                                                options={{
                                                                    altInput: true,
                                                                    altFormat: "F j, Y",
                                                                    dateFormat: "Y-m-d",
                                                                }}
                                                                onChange={([date]) => {
                                                                    setEditModeInvoiceDetails(prev => {
                                                                        return {
                                                                            ...prev,
                                                                            issued_on: date
                                                                        };
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                {
                                                    isEditMode && !isLoading && (
                                                        <div className="d-flex align-items-center mt-2 mb-4">
                                                            <p className="mb-0 client-address invoice-item_description date-selector-title me-3 text-md-end text-start">Due</p>
                                                            <Flatpickr
                                                                ref={handleFlatpickrInstance}
                                                                className="form-control ms-sm-4 ms-sm-3 d-block flatpicker-field"
                                                                placeholder="M dd,yyyy"
                                                                value={editModeInvoiceDetails?.due_date === null ? new Date().setMonth(new Date().getMonth() + 1) : new Date(editModeInvoiceDetails?.due_date)}
                                                                id="expiresAt"
                                                                options={{
                                                                    altInput: true,
                                                                    altFormat: "F j, Y",
                                                                    dateFormat: "Y-m-d",
                                                                }}
                                                                onOpen={() => {
                                                                    // Set minDate to "same as issue date" every time the Flatpickr is opened
                                                                    flatpickrRef.current?.flatpickr?.set(
                                                                        "minDate",
                                                                        editModeInvoiceDetails?.issued_on == null
                                                                            ? new Date().toISOString()
                                                                            : editModeInvoiceDetails?.issued_on
                                                                    );
                                                                }}
                                                                onChange={([date]) => {
                                                                    setEditModeInvoiceDetails(prev => {
                                                                        return {
                                                                            ...prev,
                                                                            due_date: (date)
                                                                        };
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                {
                                                    !isEditMode && !isLoading && (
                                                        <div className="mb-4 text-md-end text-start">
                                                            <p className={`mb-1 selected-client-info  name-font-weight ${getPreviewInvoiceInfo?.client_company_name.length > 39 ? 'cursor-pointer' : ''} client_info`} id="client_company_name">{getPreviewInvoiceInfo?.client_company_name}</p>
                                                            <p className={`mb-1 selected-client-info ${getPreviewInvoiceInfo?.client_name?.length > 39 ? 'cursor-pointer' : ''} client_info`} id="client_name">{getPreviewInvoiceInfo?.client_name}</p>
                                                            <p className={`mb-2 selected-client-info client_info ${getPreviewInvoiceInfo?.client_email.length > 39 ? 'cursor-pointer' : ''}`} id="client_email">{getPreviewInvoiceInfo?.client_email}</p>
                                                            {
                                                                getPreviewInvoiceInfo?.client_address && (
                                                                    <p className="mb-0">{getPreviewInvoiceInfo?.client_address}</p>
                                                                )
                                                            }
                                                            <p className="mb-0 client-address ps-0 ps-md-2">{getPreviewInvoiceInfo?.client_city}{getPreviewInvoiceInfo?.client_city && ','} {getPreviewInvoiceInfo?.client_state}</p>
                                                            <p className="mb-2 client-address ps-0 ps-md-2">{getPreviewInvoiceInfo?.client_zip_code}{getPreviewInvoiceInfo?.client_zip_code && ','} {getPreviewInvoiceInfo?.client_country}</p>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    !isEditMode && !isLoading && (
                                                        <div className="d-flex justify-content-md-end justify-content-start align-items-center mt-2 mb-2 ">
                                                            <p className="mb-0 me-1 invoice-item_description ">Issued:</p>
                                                            <p className="mb-0 ">
                                                                {getPreviewInvoiceInfo?.issued_on ?
                                                                    displayUTCDate(getPreviewInvoiceInfo?.issued_on) : null}
                                                            </p>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    !isEditMode && !isLoading && (
                                                        <div className="d-flex justify-content-md-end justify-content-start align-items-center mt-2">
                                                            <p className="mb-0 me-1 invoice-item_description text-end">Due:</p>
                                                            <p className="mb-0 text-start">
                                                                {getPreviewInvoiceInfo?.due_date ?
                                                                    displayUTCDate(getPreviewInvoiceInfo?.due_date) : null}
                                                            </p>

                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    {
                                        ((isEditMode && state.mode === "previewInvoice") || (isEditMode && state.mode === 'editMode') || (state.quote_uuid !== null && isEditMode && state.mode === 'create-from-list') || (isEditMode && state === 'create-new')) && (
                                            <Formik
                                                key={getFormKey}
                                                initialValues={{ invoiceItem: editModeInvoiceDetails.invoice_items || [], }}
                                                validationSchema={validationPreviewQuoteItemSchema}
                                            >
                                                {({ values, setFieldValue, errors, isValid }) => {
                                                    setisValidPreviewForm(isValid);
                                                    const handleAddItem = async () => {
                                                        const newItem = {
                                                            invoice_item_details: {
                                                                id: editModeInvoiceDetails?.invoice_items?.length + 1,
                                                                item_description: null,
                                                                item_name: null,
                                                                item_quantity: null,
                                                                item_price: null,
                                                                invoice_item_tax_details: []
                                                            }
                                                        };
                                                        setEditModeInvoiceDetails(prevData => {
                                                            const updatedItems = [...prevData.invoice_items, newItem];
                                                            Subtotal(updatedItems);
                                                            return { ...prevData, invoice_items: updatedItems };
                                                        });
                                                        setFieldValue('invoiceItem', [...values.invoiceItem, newItem], true);
                                                    }

                                                    return (
                                                        <div className="table-responsive">
                                                            <Form>
                                                                <FieldArray name="invoiceItem">
                                                                    {({ push, remove }) => (
                                                                        <Table className="table-nowrap preview-quote-table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th className={`table-th width-500 preview-table-th`}>Garment {!isLoading && (<span className="text-danger">*</span>)}  </th>
                                                                                    <th className={`table-th  text-start width-250`}>Quantity {!isLoading && (<span className="text-danger">*</span>)}</th>
                                                                                    <th className={`table-th text-start width-200`}>Rate {!isLoading && (<span className="text-danger">*</span>)}</th>
                                                                                    <th className={`text-end table-th width-120`}>Amount</th>
                                                                                    <th className="table-th text-end width-0"></th>
                                                                                </tr>
                                                                            </thead>
                                                                            {
                                                                                (!isLoading && isEditMode) && (
                                                                                    <tbody>
                                                                                        {values.invoiceItem.map((item, index) => (
                                                                                            <tr key={index}>
                                                                                                <td className="ps-2 ps-sm-3">
                                                                                                    <input type="text" className="width-300 preview-input-placeholder  form-control" id="item_name"
                                                                                                        name={`invoiceItem.${index}.invoice_item_details.item_name`}
                                                                                                        placeholder="Enter item name" value={item.invoice_item_details.item_name} onChange={(e) => {
                                                                                                            handleEditInvoiceItem(index, 'item_name', e.target.value);
                                                                                                            setFieldValue(`invoiceItem[${index}].invoice_item_details.item_name`, e.target.value || '', true);
                                                                                                        }} />
                                                                                                    {
                                                                                                        errors && errors?.invoiceItem && errors?.invoiceItem[index] && errors?.invoiceItem[index]?.invoice_item_details && errors?.invoiceItem[index]?.invoice_item_details?.item_name && item.invoice_item_details.item_name !== null && (
                                                                                                            <p className="mb-0 error-message">{errors?.invoiceItem[index]?.invoice_item_details?.item_name}</p>
                                                                                                        )
                                                                                                    }
                                                                                                    <textarea placeholder="Enter item description " name={`invoiceItem.${index}.invoice_item_details.item_description`} id={`item_description.${index}`} onChange={(e) => {
                                                                                                        handleEditInvoiceItem(index, 'item_description', e.target.value);
                                                                                                        setFieldValue(`invoiceItem[${index}].invoice_item_details.item_description`, e.target.value || '', true);
                                                                                                    }} value={item.invoice_item_details.item_description} className="form-control mt-2 preview-input-placeholder width-300" ></textarea>
                                                                                                </td>
                                                                                                <td className={`${isEditMode ? 'text-start align-top ps-2 ps-sm-3' : 'text-center table-data'}  `}>
                                                                                                    <NumericFormat
                                                                                                        id="item_quantity" decimalScale={0} value={item.invoice_item_details.item_quantity} className="form-control preview-input-placeholder width-100"
                                                                                                        name={`invoiceItem.${index}.invoice_item_details.item_quantity`} placeholder="Enter quantity" onKeyDown={(e) => {
                                                                                                            if (e.key === '-') {
                                                                                                                e.preventDefault();
                                                                                                            }
                                                                                                        }} onChange={(e) => {
                                                                                                            handleEditInvoiceItem(index, 'item_quantity', e.target.value === '' ? null : +e.target.value);
                                                                                                            setFieldValue(`invoiceItem[${index}].invoice_item_details.item_quantity`, e.target.value === '' ? null : +e.target.value, true);
                                                                                                        }}
                                                                                                    />
                                                                                                    {
                                                                                                        errors && errors?.invoiceItem && errors?.invoiceItem[index] && errors?.invoiceItem[index]?.invoice_item_details && errors?.invoiceItem[index]?.invoice_item_details?.item_quantity && item.invoice_item_details?.item_quantity !== null && (
                                                                                                            <p className="mb-0 error-message text-start">{errors?.invoiceItem[index]?.invoice_item_details?.item_quantity}</p>
                                                                                                        )
                                                                                                    }
                                                                                                </td>
                                                                                                <td className={` ${isEditMode ? 'align-top ps-2 ps-sm-3' : 'table-data text-end'} `}>
                                                                                                    <NumericFormat
                                                                                                        id="item_price" decimalScale={2} value={item.invoice_item_details.item_price} className="form-control preview-input-placeholder width-100"
                                                                                                        name={`invoiceItem.${index}.invoice_item_details.item_price`} placeholder="Enter Rate" onKeyDown={(e) => {
                                                                                                            if (e.key === '-') {
                                                                                                                e.preventDefault();
                                                                                                            }
                                                                                                            let value = e.target.value.trim(); // Trim to remove any leading or trailing spaces
                                                                                                            if (value.endsWith('.')) {
                                                                                                                value += '0'; // Append a zero
                                                                                                            }
                                                                                                            value = value === '' ? null : +value;
                                                                                                            handleEditInvoiceItem(index, 'item_price', value);
                                                                                                            setFieldValue(`invoiceItem[${index}].invoice_item_details.item_price`, value, true);
                                                                                                        }} onChange={(e) => {
                                                                                                            let value = e.target.value.trim(); // Trim to remove any leading or trailing spaces
                                                                                                            // Check if the entered value ends with a decimal point
                                                                                                            if (value.endsWith('.')) {
                                                                                                                value += '0'; // Append a zero
                                                                                                            }
                                                                                                            // Convert the value to a number, or null if empty
                                                                                                            value = value === '' ? null : +value;

                                                                                                            // Call your handleEditInvoiceItem function with the formatted value
                                                                                                            handleEditInvoiceItem(index, 'item_price', value);
                                                                                                            setFieldValue(`invoiceItem[${index}].invoice_item_details.item_price`, value, true);
                                                                                                        }}
                                                                                                    />
                                                                                                    {
                                                                                                        errors && errors?.invoiceItem && errors?.invoiceItem[index] && errors?.invoiceItem[index]?.invoice_item_details && errors?.invoiceItem[index]?.invoice_item_details?.item_price && item.invoice_item_details?.item_price !== null && (
                                                                                                            <p className="mb-0 error-message text-start">{errors?.invoiceItem[index]?.invoice_item_details?.item_price}</p>
                                                                                                        )
                                                                                                    }
                                                                                                    <TaxSelect options={getTaxInfo}
                                                                                                        updatedTaxInfo={updatedTaxInfo}
                                                                                                        shouldShowSelectedTax={true}
                                                                                                        componentName={'previewInvoice'}
                                                                                                        customer_shop_tax_master_uuid={get_customer_shop_tax_master_uuid}
                                                                                                        selectedTaxInfo={selectedTaxInfo}
                                                                                                        shouldRefreshSelectedTaxInfo={getSelectedTaxInfo.length === 0 ? true : false}
                                                                                                        getAlreadyAddedTax={
                                                                                                            (item.invoice_item_details.invoice_item_tax_details === null || item.invoice_item_details.invoice_item_tax_details === undefined) ? {
                                                                                                                invoice_item_uuid: item.invoice_item_details.invoice_item_uuid === undefined ? item.invoice_item_details.id : item.invoice_item_details.invoice_item_uuid
                                                                                                                , invoice_item_tax_details: []
                                                                                                            } : {
                                                                                                                invoice_item_uuid: item.invoice_item_details.invoice_item_uuid === undefined ? item.invoice_item_details.id : item.invoice_item_details.invoice_item_uuid,
                                                                                                                invoice_item_tax_details: item.invoice_item_details.invoice_item_tax_details
                                                                                                            }} />
                                                                                                </td>
                                                                                                <td className={` text-end ${isEditMode ? ' pe-1 pe-sm-3' : ' table-data'}`}>
                                                                                                    <NumericFormat decimalScale={2} prefix="$" className="border-0 text-end width-120 height-40" value={(item.invoice_item_details.item_price * item.invoice_item_details.item_quantity).toFixed(2)} />
                                                                                                    {
                                                                                                        item.invoice_item_details.invoice_item_tax_details &&
                                                                                                        item.invoice_item_details.invoice_item_tax_details
                                                                                                            .filter(taxInfo => taxInfo.tax_percentage !== null)
                                                                                                            .map((taxInfo, index) => (
                                                                                                                <div className="mt-1 text-end" key={index}>
                                                                                                                    <span className="text-end">
                                                                                                                        ${(item.invoice_item_details.item_quantity * item.invoice_item_details.item_price * (taxInfo.tax_percentage / 100)).toFixed(2)}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            ))
                                                                                                    }
                                                                                                </td>
                                                                                                <td className={`text-end ${isEditMode ? 'align-top' : ' table-data'}`} >
                                                                                                    <i className="mdi mdi-trash-can delete-icon mt-3 me-sm-3 text-danger font-size-16 cursor-pointer" id={`deletetooltip${index}`} onClick={() => { setConfirmationModal(true); setIsModal('Delete item'); setDeleteInfo(editModeInvoiceDetails.invoice_items[index].invoice_item_details) }} />
                                                                                                    <UncontrolledTooltip placement="bottom" target={`deletetooltip${index}`}>Delete</UncontrolledTooltip>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                        {
                                                                                            !isLoading && (
                                                                                                <tr >
                                                                                                    <td colSpan={6} className="border-0">
                                                                                                        <button className="btn-secondary ms-0 add-button btn btn-soft-success waves-light btn " onClick={handleAddItem}> <i className="mdi mdi-plus p-0 m-0 me-1" /><span className="me-1">Add Item</span> </button>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            !isLoading && editModeInvoiceDetails?.invoice_items?.length !== 0 && (
                                                                                                <tr >
                                                                                                    <td className="border-0" colSpan={5}>
                                                                                                        <div className="d-flex justify-content-end mt-3">
                                                                                                            <div className="d-flex justify-content-between subtotal-container">
                                                                                                                <div key={getFormKey}>
                                                                                                                    <div className=" mb-2">
                                                                                                                        <span >Subtotal</span>
                                                                                                                    </div>
                                                                                                                    {getTaxArray.map((data, index) => {
                                                                                                                        if (!data) return null;
                                                                                                                        const taxName = data.tax_name;
                                                                                                                        const taxPercentage = parseFloat(data.tax_percentage).toFixed(2);

                                                                                                                        return (
                                                                                                                            <div className="my-2 tax-name" key={index}>
                                                                                                                                {taxName && (
                                                                                                                                    <span id={`tax_name${index}`}>
                                                                                                                                        {taxName}  ({taxPercentage}%)
                                                                                                                                    </span>
                                                                                                                                )}
                                                                                                                                {taxName && taxName.length > 27 && (
                                                                                                                                    <UncontrolledTooltip target={`tax_name${index}`} style={{ background: 'black' }}>
                                                                                                                                        {taxName}
                                                                                                                                    </UncontrolledTooltip>
                                                                                                                                )}
                                                                                                                            </div>
                                                                                                                        );
                                                                                                                    })}
                                                                                                                </div>
                                                                                                                <div key={getFormKey}>
                                                                                                                    <p className="m-0 text-end" >
                                                                                                                        ${isLoading ? 0.00 :
                                                                                                                            Number(
                                                                                                                                editModeInvoiceDetails.invoice_items?.reduce((a, v) => {
                                                                                                                                    const itemQuantity = +v.invoice_item_details.item_quantity;
                                                                                                                                    const quotedSalesPrice = +v.invoice_item_details.item_price;
                                                                                                                                    return a + (itemQuantity * quotedSalesPrice);
                                                                                                                                }, 0).toFixed(2)
                                                                                                                            ).toFixed(2)
                                                                                                                        }
                                                                                                                    </p>
                                                                                                                    <p className="text-end mb-0">
                                                                                                                        {
                                                                                                                            isLoading ? '00' : getTaxArray.map((data) => (
                                                                                                                                <div className="my-2">${data?.Amount.toFixed(2)}</div>
                                                                                                                            ))
                                                                                                                        }
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="d-flex justify-content-end mt-2">
                                                                                                            <div className="d-flex justify-content-between total-container">
                                                                                                                <div>
                                                                                                                    <strong className="color-secondary mt-3">Total</strong>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <h4 className="total-value color-secondary" key={getFormKey}>
                                                                                                                        ${(getTaxArray.reduce((acc, data) => acc + data?.Amount, 0) + (editModeInvoiceDetails?.invoice_items?.reduce((acc, v) => acc + (+v.invoice_item_details.item_quantity * +v.invoice_item_details.item_price), 0) || 0)).toFixed(2)}
                                                                                                                    </h4>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        }
                                                                                    </tbody>
                                                                                )
                                                                            }
                                                                        </Table>
                                                                    )}
                                                                </FieldArray>
                                                            </Form>
                                                        </div>
                                                    )
                                                }}
                                            </Formik>
                                        )}
                                    {
                                        (isEditMode && !isLoading) && (
                                            <Row className=" mt-5">
                                                <div className="d-block">
                                                    <div className="mb-3">
                                                        <h5 className="color-secondary">Notes</h5>
                                                        {
                                                            !isLoading && editModeInvoiceDetails !== undefined && editModeInvoiceDetails?.invoice_notes !== undefined && (
                                                                <RichTextEditor editorClassName="editor custom-editor"
                                                                    editorStyle={{ fontSize: "13px", fontFamily: "Poppins, sans-serif", maxHeight: '170px', overflow: 'scroll' }}
                                                                    placeholder="Enter invoice notes"
                                                                    value={editModeInvoiceDetails?.invoice_notes}
                                                                    onChange={(e) => {
                                                                        setEditModeInvoiceDetails(prevData => {
                                                                            return {
                                                                                ...prevData,
                                                                                invoice_notes: e
                                                                            };
                                                                        })
                                                                    }} />
                                                            )
                                                        }
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <button className="btn me-3 btn-outline" type="button" onClick={() => {
                                                            if (currentMode === 'add') {
                                                                navigate(-1);
                                                            } else {
                                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                                                setisEditMode(false);
                                                                setCurrentMode('let-edit');
                                                                Subtotal(getPreviewInvoiceInfo.invoice_items);
                                                            }
                                                        }}>cancel</button>
                                                        <button className="btn-fill btn d-flex align-items-center"
                                                            type="button"
                                                            disabled={
                                                                !isValidPreviewForm ||
                                                                (editModeInvoiceDetails?.invoice_items?.length === 0 ||
                                                                    !editModeInvoiceDetails?.invoice_client_details ||
                                                                    !editModeInvoiceDetails?.invoice_name ||
                                                                    editModeInvoiceDetails?.invoice_name.length > 30)}
                                                            onClick={() => { onClickSaveInvoice(); }}>
                                                            {isSaveInvoiceIsLoading ? <Spinner color="light" className='me-1' /> : ''}
                                                            save
                                                        </button>
                                                    </div>
                                                </div>
                                            </Row>
                                        )
                                    }
                                    {
                                        !isEditMode && (
                                            <div className="table-responsive">
                                                <Table className="table-nowrap preview-quote-table">
                                                    <thead>
                                                        <tr>
                                                            <th className={`table-th ${!isLoading && getPreviewInvoiceInfo?.invoice_items?.some(item => !!(item?.invoice_item_details?.invoice_item_tax_details && item.invoice_item_details.invoice_item_tax_details.length)) ? !isLoading && getPreviewInvoiceInfo?.invoice_items?.length !== 0 ? 'width-400' : 'width-450' : 'width-400'} preview-table-th`}>Garment</th>
                                                            <th className={`table-th text-center  ${!isLoading && getPreviewInvoiceInfo?.invoice_items?.some(item => !!(item?.invoice_item_details?.invoice_item_tax_details && item.invoice_item_details.invoice_item_tax_details.length)) ? !isLoading && getPreviewInvoiceInfo?.invoice_items?.length !== 0 ? 'quantity-th' : '' : ''} text-start`}>Quantity</th>
                                                            <th className={`table-th ${!isLoading && getPreviewInvoiceInfo?.invoice_items?.some(item => !!(item?.invoice_item_details?.invoice_item_tax_details && item.invoice_item_details.invoice_item_tax_details.length)) ? !isLoading && getPreviewInvoiceInfo?.invoice_items?.length !== 0 ? 'width-0' : '' : ''}  text-end`}>Rate </th>
                                                            <th className={`text-start ${!isLoading && getPreviewInvoiceInfo?.invoice_items?.some(item => !!(item?.invoice_item_details?.invoice_item_tax_details && item.invoice_item_details.invoice_item_tax_details.length)) ? !isLoading && getPreviewInvoiceInfo?.invoice_items?.length !== 0 ? 'preview-amount-th' : '' : ''} table-th text-end `}>Amount</th>
                                                        </tr>
                                                    </thead>
                                                    {
                                                        !isLoading && (
                                                            <tbody>
                                                                {
                                                                    getPreviewInvoiceInfo?.invoice_items.map((invoice_item, key) => (
                                                                        <tr>
                                                                            <td className={`table-data invoice-name-description ${invoice_item.invoice_item_details.item_description && invoice_item.invoice_item_details.invoice_item_tax_details === '' ? 'align-middle' : 'align-top'} `}>
                                                                                <div className="invoice-data-container">
                                                                                    <span className="invoice-info item_name ">{invoice_item.invoice_item_details.item_name}</span>
                                                                                    {
                                                                                        invoice_item.invoice_item_details.item_description && (
                                                                                            <p className="mb-0 mt-1 invoice_description  invoice-info">{invoice_item.invoice_item_details.item_description}</p>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td className={`table-data text-center`}>
                                                                                <p className="mb-0">{invoice_item.invoice_item_details.item_quantity}</p>
                                                                            </td>
                                                                            <td className={`table-data`}>
                                                                                <div className="">
                                                                                    <p className="mb-0 text-end ">${invoice_item.invoice_item_details.item_price.toFixed(2)}</p>
                                                                                    {invoice_item.invoice_item_details.invoice_item_tax_details && invoice_item.invoice_item_details.invoice_item_tax_details.map((taxInfo, taxIndex) => (
                                                                                        <div className="d-flex justify-content-end" key={taxIndex}>
                                                                                            <div className="d-flex justify-content-between tax_detail_row">
                                                                                                <span id={`text_name${taxIndex}-${key}`} className={`mt-1 me-1 tax-display-quote-items-popup ${taxInfo?.tax_name?.length > (isMobileDevice ? 10 : 19) ? 'cursor-pointer tax-display-quote-items-elipsis' : ''}`}>
                                                                                                    {taxInfo?.tax_name}
                                                                                                </span>
                                                                                                {taxInfo?.tax_name?.length > (isMobileDevice ? 10 : 19) && (
                                                                                                    <UncontrolledTooltip placement="bottom" target={`text_name${taxIndex}-${key}`}>
                                                                                                        {taxInfo.tax_name}
                                                                                                    </UncontrolledTooltip>
                                                                                                )}
                                                                                                {
                                                                                                    taxInfo?.tax_percentage !== null && (
                                                                                                        <span className="mb-0 mt-1">{parseFloat(taxInfo?.tax_percentage).toFixed(2)}%</span>
                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </td>
                                                                            <td className={`table-data`}>
                                                                                <p className="mb-0 text-end">
                                                                                    ${(invoice_item.invoice_item_details.item_price * invoice_item.invoice_item_details.item_quantity).toFixed(2)}
                                                                                </p>
                                                                                {
                                                                                    invoice_item.invoice_item_details.invoice_item_tax_details &&
                                                                                    invoice_item.invoice_item_details.invoice_item_tax_details
                                                                                        .filter(taxInfo => taxInfo.tax_percentage !== null)
                                                                                        .map((taxInfo, index) => (
                                                                                            <div className="mt-1 text-end" key={index}>
                                                                                                <span className="text-end">
                                                                                                    ${(invoice_item.invoice_item_details.item_quantity * invoice_item.invoice_item_details.item_price * (taxInfo.tax_percentage / 100)).toFixed(2)}
                                                                                                </span>
                                                                                            </div>
                                                                                        ))
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                                {
                                                                    getPreviewInvoiceInfo?.invoice_items?.length === 0 && (
                                                                        <tr>
                                                                            <td colSpan={4} className="border-0">
                                                                                <div className="d-flex justify-content-center align-items-center no-item-added-to-quote-info">
                                                                                    <i className="bx bx-info-circle me-1" /> No items added to Invoice.
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                                {
                                                                    !isLoading && getPreviewInvoiceInfo?.invoice_items?.length !== 0 && (
                                                                        <tr >
                                                                            <td className="border-0" colSpan={4}>
                                                                                <div className="d-flex justify-content-end mt-3">
                                                                                    <div className="d-flex justify-content-between subtotal-container">
                                                                                        <div>
                                                                                            <div className=" mb-2">
                                                                                                <span >Subtotal</span>
                                                                                            </div>
                                                                                            {
                                                                                                getTaxArray.length !== 0 && getTaxArray.map((data, index) => (
                                                                                                    <div className="my-2 tax-name">
                                                                                                        {
                                                                                                            data?.tax_name !== null && data?.tax_percentage !== null && (
                                                                                                                <span id={`tax_name${index}`}>
                                                                                                                    {data.tax_name}({parseFloat(data?.tax_percentage).toFixed(2)}%)
                                                                                                                </span>
                                                                                                            )
                                                                                                        }
                                                                                                        {
                                                                                                            data?.tax_name?.length > 27 && (
                                                                                                                <UncontrolledTooltip target={`tax_name${index}`} style={{ background: 'black' }}>
                                                                                                                    {data?.tax_name}
                                                                                                                </UncontrolledTooltip>
                                                                                                            )
                                                                                                        }
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                        </div>
                                                                                        <div>
                                                                                            <p className="m-0 text-end" >
                                                                                                ${isLoading ? 0.00 :
                                                                                                    Number(
                                                                                                        getPreviewInvoiceInfo?.invoice_items?.reduce((a, v) => {
                                                                                                            const itemQuantity = +v.invoice_item_details.item_quantity;
                                                                                                            const quotedSalesPrice = +v.invoice_item_details.item_price;
                                                                                                            return a + (itemQuantity * quotedSalesPrice);
                                                                                                        }, 0).toFixed(2)
                                                                                                    ).toFixed(2)
                                                                                                }
                                                                                            </p>
                                                                                            <p className="text-end mb-0">
                                                                                                {
                                                                                                    isLoading
                                                                                                        ? '00.00'
                                                                                                        : getTaxArray.map((data) => (
                                                                                                            data?.Amount !== 0 && (
                                                                                                                <div className="my-2">${data?.Amount?.toFixed(2)}</div>
                                                                                                            )
                                                                                                        ))
                                                                                                }
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex justify-content-end mt-2">
                                                                                    <div className="d-flex justify-content-between total-container">
                                                                                        <div>
                                                                                            <strong className="color-secondary mt-3">Total</strong>
                                                                                        </div>
                                                                                        <div>
                                                                                            <h4 className="total-value color-secondary" key={getFormKey}>
                                                                                                ${(getTaxArray.reduce((acc, data) => acc + data.Amount, 0) + (getPreviewInvoiceInfo.invoice_items?.reduce((acc, v) => acc + (+v.invoice_item_details.item_quantity * +v.invoice_item_details.item_price), 0) || 0)).toFixed(2)}
                                                                                            </h4>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        )
                                                    }
                                                </Table>
                                            </div>
                                        )
                                    }
                                    {
                                        !isEditMode && !isLoading && getPreviewInvoiceInfo?.invoice_notes !== null && getPreviewInvoiceInfo?.invoice_notes !== '<p><br></p>' && (
                                            <Row className=" mt-5">
                                                <div className=" d-block justify-content-between align-items-center">
                                                    <div className="mb-3">
                                                        <h5 className="color-secondary">Notes</h5>
                                                        {
                                                            getPreviewInvoiceInfo && getPreviewInvoiceInfo.invoice_notes && (
                                                                <div dangerouslySetInnerHTML={{ __html: getPreviewInvoiceInfo.invoice_notes.toString('html') }}></div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Row>
                                        )
                                    }
                                    {/* Modal for image preview */}
                                    <ImagePreviewModal imgSrc={previewImage} onCloseClick={() => setPreviewImage(null)}></ImagePreviewModal>
                                </CardBody>
                                {isLoading && <Spinner className='me-2 position-absolute preview-quote-spinner' />}
                            </Card>
                        </Col>
                    </Row>
                </Container>
                {isActionisLoading && <Spinner className='me-2 position-absolute preview-quote-spinner' />}
                <ConfirmationModal
                    show={confirmationModal}
                    onCloseClick={() => setConfirmationModal(false)}
                    title={
                        isModal === 'Delete item' ? 'Delete item' :
                            isModal === 'Sent invoice' ? 'Send Invoice' :
                                isModal === 'Delete invoice' ? 'Delete Invoice' :
                                    isModal === 'paid' ? 'Mark as Paid' :
                                        isModal === 'unpaid' ? 'Mark as unpaid' :
                                            isModal === 'void' ? 'Cancel invoice' : ''
                    }
                    description={
                        isModal === 'Delete item' ? 'Are you sure you want to delete this invoice item?' :
                            isModal === 'Sent invoice' ? '' :
                                isModal === 'Delete invoice' ? 'Are you sure you want to delete this invoice?' :
                                    isModal === 'paid' ? 'Are you sure you want to mark this invoice as paid?' :
                                        isModal === 'unpaid' ? 'Are you sure you want to mark this invoice as unpaid?' :
                                            isModal === 'void' ? 'Are you sure you want to mark this invoice as cancelled?' : ''}
                    onAction={() =>
                        isModal === 'Delete item' ? isDeleteInvoiceItem() :
                            isModal === 'Sent invoice' ? isSendHandle() :
                                isModal === 'Delete invoice' ? isDeleteInvoiceHandle() :
                                    (isModal === 'paid' || isModal === 'void' || isModal === 'unpaid') ? changeInvoiceStatus() : ''}
                    isSendQuote={isSendQuote}
                    afterAction={afterAction}
                    actionName={
                        isModal === 'Sent invoice'
                            ? 'Send'
                            : (isModal === 'paid' || isModal === 'unpaid' || isModal === 'void')
                                ? 'Confirm'
                                : 'Delete'
                    }
                    quote={getPreviewInvoiceInfo}
                />
            </div>
            {/* toast message */}
            <ToastContainer />
        </React.Fragment >
    );
}

export default PreviewInvoice;