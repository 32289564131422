import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, Table, UncontrolledTooltip, Collapse, Spinner, Alert } from "reactstrap";
import classnames from "classnames";
import { useParams } from "react-router-dom";
import { InvoiceService } from "../../invoices/service/invoice.service";
import { AWS_S3_URL, paymentMessage } from "../../shared/static/constants/constant";
import ImagePreviewModal from "../../shared/components/common/imagePreviewModal";
import Spinners from '../../shared/components/common/spinner';
import './standaloneInvoicePreview.scss';
import CreditCardPopUp from "../../shared/components/common/creditcardPopup";
import moment from 'moment';
import { ShopOwnerGetPaid } from "../../shopOwnerGetPaid/service/ShopOwnerGetPaid.service";
import html2pdf from 'html2pdf.js';
import { ToastContainer, toast } from "react-toastify";
import { ImageURLTOBase64Convertor } from "../../shared/helpers/imageUrlToBase64";

const StandaloneInvoicePreview = () => {
    const [col1, setcol1] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState('');
    const { invoiceID } = useParams();
    const [isLoading, setLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    const [getTaxArray, setTaxArray] = useState([]);
    const [isMobileDevice, setisMobiledevice] = useState(false);
    const [showCreditCardInfo, showAddCreditCardInfo] = useState(false);
    const [paymentInfo, setpaymentInfo] = useState();
    const [paymentStatus, setpaymentStatus] = useState('unknown');
    const [paymentResponseMessage, setPaymentResponseMessage] = useState(paymentMessage.INVOICE_PAYMENT_SUCCESSFULL);
    const [cardLastFourDigit, setCardLastFourDigit] = useState();
    const [cardType, setCardType] = useState();
    const [payableAmount, setPayableAmount] = useState();
    const isOverdue = invoiceDetails && invoiceDetails.due_date && moment(invoiceDetails.due_date).isBefore(moment(), 'day');
    const statusText = getStatusText(invoiceDetails, isOverdue);
    const [accountStatus, setAccountStatus] = useState(undefined);
    const [isInvoiceError, setisInvoiceError] = useState(false);
    const invoiceService = new InvoiceService();
    const ShopOwnerGetPaidService = new ShopOwnerGetPaid();

    function getStatusText(invoiceDetails, isOverdue) {
        if (!invoiceDetails) return '';

        switch (invoiceDetails.invoice_status) {
            case 'sent':
            case 'read':
                return isOverdue ? 'OVERDUE' : 'READ';
            case 'cancelled':
                return 'CANCELLED';
            case 'paid':
                return 'PAID';
            default:
                return null;
        }
    }

    useEffect(() => {
        setPayableAmount(+(getTaxArray.reduce((acc, data) => acc + data.Amount, 0) + (invoiceDetails.invoice_items?.reduce((acc, v) => acc + (+v.invoice_item_details.item_quantity * +v.invoice_item_details.item_price), 0) || 0)).toFixed(2));
    }, [getTaxArray])

    useEffect(() => {
        getInvoiceDetails();
        setLoading(true);
    }, []);


    const getAccountLinkVerifyStatus = (user_uuid) => {
        setAccountStatus(undefined);
        ShopOwnerGetPaidService.verifyStripeAccountStatus(user_uuid).then((response) => {
            if (response.status === 200) {
                setLoading(false);
                setAccountStatus(response.data.status)
            }
        }).catch(error => {
            toast.error(error?.response?.data?.errors[0]?.message, { autoClose: 4000 });
        }).finally(() => {
            setLoading(false);
        })
    }

    const getInvoiceDetails = () => {
        setisInvoiceError(false);
        invoiceService.getInvoiceDetailByInvoiceId(invoiceID).then((response) => {
            if (response.status === 200) {
                setInvoiceDetails(response.data);
                let taxIdentifier = [];
                if (response.data.invoice_status === 'sent') {
                    invoiceService.markInvoiceRead({ "invoice_uuid": invoiceID }).then(() => {
                    }).finally(() => {
                        getAccountLinkVerifyStatus(response.data.user_uuid);
                    });
                } else {
                    getAccountLinkVerifyStatus(response.data.user_uuid);
                }
                response.data.invoice_items.forEach((e, key) => {
                    e.invoice_item_details.invoice_item_tax_details
                        ?.filter(tax => tax.tax_name !== null && tax.tax_percentage !== null)
                        ?.forEach((taxInfo) => {
                            const taxPercentage = taxInfo?.tax_percentage;
                            const taxAmount = (+e.invoice_item_details.item_quantity * +e.invoice_item_details.item_price) * (taxInfo?.tax_percentage / 100);

                            // Find if there's already a tax with the same percentage in taxIdentifier
                            const existingTaxIndex = taxIdentifier.findIndex(tax => +tax.tax_percentage === +taxPercentage && tax?.tax_name === taxInfo?.tax_name);

                            if (existingTaxIndex !== -1) {
                                // If tax with the same percentage already exists, add the amount to it
                                taxIdentifier[existingTaxIndex].Amount += taxAmount;
                            } else {
                                // Otherwise, add a new entry
                                taxIdentifier.push({
                                    tax_name: taxInfo?.tax_name,
                                    tax_percentage: taxPercentage?.toFixed(2),  // Ensuring two decimal places
                                    Amount: taxAmount
                                });
                            }
                        });
                });
                setTaxArray(taxIdentifier);
            } else {
                setisInvoiceError(true);
                setLoading(false)
            }
        }).catch(error => {
            toast.error(error?.response?.data?.errors[0]?.message, { autoClose: 4000 });
            setLoading(false);
        });
    }

    useEffect(() => {
        function handleResize() {
            setisMobiledevice(window.innerWidth <= 600 || window.innerHeight <= 600);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => { window.removeEventListener('resize', handleResize); };
    }, []);

    // for convert date to local
    const UTCtoLocal = (utcDate) => {
        const date = new Date(utcDate);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Dynamic time zone based on user's location
        };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(localDate);
        return formattedDate;
    }

    // Utility function to convert HTML string to PDF and download it
    const downloadPdf = async (htmlString, file_name) => {
        // Create a temporary container to hold the HTML content
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
         
        document.body.appendChild(tempDiv);

        // Find all images in the HTML and convert them to base64
        const images = tempDiv.querySelectorAll('img');
        const imagePromises = Array.from(images).map(async (img) => {
            const url = img.src;
            if (url.startsWith('http')) {
                const base64 = await ImageURLTOBase64Convertor.convertImageUrlToBase64(url);
                img.src = base64;
            }
        });

        // Wait for all images to be converted
        await Promise.all(imagePromises);

        // Set up options for html2pdf
        const opt = {
            margin: [0.2, 0.1, 0.2, 0.1],
            filename: file_name,
            html2canvas: {
                scale: 2,
            },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
            enableLinks: true
        };

        // Convert the HTML to PDF
        try {
            await html2pdf().from(tempDiv).set(opt).save();
        } catch (error) {
            console.error("PDF generation error:", error);
        } finally {
            document.body.removeChild(tempDiv);
        }
    };

    // for download quote
    const onClickDownload = () => {
        setIsDownloadLoading(true);
        const invoice_uuid_obj = {
            "invoice_uuid": invoiceID
        }
        invoiceService.downloadInvoice(invoice_uuid_obj).then(async (response) => {
            if (response.status === 200) {
                setTimeout(async () => {
                    await downloadPdf(response.data, `${'invoice'}_${invoiceDetails.invoice_number}.pdf`);
                    setIsDownloadLoading(false);
                }, 2000);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.errors[0]?.message, { autoClose: 4000 });
            setIsDownloadLoading(false);
        });
    }

    const handleChangeCreditCardInfo = (creditcardInfo) => {
        setpaymentStatus(creditcardInfo.status);
        if (creditcardInfo.status === 'done') {
            getInvoiceDetails();
            setPaymentResponseMessage(creditcardInfo.message);
        } else {
            setPaymentResponseMessage(creditcardInfo.message);
            setCardLastFourDigit(creditcardInfo.card_number);
            setCardType(creditcardInfo.card_type);
        }
    }

    return (
        <React.Fragment>
            <>
                <div className="standalone-invoice-component">
                    {
                        paymentStatus === 'unknown' && accountStatus !== undefined && (
                            < div className="">
                                {
                                    accountStatus !== undefined && (
                                        <Container fluid className="px-0">
                                            {/* Render Breadcrumb */}
                                            {
                                                accountStatus !== undefined && (
                                                    <div className="align-items-center justify-content-between pb-0 px-3 mb-4 header-div d-block d-md-flex pt-2 pt-md-0">
                                                        <h4 className="my-2 font-size-13">
                                                            {
                                                                !isLoading && (
                                                                    <p className="mb-1">Invoice # {invoiceDetails?.invoice_number} <span className="span-round"></span>  ${(getTaxArray.reduce((acc, data) => acc + data.Amount, 0) + (invoiceDetails.invoice_items?.reduce((acc, v) => acc + (+v.invoice_item_details.item_quantity * +v.invoice_item_details.item_price), 0) || 0)).toFixed(2)} <span className="span-round"></span>
                                                                        <span className={` ${(invoiceDetails?.invoice_status === 'sent' || invoiceDetails?.invoice_status === 'read') ? 'read-status' :
                                                                            invoiceDetails?.invoice_status === 'cancelled' ? 'cancelled-status' :
                                                                                invoiceDetails?.invoice_status === 'paid' ? 'paid-status' : ''}  ${(isOverdue && invoiceDetails?.invoice_status !== 'paid') ? 'cancelled-status' : ''}`}>
                                                                            {statusText}
                                                                        </span>
                                                                    </p>
                                                                )
                                                            }
                                                            {
                                                                !isLoading && (
                                                                    <p className="mb-1 invoice-details">#{invoiceDetails?.invoice_number} <span className="span-round"></span> Issued {UTCtoLocal(invoiceDetails?.created_at)} <span className="span-round"></span>  Due  {UTCtoLocal(invoiceDetails?.due_date)}</p>
                                                                )
                                                            }
                                                        </h4>
                                                        {
                                                            accountStatus !== undefined && (

                                                                <div className="page-title-right d-flex align-items-center justify-content-end py-1">
                                                                    {
                                                                        (invoiceDetails.invoice_status !== 'cancelled' && invoiceDetails.invoice_status !== 'paid' && accountStatus !== 'not_completed') && (
                                                                            <p className="mb-0 cursor-pointer make-payment-btn" onClick={() => { showAddCreditCardInfo(true); }}>Make Payment</p>
                                                                        )
                                                                    }
                                                                    <ol className="breadcrumb m-0 d-flex align-items-center position-relative pe-0 justify-content-end">
                                                                        <button type="button" onClick={() => { onClickDownload(); }} className="download-btn py-1 btn btn-outline d-flex align-items-center">
                                                                            {!isDownloadLoading ? <i className="bx bx-download me-2" /> :
                                                                                <Spinners setLoading={setLoading} />
                                                                            }
                                                                            Download
                                                                        </button>
                                                                    </ol>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                            {
                                                invoiceDetails.invoice_status === 'cancelled' && (
                                                    !isLoading && (
                                                        <Alert color="danger" className=" border-0 aler-message  alert py-3 alert-danger fade show mb-4 px-md-3 px-1  alert alert-success fade show">
                                                            This invoice has been voided. you may still view or download it.
                                                        </Alert>
                                                    )
                                                )
                                            }
                                            {
                                                !isLoading && invoiceDetails?.invoice_payment_method !== null && (
                                                    <div className={`aler-message mb-4`} id="accordion">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingOne">
                                                                <button
                                                                    className={classnames(
                                                                        "accordion-button payment_info-button",
                                                                        { collapsed: !col1 }
                                                                    )}
                                                                    type="button"
                                                                    onClick={() => { setcol1(!col1); }}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    {!col1 ? <i className="bx font-size-18 me-2 bx-caret-down"></i> : <i className="bx me-2 font-size-18 bx-caret-up"></i>}    Payment Information
                                                                </button>
                                                            </h2>
                                                            <Collapse isOpen={col1} className="accordion-collapse">
                                                                <div className="accordion-body">
                                                                    <div className="d-flex">
                                                                        <p className="me-2 fw-bold payment_method_title me-sm-5">Payment method</p>
                                                                        {
                                                                            invoiceDetails?.invoice_payment_method === 'card' ? (
                                                                                <p>Invoice was paid using card on  <strong className="fw-bold">
                                                                                    {UTCtoLocal(invoiceDetails.invoice_paid_on)}
                                                                                </strong>.</p>
                                                                            ) : (
                                                                                <p>Invoice was {invoiceDetails?.invoice_payment_method} marked as paid on <strong className="fw-bold">
                                                                                    {UTCtoLocal(invoiceDetails.invoice_paid_on)}
                                                                                </strong>.</p>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <p className="me-2 fw-bold mb-0 net_amount_title me-sm-5">Net Amount</p>
                                                                        <p className="mb-0">${(getTaxArray.reduce((acc, data) => acc + data.Amount, 0) + (invoiceDetails?.invoice_items?.reduce((acc, v) => acc + (+v.invoice_item_details.item_quantity * +v.invoice_item_details.item_price), 0) || 0)).toFixed(2)}</p>
                                                                    </div>
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {
                                                !isLoading && (
                                                    <Row className="gx-0 mx-4 card-row">
                                                        <Col lg="12">
                                                            <Card className="mb-4 preview-quote-card">
                                                                <CardBody className="px-lg-5 mt-4 px-3">
                                                                    <div className="preview-quote-title d-flex justify-content-between align-items-center">
                                                                        <div className="shop-logo d-flex align-items-center cursor-pointer">
                                                                            {invoiceDetails.shop_logo_url !== null && invoiceDetails.shop_logo_url !== 'null' &&
                                                                                <img src={AWS_S3_URL + invoiceDetails.shop_logo_url} onClick={() => setPreviewImage(AWS_S3_URL + invoiceDetails.shop_logo_url)} alt="logo-dark" className="logo-dark-element preview-quote-shop-logo" />
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            <h4 className="font-size-16 text-end my-0 color-secondary mt-1">
                                                                                {invoiceDetails?.invoice_name}
                                                                            </h4>
                                                                            {
                                                                                !isLoading && invoiceDetails?.invoice_number && (
                                                                                    <div className="d-flex align-items-center justify-content-end mt-2 mb-2 ps-sm-5 ps-1">
                                                                                        <p className="mb-0 due-text me-1 text-end" >Invoice #</p>
                                                                                        <p className="mb-0 text-end preview-invoice-info invoice-item_description">
                                                                                            {invoiceDetails?.invoice_number || ""}
                                                                                        </p>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            {
                                                                                !isLoading && (invoiceDetails?.reference_number !== 0 && invoiceDetails?.reference_number !== null) && (
                                                                                    <div className="d-flex align-items-center justify-content-end mt-2 mb-2 ps-sm-5 ps-1">
                                                                                        <p className="mb-0 due-text me-1 text-end" >Reference #</p>
                                                                                        <p className="mb-0 text-end preview-invoice-info invoice-item_description">
                                                                                            {invoiceDetails?.reference_number || ""}
                                                                                        </p>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <Row>
                                                                        {/* shop details */}
                                                                        <Col md="6">
                                                                            <address className="mb-3 mb-md-5">
                                                                                <p className="gray-text mb-3">From</p>
                                                                                <p className="mb-2 name-font-weight">{invoiceDetails?.name}</p>
                                                                                <p className="mb-2">{invoiceDetails.shop_name}</p>
                                                                                <p className="mb-0">{invoiceDetails.shop_address}</p>
                                                                                <p className="mb-0">{invoiceDetails.shop_city}{invoiceDetails.shop_city && ','} {invoiceDetails.shop_state}</p>
                                                                                <p className="mb-2">{invoiceDetails.shop_zip_code}{invoiceDetails.shop_zip_code && ','} {invoiceDetails.shop_country}</p>
                                                                                <p className="mb-0">{invoiceDetails.shop_contact_number}</p>
                                                                                <p className="email-break mb-1">{invoiceDetails.shop_email}</p>
                                                                            </address>
                                                                        </Col>
                                                                        <Col md="6" className="d-flex justify-content-lg-end justify-content-start">
                                                                            <div className={`billed-to-container mb-md-0 mb-3 }`}>
                                                                                <p className={`gray-text text-start text-md-end`}>Billed To</p>
                                                                                {
                                                                                    !isLoading && (
                                                                                        <div className="mb-4 text-md-end text-start">
                                                                                            <p className={`mb-1 selected-client-info client_detail name-font-weight ${invoiceDetails?.client_company_name?.length > 39 ? 'cursor-pointer' : ''}`} id="client_company_name">{invoiceDetails?.client_company_name}</p>
                                                                                            <p className={`mb-1 selected-client-info client_detail ${invoiceDetails?.client_name?.length > 39 ? 'cursor-pointer' : ''}`} id="client_name">{invoiceDetails?.client_name}</p>
                                                                                            {
                                                                                                invoiceDetails?.client_company_name?.length > 39 && (
                                                                                                    <UncontrolledTooltip target='client_company_name' style={{ background: 'black' }}>
                                                                                                        {invoiceDetails?.client_company_name}
                                                                                                    </UncontrolledTooltip>
                                                                                                )
                                                                                            }
                                                                                            {
                                                                                                invoiceDetails?.client_name?.length > 39 && (
                                                                                                    <UncontrolledTooltip target='client_name' style={{ background: 'black' }}>
                                                                                                        {invoiceDetails?.client_name}
                                                                                                    </UncontrolledTooltip>
                                                                                                )
                                                                                            }
                                                                                            <p className={` selected-client-info mb-2 client_detail ${invoiceDetails?.client_email?.length > 39 ? 'cursor-pointer' : ''}`} id="client_email">{invoiceDetails?.client_email}</p>
                                                                                            {
                                                                                                invoiceDetails?.client_email?.length > 39 && (
                                                                                                    <UncontrolledTooltip target='client_email' style={{ background: 'black' }}>
                                                                                                        {invoiceDetails?.client_email}
                                                                                                    </UncontrolledTooltip>
                                                                                                )
                                                                                            }
                                                                                            {invoiceDetails?.client_address &&
                                                                                                <p className="mb-0">
                                                                                                    {invoiceDetails.client_address}
                                                                                                </p>
                                                                                            }
                                                                                            <p className="mb-0">{invoiceDetails.client_city}{invoiceDetails.client_city && ','} {invoiceDetails.client_state}</p>
                                                                                            <p className="mb-0">{invoiceDetails.client_zip_code}{invoiceDetails.client_zip_code && ','} {invoiceDetails.client_country}</p>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    !isLoading && (
                                                                                        <div className="d-flex justify-content-md-end justify-content-start mt-2 mb-2 ">
                                                                                            <p className="mb-0   me-1 invoice-item_description text-start">Issued:</p>
                                                                                            <p className="mb-0 text-start">
                                                                                                {invoiceDetails?.issued_on ? UTCtoLocal(invoiceDetails?.issued_on) : null}
                                                                                            </p>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    !isLoading && (
                                                                                        <div className="d-flex justify-content-md-end justify-content-start mt-2 mb-2">
                                                                                            <p className="mb-0  me-1 invoice-item_description text-end">Due:</p>
                                                                                            <p className="mb-0 text-start">
                                                                                                {invoiceDetails?.due_date ? UTCtoLocal(invoiceDetails?.due_date) : null}
                                                                                            </p>

                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </Col>
                                                                        {/* invoice table */}
                                                                        <div className="table-responsive px-2">
                                                                            <Table className="table-nowrap preview-quote-table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className={`table-th garment-th`}>Garment</th>
                                                                                        <th className={`table-th  text-center width-100`}>Quantity </th>
                                                                                        <th className={`table-th text-end table-header-rate`}>Rate</th>
                                                                                        <th className={`text-end table-th amount-th`}>Amount</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                {
                                                                                    invoiceDetails && invoiceDetails?.invoice_items && invoiceDetails?.invoice_items.length > 0 && (
                                                                                        <tbody>
                                                                                            {
                                                                                                invoiceDetails?.invoice_items.map((invoice_item, key) => (
                                                                                                    <tr>
                                                                                                        <td className={`table-data invoice-name-description ${invoice_item.invoice_item_details.item_description && invoice_item.invoice_item_details.invoice_item_tax_details ? 'align-middle' : 'align-top'} `}>
                                                                                                            <div className="invoice-data-container">
                                                                                                                <span className="invoice-info item_name">{invoice_item.invoice_item_details.item_name}</span>
                                                                                                                {
                                                                                                                    invoice_item.invoice_item_details.item_description && (
                                                                                                                        <p className={`mb-0 mt-1 invoice-info  invoice_description`}>{invoice_item.invoice_item_details.item_description}</p>
                                                                                                                    )
                                                                                                                }
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td className={`table-data text-center`}>
                                                                                                            <p className="mb-0">{invoice_item.invoice_item_details.item_quantity}</p>
                                                                                                        </td>
                                                                                                        <td className={`table-data`}>
                                                                                                            <div className="">
                                                                                                                <p className="mb-0 text-end ">${invoice_item.invoice_item_details.item_price.toFixed(2)}</p>
                                                                                                                {invoice_item.invoice_item_details.invoice_item_tax_details && invoice_item.invoice_item_details.invoice_item_tax_details.map((taxInfo, taxIndex) => (
                                                                                                                    <div className="d-flex justify-content-end" key={taxIndex}>
                                                                                                                        <div className="d-flex justify-content-between tax_detail_row">
                                                                                                                            <span id={`text_name${taxIndex}-${key}`} className={`mt-1 me-1 tax-display-quote-items-popup ${taxInfo?.tax_name?.length > (isMobileDevice ? 10 : 19) ? 'cursor-pointer tax-display-quote-items-elipsis' : ''}`}>
                                                                                                                                {taxInfo?.tax_name}
                                                                                                                            </span>
                                                                                                                            {taxInfo?.tax_name?.length > (isMobileDevice ? 10 : 19) && (
                                                                                                                                <UncontrolledTooltip placement="bottom" target={`text_name${taxIndex}-${key}`}>
                                                                                                                                    {taxInfo?.tax_name}
                                                                                                                                </UncontrolledTooltip>
                                                                                                                            )}
                                                                                                                            {
                                                                                                                                taxInfo?.tax_percentage !== null && taxInfo?.tax_percentage !== undefined ? (
                                                                                                                                    <span className="mb-0 mt-1">{parseFloat(taxInfo?.tax_percentage).toFixed(2)}%</span>
                                                                                                                                ) : null
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ))}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td className={`table-data`}>
                                                                                                            <p className="mb-0 text-end">${(invoice_item.invoice_item_details.item_price * invoice_item.invoice_item_details.item_quantity).toFixed(2)}</p>
                                                                                                            {
                                                                                                                invoice_item.invoice_item_details.invoice_item_tax_details &&
                                                                                                                invoice_item.invoice_item_details.invoice_item_tax_details.map((taxInfo, index) => (
                                                                                                                    taxInfo?.tax_percentage !== null && taxInfo?.tax_percentage !== undefined ? (
                                                                                                                        <div key={index} className="mt-1 text-end">
                                                                                                                            <span className="text-end">
                                                                                                                                ${((invoice_item.invoice_item_details?.item_quantity * invoice_item?.invoice_item_details?.item_price) * (taxInfo?.tax_percentage / 100)).toFixed(2)}
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    ) : null
                                                                                                                ))
                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ))
                                                                                            }
                                                                                            {
                                                                                                invoiceDetails?.invoice_items.length === 0 && (
                                                                                                    <tr>
                                                                                                        <td colSpan={4} className="border-0">
                                                                                                            <div className="d-flex justify-content-center align-items-center no-item-added-to-quote-info">
                                                                                                                <i className="bx bx-info-circle me-1" /> No items added to Invoice.
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                )
                                                                                            }
                                                                                            {
                                                                                                !isLoading && invoiceDetails?.invoice_items.length !== 0 && (
                                                                                                    <tr >
                                                                                                        <td className="border-0" colSpan={4}>
                                                                                                            <div className="d-flex justify-content-end mt-3">
                                                                                                                <div className="d-flex justify-content-between subtotal-container">
                                                                                                                    <div>
                                                                                                                        <div className=" mb-2">
                                                                                                                            <span >Subtotal</span>
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            getTaxArray.length !== 0 && getTaxArray.map((data, index) => (
                                                                                                                                <div className="my-2 tax-name">
                                                                                                                                    <span id={`tax_name${index}`}>{data?.tax_name + '(' + parseFloat(data?.tax_percentage).toFixed(2) + '%)'}</span>
                                                                                                                                    {
                                                                                                                                        data?.tax_name?.length > 27 && (
                                                                                                                                            <UncontrolledTooltip target={`tax_name${index}`}>
                                                                                                                                                {data?.tax_name}
                                                                                                                                            </UncontrolledTooltip>
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            ))
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <p className="m-0 text-end" >
                                                                                                                            ${isLoading ? 0.00 :
                                                                                                                                Number(
                                                                                                                                    invoiceDetails?.invoice_items?.reduce((a, v) => {
                                                                                                                                        const itemQuantity = +v.invoice_item_details.item_quantity;
                                                                                                                                        const quotedSalesPrice = +v.invoice_item_details.item_price;
                                                                                                                                        return a + (itemQuantity * quotedSalesPrice);
                                                                                                                                    }, 0).toFixed(2)
                                                                                                                                ).toFixed(2)
                                                                                                                            }
                                                                                                                        </p>
                                                                                                                        <p className="text-end mb-0">
                                                                                                                            {
                                                                                                                                isLoading ? '00' : getTaxArray.map((data) => (
                                                                                                                                    <div className="my-2">${data.Amount.toFixed(2)}</div>
                                                                                                                                ))
                                                                                                                            }
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="d-flex justify-content-end mt-2">
                                                                                                                <div className="d-flex justify-content-between total-container">
                                                                                                                    <div>
                                                                                                                        <strong className="color-secondary mt-3">Total</strong>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <h4 className="total-value color-secondary">
                                                                                                                            ${(getTaxArray.reduce((acc, data) => acc + data.Amount, 0) + (invoiceDetails.invoice_items?.reduce((acc, v) => acc + (+v.invoice_item_details.item_quantity * +v.invoice_item_details.item_price), 0) || 0)).toFixed(2)}
                                                                                                                        </h4>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                )
                                                                                            }
                                                                                        </tbody>
                                                                                    )
                                                                                }
                                                                            </Table>
                                                                            {
                                                                                !isLoading && invoiceDetails && invoiceDetails?.invoice_items && invoiceDetails?.invoice_items.length === 0 && (
                                                                                    <div className="d-flex justify-content-center align-items-center no-item-added-to-quote-info"><i className="bx bx-info-circle me-1" /> No items added to quote</div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        {/* notes */}
                                                                        {
                                                                            !isLoading && invoiceDetails && invoiceDetails.invoice_notes !== null && invoiceDetails.invoice_notes !== '<p><br></p>' && (
                                                                                <Row className=" mt-5">
                                                                                    <div className="d-sm-flex d-block justify-content-between align-items-center">
                                                                                        <div className="mb-3">
                                                                                            <h5 className="color-secondary">Notes</h5>
                                                                                            <div dangerouslySetInnerHTML={{ __html: invoiceDetails.invoice_notes.toString('html') }}></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Row>
                                                                            )
                                                                        }
                                                                    </Row>
                                                                    {/* Modal for image preview */}
                                                                    <ImagePreviewModal imgSrc={previewImage} onCloseClick={() => setPreviewImage(null)}></ImagePreviewModal>
                                                                </CardBody>
                                                                {isLoading && <Spinner className='me-2 position-absolute preview-quote-spinner' />}
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                        </Container>
                                    )
                                }
                            </div>
                        )}

                    {
                        paymentStatus !== 'unknown' && (
                            (
                                <div className="animation-container">
                                    <Card className="animation_card">
                                        <CardBody className="animation_card_body">
                                            <div className="text-left">
                                                <img className="payment_status_image" src={`${paymentStatus === 'done' ? '/assets/images/payment_successfull.png' : '/assets/images/payment_failed.png'}`} alt="payment status icon" />
                                                {
                                                    paymentStatus === 'done' ? (
                                                        <h1 className="text-left mb-2 mt-3 ps-1">Payment Successful</h1>
                                                    ) : <h1 className="text-left fw-bold mb-2 mt-3  ps-1">
                                                        Oh no, your payment failed
                                                    </h1>
                                                }
                                                <p className="text-left mb-0 text-muted payment_failed_message ps-1 ">
                                                    {paymentStatus === 'done' ? paymentResponseMessage : paymentMessage.INVOICE_PAYMENT_FAILD.replace(/{customer_name}/, invoiceDetails?.client_company_name).replace(/{payment_failure_message}/, paymentResponseMessage)}
                                                </p>
                                                {
                                                    paymentStatus === 'error' && paymentResponseMessage && (
                                                        <h5 className="text-left text-muted payment_error_messages mt-1 mb-4 ps-1">{paymentMessage.INVOICE_PAYMENT_FAILED_MESSAGE.replace(/{payment_failure_reason}/, paymentResponseMessage).replace(/{card_number}/, cardLastFourDigit).replace(/{card_type}/, cardType)}</h5>
                                                    )
                                                }
                                                {
                                                    paymentStatus === 'error' ? (
                                                        <button className="btn btn-danger text-left mt-2 curser-pointer ms-1" onClick={() => { setpaymentStatus('unknown'); setpaymentInfo(undefined); }}>Try Again</button>
                                                    ) : (
                                                        <button className="btn btn-success mt-2 curser-pointer ms-1" onClick={() => { setpaymentStatus('unknown'); }}>Go Back</button>
                                                    )
                                                }
                                                {
                                                    paymentStatus === 'error' && paymentResponseMessage && (
                                                        <p className="text-left should_have_query mt-4 ps-1 mb-4">Should you have any queries? contact us at <a className="text-success print_shop_email" href="mailto:info@theprintshopmaster.com">info@theprintshopmaster.com.</a></p>
                                                    )
                                                }
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            )
                        )
                    }
                </div>
                {(accountStatus === undefined || isLoading ) && <Spinner className='me-2 position-absolute download-quote-spinner' />}
                <CreditCardPopUp show={showCreditCardInfo} oncloseClick={() => { showAddCreditCardInfo(!showCreditCardInfo); }} isEndClient={true} payableAmount={payableAmount} invoiceID={invoiceID} userID={invoiceDetails?.quote_client_info_uuid} onSubmit={handleChangeCreditCardInfo} />
                {
                    isInvoiceError && (
                        <div className="noinvoice_container">
                            <i className="bi bi-info-circle me-2" /> Invoice not found
                        </div>
                    )
                }
            </>
            <ToastContainer />
        </React.Fragment>
    )
}

export default StandaloneInvoicePreview;