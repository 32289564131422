import React from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"

const ImagePreviewModal = ({ imgSrc, onCloseClick }) => {
    // for close modal
    const handleClosePreview = () => {
        onCloseClick();
    };

    return (
        // <Modal isOpen={imgSrc ? true : false} toggle={() => handleClosePreview()} className=" preview-img-modal my-0 h-100 d-flex align-items-center" style={{ maxWidth: 'none', width: 'auto', minWidth: '35vw' }}>
        //     <ModalHeader toggle={() => handleClosePreview()} >
        //     </ModalHeader>
        //     <ModalBody>
        //         <img className="modal-preview-img" src={imgSrc} alt="Preview" onLoad={(e) => { e.target.closest('.modal-dialog').style.width = `${e.target.width}px`; }} />
        //     </ModalBody>
        // </Modal>
        <Modal isOpen={imgSrc ? true : false} toggle={() => handleClosePreview()} className="preview-img-modal my-0 h-100 d-flex align-items-center"
            style={{
                minWidth: '35vw',
                '@media (min-width: 1023px)': {
                    maxWidth: 'none',
                    width: 'auto',
                    minWidth: '35vw',
                }
            }}
        >
            <ModalHeader toggle={() => handleClosePreview()}></ModalHeader>
            <ModalBody>
                <img className="modal-preview-img" src={imgSrc} alt="Preview" onLoad={(e) => {
                    if (window.innerWidth >= 1024) {
                        const imageWidth = e.target.width + 30;
                        e.target.closest('.modal-dialog').style.width = `${imageWidth}px`;
                    } else {
                        const imageHeight = e.target.height + 90;
                        const modalContent = e.target.closest('.modal-content');
                        modalContent.style.height = `${imageHeight}px`;
                    }
                }} />
            </ModalBody>
        </Modal>
    )
}

export default ImagePreviewModal
