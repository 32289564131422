import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Card, CardBody, Label, Input, FormFeedback, Form, UncontrolledTooltip, Modal, ModalHeader, ModalBody, } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import AvatarEditor from 'react-avatar-editor';
import { useRef } from "react";
// Formik Validation
import { Spinner } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDropzone } from 'react-dropzone';
import { registerValidationErr, Regex, SUBSCRIPTION_PLAN_UUID } from '../../../shared/static/constants/constant';
// navigation
import { Link } from "react-router-dom";
import { PatternFormat } from "react-number-format";
import { AWS_S3_URL } from '../../../shared/static/constants/constant';
// loader
import Spinners from '../../../shared/components/common/spinner';
import withRouter from "../../../shared/components/common/withRouter";
import { CustomerService } from "../../../customers/service/customer.service";
import { QuoteService } from '../../../quotes/service/quote.service';
// toast message
import { ToastContainer } from "react-toastify";
import TaxSelect from "../../../shared/components/common/addTax";
import './userProfile.scss';
import ImagePreviewModal from '../../../shared/components/common/imagePreviewModal';
import { useUser } from '../../../profile/component/userContext';
import moment from 'moment';
import { Role } from "../../../authentication/model/authentication.model";
import RichTextEditor from "react-rte";

const UserProfile = (props) => {
  const [customerShop_uuid, setCustomerShop_uuid] = useState('');
  const [userDetails, setUserDetails] = useState();
  const [userInfo, setUserInfo] = useState();
  const [getTaxInfo, setTaxInfo] = useState([]);
  const [quoteNotes, setQuoteNotes] = useState(RichTextEditor.createEmptyValue());
  const [invoiceNotes, setInvoiceNotes] = useState(RichTextEditor.createEmptyValue());
  const [shopContactNo, setShopContactNo] = useState('');
  const [shopContactNoTouched, setShopContactNoTouched] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isPasswordLoading, setIsPasswordLoading] = useState(false)
  // toggle eye icon for password
  const [visiblePassword, setVisiblePassword] = useState(false);
  // profile picture upload
  const [selectedProfilePic, setselectedProfilePic] = useState();
  const [profilePicUrl, setProfilePicUrl] = useState([]);
  // shop logo
  const [shopLogoUrl, setShopLogoUrl] = useState([]);

  const [selectedFileType, setselectedFiletype] = useState('');
  const [ishowcropimage, setishowCropimage] = useState();

  // password modal
  const [passwordModal, setPasswordModal] = useState(false);
  const [isEditEmail, setIsEditEmail] = useState(true);
  const [slideValue, setSlideValue] = useState(10);
  const [previewImage, setPreviewImage] = useState(null);

  const [profilePicTypeError, setProfilePicTypeError] = useState(null);
  const [shopLogoTypeError, setShopLogoTypeError] = useState(null);

  // auth service
  const customerService = new CustomerService();
  const quoteService = new QuoteService();

  useEffect(() => {
    setUserInfo(JSON.parse(sessionStorage.getItem('userLogin')));
  }, []);

  const cropRef = useRef(null);

  const { userData, updateUser } = useUser();

  // for back 
  const navigate = useNavigate();
  // form
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      customerName: (userDetails ? userDetails.name : ''),
      customerEmail: userDetails ? userDetails.email : '',
      shopName: userDetails ? userDetails.shop_name : '',
      shopEmail: userDetails ? userDetails.shop_email : '',
      shopAddress: userDetails ? userDetails.shop_address : '',
      shopCity: userDetails ? userDetails.shop_city : '',
      shopState: userDetails ? userDetails.shop_state : '',
      shopCountry: userDetails ? userDetails.shop_country : '',
      shopZipCode: userDetails ? userDetails.shop_zip_code : '',
      profilePic: userDetails ? userDetails.profile_pic : ''
    },
    validationSchema: Yup.object({
      customerName: Yup.string().required(registerValidationErr.ERR_NAME_REQUIRED)
        .matches(Regex.ONLY_CHAR, registerValidationErr.ERR_NAME_INVALID),
      customerEmail: Yup.string().required(registerValidationErr.ERR_EMAIL_REQUIRED)
        .email(registerValidationErr.ERR_EMAIL_INVALID),
      shopName: Yup.string().required(registerValidationErr.ERR_SHOP_NAME_REQUIRED)
        .matches(Regex.SHOP_NAME, registerValidationErr.ERR_SHOPNAME_INVALID),
      shopEmail: Yup.string().required(registerValidationErr.ERR_EMAIL_REQUIRED)
        .email(registerValidationErr.ERR_EMAIL_INVALID),
      shopCity: Yup.string().matches(Regex.ONLY_CHAR, registerValidationErr.ERR_CITY_INVALID),
      shopState: Yup.string().matches(Regex.ONLY_CHAR, registerValidationErr.ERR_STATE_INVALID),
      shopCountry: Yup.string().matches(Regex.ONLY_CHAR, registerValidationErr.ERR_COUNTRY_INVALID),
      shopZipCode: Yup.string().matches(Regex.ZIP_CODE, registerValidationErr.ERR_INVALID_ZIP_CODE).nullable()
    }),
    onSubmit: (values) => {
      if (userInfo && userInfo.data.role_name === Role.Customer) {
        const editCustomer = {
          name: validation.values.customerName,
          email: validation.values.customerEmail,
          password: validation.values.password,
          profile_pic: (profilePicUrl === null || profilePicUrl === 'null') && (selectedProfilePic === undefined || selectedProfilePic === 'undefined' || !selectedProfilePic) ? null : selectedProfilePic ? selectedProfilePic : '',
          shop_name: validation.values.shopName,
          shop_logo: (shopLogoUrl === null || shopLogoUrl === 'null') && (selectedFiles === undefined || selectedFiles === 'undefined' || !selectedFiles) ? null : selectedFiles ? selectedFiles : '',
          shop_email: validation.values.shopEmail,
          shop_contact_number: shopContactNo,
          shop_address: validation.values.shopAddress,
          shop_city: validation.values.shopCity,
          shop_state: validation.values.shopState,
          shop_country: validation.values.shopCountry,
          shop_zip_code: validation.values.shopZipCode,
          quote_notes: quoteNotes.toString('html') === '<p><br></p>' ? null : quoteNotes.toString('html'),
          invoice_notes: invoiceNotes.toString('html') === '<p><br></p>' ? null : invoiceNotes.toString('html')
        };
        updateCustomer(editCustomer);
      }
    },
  });

  // for admin edit profile
  const adminEditProfile = () => {
    const editCustomer = {
      name: validation.values.customerName,
      email: validation.values.customerEmail,
      profile_pic: (profilePicUrl === null || profilePicUrl === 'null') && (selectedProfilePic === undefined || selectedProfilePic === 'undefined' || !selectedProfilePic) ? null : selectedProfilePic ? selectedProfilePic : '',
    };
    updateCustomer(editCustomer);
  }

  // api call for edit customer
  const updateCustomer = (editCustomer) => {
    setLoading(true);
    const formData = new FormData();
    Object.entries(editCustomer).forEach(([key, value]) => {
      formData.append(key, value);
    });
    customerService.editCustomer(userDetails.user_uuid, formData).then(response => {
      if (response.status === 200) {
        // navigate(-1);
        cancelForm();
        setTimeout(() => {
          getUserDetail();
          const userInfoUpdate = userInfo;
          userInfoUpdate.data.name = validation.values.customerName;
          sessionStorage.setItem('userLogin', JSON.stringify(userInfoUpdate));
          fetchUserData();
        }, 1000);
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  const fetchUserData = async () => {
    // Fetch user data
    const newData = validation.values.customerName;
    const data = {
      username: validation.values.customerName,
      profilePicture: (profilePicUrl === null || profilePicUrl === 'null') && (selectedProfilePic === undefined || selectedProfilePic === 'undefined' || !selectedProfilePic) ? null : selectedProfilePic ? selectedProfilePic.preview : '',
    }
    // Update the user context with the new data
    updateUser(data);
    navigate(-1);
  };

  // check file type
  function fileTypeImg(file, fileType) {
    setishowCropimage(true);
    fileType === 'profilePic' ? setselectedProfilePic(file) : setselectedFiles(file);
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop: useCallback((acceptedFile, rejectedFiles) => {
      if (rejectedFiles && rejectedFiles.length > 0) {
        setProfilePicTypeError('Please select a valid image file.');
      }
      else {
        setProfilePicTypeError('');
        setSlideValue(10);
        setselectedFiletype('profilePic');
        acceptedFile.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            fileType: fileTypeImg(file, 'profilePic')
          })
        );
      }
    }),
    multiple: false
  });

  const { getRootProps: getShopLogoProps, getInputProps: getShopLogoInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop: useCallback((acceptedFile, rejectedFiles) => {
      if (rejectedFiles && rejectedFiles.length > 0) {
        setShopLogoTypeError('Please select a valid image file.');
      } else {
        setShopLogoTypeError('');
        setselectedFiletype('shopLogo');
        setSlideValue(10);
        acceptedFile.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            fileType: fileTypeImg(file, 'shopLogo')
          })
        );
      }
    }),
    multiple: false
  })

  // delete profile pic
  function removeProfilePic() {
    setProfilePicUrl(null);
    setselectedProfilePic('');
  }

  // shop logo file upload
  const [selectedFiles, setselectedFiles] = useState();

  // delete selected logo file
  function removeFile() {
    setShopLogoUrl(null);
    setselectedFiles('');
  }

  const updatedTaxInfo = (newTaxInfo) => {
    setTaxInfo(newTaxInfo);
  }

  // on cancel 
  const cancelForm = () => {
    validation.resetForm();
    setselectedProfilePic('');
    setselectedFiles('');
    setVisiblePassword(false);
    setShopContactNoTouched(false);
    setProfilePicUrl('');
    setShopLogoUrl('');
  }

  useEffect(() => {
    if (userInfo) {
      getUserDetail();
      localStorage.removeItem('taxInfo');
    }
  }, [userInfo]);

  const UTCtoLocal = (utcDate) => {
    const date = new Date(utcDate);
    const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    const options = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Dynamic time zone based on user's location
    };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(localDate);
    return formattedDate;
  }

  const getUserDetail = () => {
    customerService.getCustomerDetail(userInfo?.data.user_uuid).then(response => {
      if (response.status === 200) {
        let shopOwnerInfo = response.data[0]
        setUserDetails(shopOwnerInfo);
        setShopLogoUrl(shopOwnerInfo.shop_logo_url);
        setProfilePicUrl(shopOwnerInfo.profile_pic);
        setQuoteNotes(RichTextEditor.createValueFromString(shopOwnerInfo.quote_notes === null || shopOwnerInfo.quote_notes === '<p><br></p>' || shopOwnerInfo.quote_notes === "null" ? '' : shopOwnerInfo.quote_notes, 'html'));
        setInvoiceNotes(RichTextEditor.createValueFromString(shopOwnerInfo.invoice_notes === null || shopOwnerInfo.invoice_notes === '<p><br></p>' || shopOwnerInfo.invoice_notes === 'null' ? '' : shopOwnerInfo.invoice_notes, 'html'));
        if (shopOwnerInfo.role_name !== 'admin') {
          setCustomerShop_uuid(shopOwnerInfo.customer_shop_info_uuid);
          quoteService.getTaxInfo(shopOwnerInfo.customer_shop_info_uuid).then((response) => {
            if (response.status === 200) {
              setTaxInfo(response.data);
            }
          })
        }
      }
    }).catch((error) => {
      console.error(error)
    });
  }

  // confirm password form
  const passwordValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required(registerValidationErr.ERR_PASSWORD_REQUIRED)
    }),
    onSubmit: (values) => {
      // setIsPasswordLoading(true);
    },
  });

  // for confirm password
  const confirmPassword = () => {
    setIsPasswordLoading(true);
    const password = {
      password: passwordValidation.values.password,
      user_uuid: userInfo.data.user_uuid
    };
    customerService.validatePassword(password).then(response => {
      if (response.status === 200) {
        closeModal();
        setIsEditEmail(false);
        setIsPasswordLoading(false);
      }
    }).finally(() => {
      setIsPasswordLoading(false);
    });
  }

  // for close modal
  const closeModal = () => {
    setPasswordModal(false);
    setVisiblePassword(false);
    passwordValidation.resetForm();
  }

  // cropimage and replace with orignal image
  const handlePreviewSave = async () => {
    if (cropRef) {
      const dataUrl = cropRef.current.getImage().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      const randomString = generateRandomString(8);
      const uniqueFileName = `${randomString}_${selectedFileType === 'shopLogo' ? selectedFiles.path : selectedProfilePic.path}`;
      let croppedImageFile = new File([blob], uniqueFileName, { type: "image/" });
      croppedImageFile.preview = URL.createObjectURL(blob);
      selectedFileType === 'shopLogo' ? setselectedFiles(croppedImageFile) : setselectedProfilePic(croppedImageFile);
      croppedImageFile = '';
      setishowCropimage(false);
      setSlideValue(10);
    }
  }

  function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  const formateCreatedDate = () => {
    return new Date(userInfo?.data?.created_at).toLocaleDateString('en-US', {
      month: '2-digit', day: '2-digit', year: 'numeric'
    });
  }

  return (
    <React.Fragment>
      <div className="page-content user-profile mx-auto">
        <Container fluid>
          <Row className="container-data">
            <Col className={`${userInfo?.data?.role_name === Role.Customer ? 'personal-information-container' : ''} `} lg={`${userInfo && userInfo.data.role_name === Role.Customer ? 7 : 12}`} xl={`${userInfo && userInfo?.data.role_name === Role.Customer ? 8 : 12}`} >
              <h4 className="card-title mb-4">Personal Information</h4>
              <Card >
                <CardBody className={` ${userInfo?.data.subscription_details.subscription_plan_details?.start_date && userInfo?.data.subscription_details.subscription_plan_details.start_date !== null && moment(userInfo?.data.subscription_details.subscription_plan_details.end_date).add(1, 'days').diff(moment(), 'days') <= 15 ? 'pb-sm-5' : ''}  `} >
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      adminEditProfile();
                      return false;
                    }}
                  >
                    <Row className="pe-0">
                      <Col md={`${userInfo?.data.role_name === Role.Customer ? 3 : 2}`} lg={`${userInfo?.data.role_name === Role.Customer ? 4 : 2}`} xl={`${userInfo?.data.role_name === Role.Customer ? 3 : 2}`} className="pe-0">
                        <div className="mb-3 mb-lg-0">
                          <Label>Profile Picture</Label>
                          {/* drag and drop profile pic */}
                          {(!selectedProfilePic) && (!userDetails || (userDetails && (!profilePicUrl) | profilePicUrl === 'null' | profilePicUrl === 'undefined')) ? (
                            <>
                              <div className="dropzone justify-content-center profile-pic-dropzone">
                                <div
                                  className="dz-message needsclick p-0 my-3"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-0">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h6 className="dropzone-text">Drop files here or click to upload.</h6>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div>
                              {selectedProfilePic &&
                                <>
                                  <div className="dropzone-previews" id="file-previews">
                                    <Card
                                      className="mt-0 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete profile-pic-preview"
                                    >
                                      <div className=" my-0">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-md w-100 rounded bg-light profile-pic-img preview-logo-img mb-1 cursor-pointer"
                                          alt={selectedProfilePic.name}
                                          src={selectedProfilePic.preview}
                                          onClick={() => setPreviewImage(selectedProfilePic.preview)}
                                        />
                                        <div className="d-flex justify-content-between align-items-center position-relative">
                                          <i className="bx bx-trash cursor-pointer font-size-17 mt-1 position-absolute profile-pic-delete-icon p-1 text-danger" id="profilePicIcon" onClick={() => {
                                            removeProfilePic()
                                          }}></i>
                                          <UncontrolledTooltip placement="bottom" target="profilePicIcon" style={{ background: 'black' }}>
                                            Remove
                                          </UncontrolledTooltip>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                </>
                              }
                            </div>
                          )}
                          <span className="text-danger image-error-msg">{profilePicTypeError}</span>
                          {userDetails && (profilePicUrl && profilePicUrl !== 'null' && profilePicUrl !== 'undefined') ?
                            <div className="dropzone-previews" id="file-previews">
                              <Card
                                className="mt-0 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete profile-pic-preview">
                                <div className="my-0">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-md w-100 rounded bg-light profile-pic-img preview-logo-img mb-1 cursor-pointer"
                                    alt={'profile_pic'}
                                    src={`${AWS_S3_URL + profilePicUrl}`}
                                    onClick={() => setPreviewImage(`${AWS_S3_URL + profilePicUrl}`)}
                                  />
                                  <div className="d-flex justify-content-between align-items-center position-relative">
                                    <i className="bx bx-trash cursor-pointer font-size-17 mt-1 position-absolute profile-pic-delete-icon p-1 text-danger" id="profilePicIcon" onClick={() => {
                                      removeProfilePic()
                                    }}></i>
                                    <UncontrolledTooltip placement="bottom" target="profilePicIcon" style={{ background: 'black' }}>
                                      Remove
                                    </UncontrolledTooltip>
                                  </div>
                                </div>
                              </Card>
                            </div>
                            : ''}
                        </div>
                      </Col>
                      <Col md={`${userInfo?.data.role_name === Role.Customer ? 9 : 10}`} lg={`${userInfo?.data.role_name === Role.Customer ? 8 : 10}`} xl={`${userInfo?.data.role_name === Role.Customer ? 9 : 10}`} className="customer-detail-section">
                        {/*customer name */}
                        <div className="mb-3">
                          <Label className="form-label">Name <span className='text-danger'>*</span></Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="customerName"
                            placeholder="Enter Name"
                            value={validation.values.customerName}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.customerName && validation.errors.customerName ? true : false
                            }
                          />
                          {validation.touched.customerName && validation.errors.customerName ? (
                            <FormFeedback type="invalid">{validation.errors.customerName}</FormFeedback>
                          ) : null}
                        </div>
                        <Row>
                          {/*customer email */}
                          <Col className='col-md-12 col-12'>
                            <div className="mb-3">
                              <Label className="form-label">Email <span className='text-danger'>*</span></Label>
                              <Input
                                name="customerEmail"
                                className="form-control pe-5"
                                placeholder="Enter email"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                autoComplete='off'
                                value={validation.values.customerEmail || ""}
                                invalid={
                                  validation.touched.customerEmail && validation.errors.customerEmail ? true : false
                                }
                                disabled={isEditEmail}
                              />
                              {validation.values.customerEmail.length > 0 && (isEditEmail && <i className={`edit-email  bx bxs-edit text-success`} id="editEmail" onClick={() => { setPasswordModal(!passwordModal) }}>
                                <UncontrolledTooltip placement="bottom" target="editEmail" style={{ background: 'black' }}>
                                  Edit Email
                                </UncontrolledTooltip>
                              </i>)}
                              {validation.touched.customerEmail && validation.errors.customerEmail ? (
                                <FormFeedback type="invalid">{validation.errors.customerEmail}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {/* footer */}
                    {userInfo && userInfo.data.role_name !== Role.Customer && <Row>
                      <Col>
                        <div className="d-flex justify-content-end">
                          <button
                            className="btn-outline me-3"
                            type="button"
                            onClick={() => navigate(-1)}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn-fill save-user d-flex"
                            disabled={(validation.errors.customerName || validation.errors.customerEmail)}
                          >
                            {isLoading ? <Spinners setLoading={setLoading} /> : ''}
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>}
                  </Form>
                </CardBody>
              </Card>
            </Col>
            {
              userInfo && userInfo?.data.role_name === Role.Customer && (
                <Col className="subscription-information-container" lg={5} xl="4">
                  <h4 className="card-title mb-4">Subscription Information</h4>
                  <Card>
                    <CardBody className="subscription-card-body pb-2">
                      <div>
                        <Row>
                          <Col>
                            {
                              userInfo?.data?.subscription_details.subscription_plan_details !== null && userInfo?.data.subscription_details.subscription_plan_details?.start_date && userInfo?.data.subscription_details.subscription_plan_details.start_date !== null ? (
                                <div className="d-flex justify-content-between mb-3 border-bottom align-items-center">
                                  <h5 className="text-sucess">{userInfo?.data.subscription_details.subscription_plan_details.plan_name} {(userInfo?.data.subscription_details.subscription_plan_details?.start_date && userInfo?.data.subscription_details.subscription_plan_details.start_date !== null) ?
                                    '(' + userInfo?.data.subscription_details.subscription_plan_details.subscription_duration + ' plan)'

                                    : ''} </h5>
                                  <i className={`bx ${userInfo?.data.subscription_details.subscription_plan_details.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.BASIC_PALN ? 'bx-walk' : 'bx-run'} h1 text-yellow mb-0`}></i>
                                </div>
                              ) : (
                                <div className="d-flex justify-content-between mb-3  border-bottom align-items-center">
                                  <h5 className="text-muted">Trial Period</h5>
                                  <i className="mdi mdi-timer h1 text-yellow mb-0"></i>
                                </div>
                              )
                            }
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mb-2">
                            {
                              userInfo?.data.subscription_details.subscription_plan_details?.start_date && userInfo?.data.subscription_details.subscription_plan_details.start_date !== null ? (
                                moment(UTCtoLocal(userInfo?.data.subscription_details.subscription_plan_details.end_date)).diff(moment(), 'days') <= 15 && (
                                  <span className="d-flex remaining-days-countdown justify-content-end align-items-center"> <i id="time-left" className="mdi me-1 font-size-20 cursor-pointer mdi-clock-alert-outline"></i> <UncontrolledTooltip target={'time-left'}>Plan will expire in</UncontrolledTooltip> <span className={`${moment(userInfo?.data.subscription_details.subscription_plan_details.end_date).add(1, 'days').diff(moment(), 'days') <= 15 ? 'remaining-days-countdown' : ''} fw-bold`}>
                                    {moment(UTCtoLocal(userInfo?.data.subscription_details.subscription_plan_details.end_date)).endOf('date').fromNow(true)}
                                  </span></span>
                                )
                              ) : (
                                <span className="d-flex remaining-days-countdown justify-content-end align-items-center"> <i id="time-left" className="mdi me-1 font-size-20 cursor-pointer mdi-clock-alert-outline"></i> <UncontrolledTooltip target={'time-left'}>Plan will expire in</UncontrolledTooltip> <span className={`${moment(new Date(userInfo?.data.subscription_details.user_created_at).toLocaleDateString('en-US', {
                                  month: '2-digit', day: '2-digit', year: 'numeric'
                                })).add(7, 'days').diff(moment(), 'days') <= 15 ? 'remaining-days-countdown' : ''} fw-bold`}>
                                  {moment.duration(moment(moment(formateCreatedDate())).add(6, 'days').endOf('day').diff(moment().endOf('day').format('YYYY-MM-DD'))).humanize()}
                                </span></span>
                              )
                            }
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <Label className="mb-1 form-label">Plan Duration </Label>
                                {
                                  (userInfo?.data.subscription_details && userInfo?.data.subscription_details.subscription_plan_details?.start_date && userInfo?.data.subscription_details.subscription_plan_details.start_date !== null && moment(UTCtoLocal(userInfo?.data?.subscription_details.subscription_plan_details.end_date)).diff(moment().format('YYYY-MM-DD'), 'days') > 15) && (
                                    <span>
                                      <i id="expiry" className="mdi ms-1 d-sm-none d-block position-relative text-muted font-size-18  cursor-pointer mdi-clock-alert-outline" /> <UncontrolledTooltip target='expiry'> Plan will expire in {UTCtoLocal(userInfo?.data?.subscription_details.subscription_plan_details?.end_date)}</UncontrolledTooltip>
                                    </span>
                                  )
                                }
                              </div>
                              {
                                (userInfo?.data?.subscription_details !== null && userInfo?.data?.subscription_details.subscription_plan_details) ? (
                                  <div className="d-flex align-items-center text-muted">
                                    <span className="d-flex align-items-center">
                                      {moment(UTCtoLocal(userInfo?.data?.subscription_details.subscription_plan_details?.end_date)).diff(moment(), 'days') > 15 ? (
                                        <span className="d-flex align-items-center">
                                          From  {UTCtoLocal(userInfo?.data?.subscription_details.subscription_plan_details?.start_date)}  to  {UTCtoLocal(userInfo?.data?.subscription_details.subscription_plan_details?.end_date)}
                                          <i id="expiry-duration" className="mdi ms-1 d-sm-block d-none position-relative text-muted font-size-18  cursor-pointer mdi-clock-alert-outline" /> <UncontrolledTooltip target='expiry-duration'> Plan will expire in {moment(UTCtoLocal(userInfo?.data?.subscription_details.subscription_plan_details?.end_date)).fromNow(true)}</UncontrolledTooltip>
                                        </span>
                                      ) : (
                                        <span className="d-flex align-items-center">
                                          From  {UTCtoLocal(userInfo?.data?.subscription_details.subscription_plan_details?.start_date)}  to  {UTCtoLocal(userInfo?.data?.subscription_details.subscription_plan_details?.end_date)}
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                ) : (
                                  <span className="d-block text-muted">From {moment(formateCreatedDate()).format('MMMM DD YYYY')} to <span className="text-muted">{moment(moment(formateCreatedDate())).add(6, 'days').format('MMMM DD YYYY')}</span></span>
                                )
                              }
                            </div>
                          </Col>
                        </Row>
                        {
                          (userInfo?.data?.subscription_details.subscription_plan_details !== null) && (
                            <Row>
                              <Col>
                                <div className="">
                                  <Label className="mb-1 form-label">Paid Amount</Label>
                                  <span className="text-muted d-block">${userInfo?.data?.subscription_details.subscription_plan_details?.payment_amount}</span>
                                </div>
                              </Col>
                            </Row>
                          )
                        }
                        {
                          (userInfo?.data?.subscription_details.subscription_plan_details !== null) ? (
                            <></>) : (
                            <Row className="d-flex justify-content-end">
                              <Col className="d-flex justify-content-end ">
                                <Link to="/pricing" className="purchase-plan-container">
                                  <span className=''>Purchase Plan</span>
                                </Link>
                              </Col>
                            </Row>
                          )
                        }
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )
            }
          </Row>
          {userInfo && userInfo.data.role_name === Role.Customer && <h4 className="card-title mb-4">Shop Information</h4>}
          {userInfo && userInfo.data.role_name === Role.Customer && <Card>
            <CardBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Row className="pe-0">

                  </Row>
                  {/* shop info */}
                  <Row className="pe-0">
                    <Col md={12} lg={7} xl={9} className="shop-mandatory-detail">
                      {/* shop name */}
                      <div className="mb-3">
                        <Label className="form-label">Shop Name <span className='text-danger'>*</span></Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="shopName"
                          placeholder="Enter Name"
                          value={validation.values.shopName || ''}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.shopName && validation.errors.shopName ? true : false
                          }
                        />
                        {validation.touched.shopName && validation.errors.shopName ? (
                          <FormFeedback type="invalid">{validation.errors.shopName}</FormFeedback>
                        ) : null}
                      </div>
                      <Row>
                        {/* shop email */}
                        <Col className="col-md-6 col-12">
                          <div className="mb-3">
                            <Label for="shopEmail">
                              Shop Email <span className='text-danger'>*</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control pe-5"
                              id="shopEmail"
                              placeholder="Enter Email"
                              value={validation.values.shopEmail || ''}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              disabled={userDetails && userDetails.shop_email ? isEditEmail : false}
                              invalid={
                                validation.touched.shopEmail && validation.errors.shopEmail ? true : false
                              }
                            />
                            {(userDetails && userDetails.shop_email && validation.values.shopEmail.length > 0) &&
                              (isEditEmail && <i className={`edit-email  bx bxs-edit text-success`} id="editShopEmail" onClick={() => { setPasswordModal(!passwordModal) }}>
                                <UncontrolledTooltip placement="bottom" target="editShopEmail" style={{ background: 'black' }}>
                                  Edit Shop Email
                                </UncontrolledTooltip>
                              </i>)}
                            {validation.touched.shopEmail && validation.errors.shopEmail ? (
                              <FormFeedback type="invalid">{validation.errors.shopEmail}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col className="col-md-6 col-12">
                          <div className="mb-3">
                            <Label for="shopContactNo">
                              Shop Contact No. <span className='text-danger'>*</span>
                            </Label>
                            <PatternFormat
                              className="form-control"
                              id="shopContactNo"
                              name="phone"
                              placeholder="Enter Contact No."
                              value={userDetails && userDetails.shop_contact_number ? userDetails.shop_contact_number : (validation.values.shopContactNumber || "")}
                              onChange={validation.handleChange}
                              format="###-###-####"
                              onBlur={(e) => {
                                setShopContactNoTouched(true);
                              }}
                              onValueChange={(values) => {
                                setShopContactNo(values.value);
                                setShopContactNoTouched(false);
                              }}
                            />
                            {(shopContactNo && shopContactNo?.length >= 0 && shopContactNo?.length < 10) ? <span className="invalid-file">{registerValidationErr.ERR_CONTACT_NO_INVALID}</span> : ''}
                            {(shopContactNoTouched && !shopContactNo) ? <span className="invalid-file">{registerValidationErr.ERR_SHOP_CONTACT_NUMBER_REQUIRED}</span> : ''}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    {/* shop logo */}
                    <Col className="pe-0">
                      <div className="mb-3">
                        <Label>Shop Logo</Label>
                        {/* drag and drop logo image */}
                        {(!selectedFiles) && (!userDetails || (userDetails && (!shopLogoUrl || shopLogoUrl === 'null' || shopLogoUrl === 'undefined'))) ? (
                          <div className="dropzone justify-content-center">
                            <div
                              className="dz-message needsclick p-0 my-3"
                              {...getShopLogoProps()}
                            >
                              <input {...getShopLogoInputProps()} />
                              <div className="mb-0">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h6 className="font-size-11">Drop files here or click to upload.</h6>
                            </div>
                          </div>
                        ) : (<div >
                          {selectedFiles &&
                            <>
                              <div className="dropzone-previews" id="file-previews">
                                <Card
                                  className="mt-0 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete"
                                >
                                  <div className="p-2 my-0">
                                    <Row>
                                      <Col className="col-12 d-flex justify-content-center col-md-3 col-lg-12"><img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="rounded shop_logo bg-light preview-logo-img mb-1 cursor-pointer"
                                        alt={selectedFiles.name}
                                        src={selectedFiles.preview}
                                        onClick={() => setPreviewImage(selectedFiles.preview)}
                                      /></Col>
                                      <Col className="col-12 col-md-9 col-lg-12 my-auto">
                                        <div className="d-flex justify-content-between align-items-center">
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold profilePicName mt-1" id="logoName"
                                          >
                                            {selectedFiles.name}
                                          </Link>
                                          <UncontrolledTooltip placement="bottom" target="logoName" style={{ background: 'black' }}>
                                            {selectedFiles.name}
                                          </UncontrolledTooltip>
                                          <i className="bx bx-trash cursor-pointer font-size-17 mt-1 text-danger" id="shopLogoIcon" onClick={() => {
                                            removeFile()
                                          }}></i>
                                          <UncontrolledTooltip placement="bottom" target="shopLogoIcon" style={{ background: 'black' }}>
                                            Remove
                                          </UncontrolledTooltip>
                                        </div></Col>
                                    </Row>
                                  </div>
                                </Card>
                              </div>
                            </>
                          }
                        </div>)}
                        <span className="text-danger image-error-msg">{shopLogoTypeError}</span>
                        {userDetails && (shopLogoUrl && shopLogoUrl !== 'null' && shopLogoUrl !== 'undefined') ?
                          <div className="dropzone-previews" id="file-previews">
                            <Card
                              className="mt-0 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete">
                              <div className="p-2 my-0">
                                <Row>
                                  <Col className="col-12 d-flex justify-content-center col-md-3 col-lg-12">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="rounded bg-light shop_logo preview-logo-img mb-1 cursor-pointer"
                                      alt={'profile_pic'}
                                      src={`${AWS_S3_URL + shopLogoUrl}`}
                                      onClick={() => setPreviewImage(`${AWS_S3_URL + shopLogoUrl}`)}
                                    />
                                  </Col>
                                  <Col className="col-12 col-md-9 col-lg-12 my-auto">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold profilePicName mt-1" id="profilePicName"
                                      >
                                        {'shop_logo' + shopLogoUrl.toString().substring(shopLogoUrl.lastIndexOf("."), shopLogoUrl.length)}
                                      </Link>
                                      <i className="bx bx-trash cursor-pointer font-size-17 mt-1 text-danger" id="shopLogoIcon" onClick={() => {
                                        removeFile()
                                      }}></i>
                                      <UncontrolledTooltip placement="bottom" target="shopLogoIcon" style={{ background: 'black' }}>
                                        Remove
                                      </UncontrolledTooltip>
                                    </div>
                                  </Col>
                                </Row>


                              </div>
                            </Card>
                          </div>
                          : ''}
                      </div>
                    </Col>
                  </Row>
                  <Col className="col-12">
                    {/* shop address */}
                    <div className="mb-3">
                      <Label for="shopAddress">
                        Shop Address
                      </Label>
                      <textarea
                        className="form-control"
                        id="shopAddress"
                        placeholder="Enter Address"
                        rows="2"
                        value={validation.values.shopAddress || ''}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                      ></textarea>
                    </div>
                  </Col>
                  <Row className="pe-0">
                    <Col className="col-md-6 col-12 shop-city-col">
                      {/* shop city */}
                      <div className="mb-3">
                        <Label for="shopCity">
                          City
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="shopCity"
                          placeholder="Enter City"
                          value={validation.values.shopCity || ''}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.shopCity && validation.errors.shopCity ? true : false
                          }
                        />
                        {validation.touched.shopCity && validation.errors.shopCity ? (
                          <FormFeedback type="invalid">{validation.errors.shopCity}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col className="col-md-6 col-12 pe-0">
                      {/* shop state */}
                      <div className="mb-3">
                        <Label for="shopState">
                          State
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="shopState"
                          placeholder="Enter State"
                          value={validation.values.shopState || ''}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.shopState && validation.errors.shopState ? true : false
                          }
                        />
                        {validation.touched.shopState && validation.errors.shopState ? (
                          <FormFeedback type="invalid">{validation.errors.shopState}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row className="pe-0">
                    <Col className="col-md-6 col-12 shop-country-col" >
                      {/* shop country */}
                      <div className="mb-3">
                        <Label for="shopCountry">
                          Country
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="shopCountry"
                          placeholder="Enter Country"
                          value={validation.values.shopCountry || ''}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.shopCountry && validation.errors.shopCountry ? true : false
                          }
                        />
                        {validation.touched.shopCountry && validation.errors.shopCountry ? (
                          <FormFeedback type="invalid">{validation.errors.shopCountry}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col className="col-md-6 col-12 pe-0">
                      {/* zip code */}
                      <div className="mb-3">
                        <Label for="shopZipCode">
                          Zip Code
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="shopZipCode"
                          placeholder="Enter Zip Code"
                          value={validation.values.shopZipCode || ''}
                          onChange={(e) => {
                            const { value } = e.target;
                            // Remove non-numeric and limit to 10 characters
                            const formattedValue = value.replace(/[^0-9]/g, '').slice(0, 10);

                            // Check if formattedValue is empty or just a hyphen
                            if (formattedValue.length > 5) {
                              // Format value with a hyphen only once
                              validation.handleChange({
                                target: {
                                  name: 'shopZipCode',
                                  value: `${formattedValue.slice(0, 5)}-${formattedValue.slice(5)}`,
                                },
                              });
                            } else {
                              // Set the formatted value without adding a hyphen
                              validation.handleChange({
                                target: {
                                  name: 'shopZipCode',
                                  value: formattedValue,
                                },
                              });
                            }
                          }}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.shopZipCode && validation.errors.shopZipCode ? true : false
                          }
                          onKeyDown={(e) => {
                            // Allow Backspace if the length is greater than 0
                            if (e.key === 'Backspace' && validation.values.shopZipCode.length > 0) {
                              return;
                            }

                            // Limit to 10 characters including hyphen
                            if (e.target.value.length >= 10 && e.key !== 'Backspace') {
                              e.preventDefault();
                            }
                            // Allow numeric digits, hyphen, and Backspace
                            if (!(
                              (e.key >= '0' && e.key <= '9') ||
                              e.key === 'Backspace' || e.key === '-'
                            )) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {validation.touched.shopZipCode && validation.errors.shopZipCode ? (
                          <FormFeedback type="invalid">{validation.errors.shopZipCode}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3 pe-0">
                    <Col className="col-12 pe-0 ">
                      {/* quote notes */}
                      <div className="">
                        <Label for="quote_notes">
                          Quote Notes   <i id="quote_tooltip" className="bx bx-info-circle cursor-pointer notes-info-icon position-absolute"></i>
                          <UncontrolledTooltip target={"quote_tooltip"} placement="top">Notes will be included in every quote</UncontrolledTooltip>
                        </Label>
                        <RichTextEditor editorClassName="editor custom-editor"
                          editorStyle={{ fontSize: "13px", fontFamily: "Poppins, sans-serif", maxHeight: '170px', overflow: 'scroll' }}
                          placeholder="Enter quote notes"
                          value={quoteNotes}
                          onChange={(e) => { setQuoteNotes(e) }} />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-0 pe-0">
                    <Col className="col-12 pe-0">
                      {/* invoice notes */}
                      <div className="">
                        <Label for="invoice_notes">
                          Invoice Notes <i id="invoice_tooltip" className="bx bx-info-circle cursor-pointer notes-info-icon position-absolute"></i>
                          <UncontrolledTooltip target={"invoice_tooltip"} placement="top">Notes will be included in every invoice</UncontrolledTooltip>
                        </Label>
                        <RichTextEditor editorClassName="editor custom-editor"
                          editorStyle={{ fontSize: "13px", fontFamily: "Poppins, sans-serif", maxHeight: '170px', overflow: 'scroll' }}
                          placeholder="Enter invoice notes"
                          value={invoiceNotes}
                          onChange={(e) => { setInvoiceNotes(e) }} />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-0 pe-0">
                    <Col className="col-12 Pe-0">
                      <TaxSelect options={getTaxInfo} updatedTaxInfo={updatedTaxInfo} shouldShowSelectedTax={false} componentName={'userProfile'} customer_shop_tax_master_uuid={customerShop_uuid} />
                    </Col>
                  </Row>
                </Row>
                {/* footer */}
                <Row>
                  <Col>
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn-outline me-3"
                        type="button"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn-fill save-user d-flex"
                        disabled={(!validation.isValid) || (userInfo && userInfo.data.role_name !== 'Admin' && shopContactNo.length >= 0 && shopContactNo.length < 10)}
                      >
                        {isLoading ? <Spinner color="light" className='me-2' /> : ''}
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>}
        </Container>
      </div>
      {/* Modal for image preview */}
      <ImagePreviewModal imgSrc={previewImage} onCloseClick={() => setPreviewImage(null)}></ImagePreviewModal>
      {/* confirm password modal */}
      <Modal isOpen={passwordModal} toggle={() => closeModal()} className="confirm-password-modal">
        {/* modal header */}
        <ModalHeader toggle={() => closeModal()} tag="h4">
          {"Confirm Your Email"}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={(e) => {
            e.preventDefault();
            return false;
          }}>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label className="form-label">
                    Please type your current password to confirm your changes. <span className='text-danger'>*</span>
                  </Label>
                  <Input
                    name="password"
                    type={visiblePassword ? 'text' : 'password'}
                    placeholder="Enter Password"
                    autoComplete='off'
                    onChange={passwordValidation.handleChange}
                    onBlur={passwordValidation.handleBlur}
                    value={passwordValidation.values.password || ""}
                    invalid={
                      passwordValidation.touched.password && passwordValidation.errors.password ? true : false
                    }
                  />
                  {passwordValidation.values.password.length > 0 && (<i className={`bi login-eye  ${visiblePassword ? 'bi-eye' : 'bi-eye-slash'}`} onClick={() => { setVisiblePassword(!visiblePassword) }}></i>)}
                  {passwordValidation.touched.password && passwordValidation.errors.password ? (
                    <FormFeedback type="invalid">{passwordValidation.errors.password}</FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            {/* footer */}
            <Row>
              <Col>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn-outline me-3"
                    type="button"
                    onClick={() => closeModal()}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn-fill save-user d-flex"
                    disabled={!passwordValidation.values.password}
                    onClick={() => confirmPassword()}
                  >
                    {isPasswordLoading ? <Spinners setLoading={setIsPasswordLoading} /> : ''}
                    Confirm
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        {/* toast message */}
        <ToastContainer />
      </Modal>
      {/* crop profile crop */}
      <Modal isOpen={ishowcropimage} centered={true} toggle={() => { setishowCropimage(false); selectedFileType === 'shopLogo' ? setselectedFiles(null) : setselectedProfilePic(null); }} className="mb-0 image-crop-modal">
        {/* modal header */}
        <ModalHeader toggle={() => { setishowCropimage(false); selectedFileType === 'shopLogo' ? setselectedFiles(null) : setselectedProfilePic(null); }} tag="h4">
        </ModalHeader>
        <div className="modal-body pb-0">
          <div className="modal-dialog  modal_dialog_container">
            <div className="modal-content border-0 modal_content paragraph_modal">
              <div className="modal-body pb-0 border-0 modal_body pt-0 pb-0">
                <div className='avatar-editor-wrapper'>
                  <AvatarEditor
                    ref={cropRef}
                    image={selectedFileType === 'shopLogo' ? selectedFiles?.preview : selectedProfilePic?.preview}
                    style={{ width: "100%", height: "100%" }}
                    border={50}
                    borderRadius={selectedFileType !== 'shopLogo' ? 150 : 0}
                    color={[0, 0, 0, 0.72]}
                    scale={slideValue / 10}
                    rotate={0}
                  />
                </div>
                <div
                  className="input-group preview-zoom-slider-wrapper my-3"
                >
                  <input
                    type="range"
                    min={10}
                    max={50}
                    value={slideValue}
                    onChange={(e) => setSlideValue(e.target.value)}
                    className="form-range slider preview-zoom-slider"
                  />
                </div>
              </div>
              <div className='modal-footer pb-0 modal_footer mt-0 border-0 justify-content-end'>
                <button className='btn me-3 btn-outline '
                  onClick={(e) => {
                    e.preventDefault();
                    setishowCropimage(false);
                    selectedFileType === 'shopLogo' ? setselectedFiles(null) : setselectedProfilePic(null);
                  }}>
                  <div>Cancel</div>
                </button>
                <button type="submit" className="btn btn-fill save_user " onClick={handlePreviewSave}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* toast message */}
      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
