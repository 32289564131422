import React, { useEffect } from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import axios from "axios";
import "bootstrap-icons/font/bootstrap-icons.css";

import store from "./store";

import { useAtom } from 'jotai';
import { authenticatedUserInfo } from './atoms';

const root = ReactDOM.createRoot(document.getElementById("root"));

const Index = () => {

  const [getUserInfo, setUserInfo] = useAtom(authenticatedUserInfo);

  useEffect(() => {
    sessionStorage.getItem('userLogin') ? setUserInfo(JSON.parse(sessionStorage.getItem('userLogin') || '')) : setUserInfo(null);
  }, [setUserInfo]);
}

root.render(
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);
