import React, { useEffect, useState, useMemo } from "react";
import { Card, CardBody, Col, Container, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge, Modal, ModalBody, ModalHeader, UncontrolledTooltip } from "reactstrap";
import TableContainer from "../../../shared/components/common/tableContainer";
// loader
import Skeleton from 'react-loading-skeleton';
import { Spinner } from "reactstrap";
// services
import { QuoteService } from '../../service/quote.service';
import { QuoteCalculatorService } from "../../../quoteCalculator/service/quoteCalculator.service";
// navigation
import { useNavigate } from "react-router-dom";
import ConfirmationModal from '../../../shared/components/common/confirmationModal';
// toast message
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Breadcrumb from "../../../shared/components/common/breadcrumb";
import { QuoteExpirationStatus } from "../../model/quote.model";
// image prefix
import { AWS_S3_URL } from "../../../shared/static/constants/constant";
import html2pdf from 'html2pdf.js';
import { ImageURLTOBase64Convertor } from "../../../shared/helpers/imageUrlToBase64";

const QuoteList = (props) => {
    const [quoteList, setQuoteList] = useState([]);
    const [allQuoteList, setAllQuoteList] = useState([]);
    // loader
    const [isLoading, setLoading] = useState(false);
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    // check device
    const [isMediumDevice, setIsMediumDevice] = useState(false);
    const [isExtraSmallDevice, setIsExtraSmallDevice] = useState(false);
    const [isExtraExtraSmallDevice, setIsExtraExtraSmallDevice] = useState(false);

    //delete quote
    const [deleteModal, setDeleteModal] = useState(false);
    const [afterAction, setAfterAction] = useState(false);
    const [quoteId, setQuoteId] = useState();
    // send quote
    const [isSendQuote, setIsSendQuote] = useState(false);
    const [actionQuote, setActionQuote] = useState('');
    const [quote, setQuote] = useState();
    const [customerQuoteClientList, setCustomerQuoteClientList] = useState([]);
    // for filter
    const [quoteExpirationStatus, setQuoteExpirationStatus] = useState('');
    const [quoteListClientFilter, setQuoteListClientFilter] = useState('');
    const [quoteStatusFilter, setQuoteStatusFilter] = useState('');
    // for job notes modal
    const [isNoteModel, setIsNoteModel] = useState(false);
    const [jobNotes, setJobNotes] = useState();

    // navigation
    const navigate = useNavigate();

    // service
    const quoteService = new QuoteService();
    const quoteCalculatorService = new QuoteCalculatorService();

    useEffect(() => {
        localStorage.removeItem('taxInfo');
    }, []);

    // for check device
    useEffect(() => {
        function handleResize() {
            window.innerWidth <= 1024 ? setIsMediumDevice(true) : setIsMediumDevice(false);
            window.innerWidth <= 610 ? setIsExtraSmallDevice(true) : setIsExtraSmallDevice(false);
            window.innerWidth <= 281 ? setIsExtraExtraSmallDevice(true) : setIsExtraExtraSmallDevice(false);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        getQuoteListInfo('');
        getCustomerQuoteClientList();
    }, []);

    useEffect(() => {
        filteredQuoteList();
    }, [quoteExpirationStatus, quoteListClientFilter, quoteStatusFilter, allQuoteList]);

    const onSelectedExpirationStatus = (select) => {
        select ? setQuoteExpirationStatus(select.label) : setQuoteExpirationStatus(QuoteExpirationStatus.NotExpired);
    }

    const onSelectedClient = (select) => {
        select.label !== 'All client' ? setQuoteListClientFilter(select.label) : setQuoteListClientFilter('');
    }

    const filteredQuoteList = () => {
        let filteredList = allQuoteList;
        // Filter by client name
        if (quoteListClientFilter !== '') {
            filteredList = filteredList.filter(obj => obj.client_name === quoteListClientFilter);
        }
        // Filter by expiration status
        if (quoteExpirationStatus === QuoteExpirationStatus.Expired) {
            filteredList = filteredList.filter(obj => obj.isExpired === true);
        } else if (quoteExpirationStatus === QuoteExpirationStatus.NotExpired) {
            filteredList = filteredList.filter(obj => obj.isExpired === false);
        }
        // Filter by quote status
        if (quoteStatusFilter !== "All statuses") {
            filteredList = filteredList.filter(obj => obj.quote_status.toLowerCase() === quoteStatusFilter.toLowerCase());
        }
        // Update the state with the filtered list
        setQuoteList(filteredList);
    }

    const onSelectedStatus = (select) => {
        setQuoteStatusFilter(select.label);
    }

    const getCustomerQuoteClientList = () => {
        quoteService.getCustomerQuoteClientList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
            if (response.status === 200) {
                response.data.unshift({ client_name: 'All client' });
                setCustomerQuoteClientList(response.data);
            }
        });
    }

    // for convert date to local
    const UTCtoLocal = (utcDate) => {
        const date = new Date(utcDate);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Dynamic time zone based on user's location
        };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(localDate);
        return formattedDate;
    }

    // quote list Column
    const columns = useMemo(
        () => [
            {
                Header: 'Quote ID',
                accessor: 'quote_number',
                Cell: (cellProps) => {
                    return <div>
                        {!isLoading && <div className="position-relative d-flex align-items-center">#{cellProps.row.original.quote_number} <i className="bx bx-info-circle position-relative cursor-pointer quote-id-info ms-1 text-yellow mb-2px" id="quoteInfo">
                            <div className="quote-id-tooltip">
                                {(cellProps.row.original.quote_item_count !== 0 && cellProps.row.original.quote_item_count !== "0") && <p className="mb-0">Estimated total: ${cellProps.row.original.estimated_total}</p>}
                                <p className="mb-0">No. of items: {cellProps.row.original.quote_item_count}</p>
                            </div>
                        </i></div>}
                        {isLoading && <Skeleton height={20} width={60} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton>}
                    </div>
                }
            },
            {
                Header: 'Job Name',
                accessor: 'job_name',
                filterable: true,
                maxWidth: 400,
                minWidth: 140,
                width: 200,
                Cell: (cellProps) => {
                    return <>
                        {(!isLoading && cellProps.row.original.job_name) && <span className={`job-name ${cellProps.row.original.job_name.length > (isExtraExtraSmallDevice ? 17 : (isExtraSmallDevice ? 17 : (isMediumDevice ? 17 : 28))) ? 'customer_ellipsis' : ''}`} data-toggle='tooltip'
                            data-title={cellProps.row.original.job_name}>{cellProps.row.original.job_name}</span>}
                        {isLoading &&
                            <div><Skeleton className={`mb-0 ${isMediumDevice ? 'width-100' : ''} `} width={195} height={20} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton></div>
                        }
                    </>
                }
            },
            {
                Header: 'Created at',
                accessor: 'created_at',
                filterable: true,
                Cell: (cellProps) => {
                    return <div>
                        {(!isLoading && cellProps.row.original.created_at) && <p className="mb-0 quote-date-div">{UTCtoLocal(cellProps.row.original.created_at)}</p>}
                        {isLoading &&
                            <div><Skeleton className='mb-0' width={80} height={20} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton></div>
                        }
                    </div >;
                }
            },
            {
                Header: 'Expires at',
                accessor: 'quote_expires_at',
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {(!isLoading) && cellProps.row.original.isExpired ?
                            <Badge className="badge-soft-danger me-1 quote-date-div expired-date-badge">
                                {UTCtoLocal(cellProps.row.original.quote_expires_at)}
                            </Badge>
                            : (!isLoading) && !cellProps.row.original.isExpired ? <p className="mb-0 quote-date-div">{UTCtoLocal(cellProps.row.original.quote_expires_at)}</p>
                                : ''
                        }
                        {isLoading &&
                            <div><Skeleton className='mb-0' width={80} height={20} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton></div>
                        }
                    </>;
                }
            },
            {
                Header: 'Client',
                accessor: 'client_company_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {!isLoading && (cellProps.row.original.client_name || cellProps.row.original.client_email) && <>
                            <p className={`mb-1 company-name-email ${cellProps.row.original.client_name?.length > (isExtraExtraSmallDevice ? 20 : (isExtraSmallDevice ? 20 : (isMediumDevice ? 20 : 26))) ? 'customer_ellipsis company-name-tooltip shop-email-tooltip' : ''}`} id="companyNameTooltip"
                                data-toggle='tooltip' data-title={cellProps.row.original?.client_name}>
                                {cellProps.row.original?.client_name}</p>
                            <p className={`mb-0 gray-email company-name-email ${cellProps.row.original.client_email.length > (isExtraExtraSmallDevice ? 18 : (isExtraSmallDevice ? 18 : (isMediumDevice ? 18 : 25))) ? 'customer_ellipsis shop-email-tooltip' : ''}`} id="emailTooltip"
                                data-toggle='tooltip' data-title={cellProps.row.original.client_email}>{cellProps.row.original.client_email}
                            </p>
                        </>
                        }
                        {isLoading && <>
                            <Skeleton className={`mb-1 ${isMediumDevice ? 'company-name-email' : ''} `} height={20} width={200} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton>
                            <Skeleton className={`mb-0 ${isMediumDevice ? 'company-name-email' : ''} `} height={20} width={200} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton></>
                        }
                    </>;
                }
            },
            {
                Header: 'Status',
                accessor: 'quote_status',
                filterable: true,
                Cell: (cellProps) => {
                    return <>
                        {!isLoading && (cellProps.row.original.quote_status) && <>
                            <Badge className={` ${cellProps.row.original.quote_status === 'draft' ? 'draft-status' : cellProps.row.original.quote_status === 'sent' ? 'sent-status' : cellProps.row.original.quote_status === 'read' ? 'read-status' : cellProps.row.original.quote_status === 'approved' ? 'approved-status' : cellProps.row.original.quote_status === 'declined' ? 'declined-status' : ''}   me-1 quote-date-div status-badge text-dark w-100`}>
                                {cellProps.row.original.quote_status.toUpperCase()}
                            </Badge>
                        </>
                        }
                        {isLoading && <>
                            <Skeleton className={`mb-1 ${isMediumDevice ? 'company-name-email' : ''} `} height={20} width={200} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton>
                        </>
                        }
                    </>;
                }
            },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    return <div className="d-flex align-items-center justify-content-center">
                        {!isLoading &&
                            <>
                                <div className="invoice-notes-container ps-1 me-2">
                                    {
                                        cellProps.row.original?.job_notes !== null && cellProps.row.original?.job_notes !== "" && cellProps.row.original?.job_notes !== '<p><br></p>' && (
                                            <span>
                                                <i className="bx bx-file-blank notes-icon position-relative cursor-pointer  quote-note-info" id={`job_notes${cellProps.row.original.quote_uuid}`} onClick={(e) => { e.stopPropagation(); openNoteModel(e, cellProps.row.original) }}></i>
                                                <UncontrolledTooltip placement="bottom" target={`job_notes${cellProps.row.original.quote_uuid}`}>
                                                    Job notes
                                                </UncontrolledTooltip>
                                            </span>
                                        )
                                    }
                                </div>
                                <UncontrolledDropdown className="action-dropdown">
                                    <DropdownToggle tag="a" className="card-drop" onClick={(e) => handleDropdownToggleClick(e)}>
                                        <i className="mdi mdi-dots-horizontal font-size-18 action-icon"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem
                                            onClick={(e) => {
                                                const quoteData = cellProps.row.original;
                                                previewQuote(e, quoteData)
                                            }} className="d-flex align-items-center"
                                        >
                                            <i className="dripicons-preview font-size-16 me-2 dropdown-menu-icon" id="previewtooltip"></i>
                                            <span className="dropdown-menu-text">Preview</span>
                                        </DropdownItem>
                                        {(cellProps.row.original.isExpired !== true && cellProps.row.original.client_name && cellProps.row.original.client_email) && <DropdownItem
                                            onClick={(e) => {
                                                const quoteData = cellProps.row.original;
                                                onClickAction(e, quoteData, true, 'send');
                                            }} className="d-flex align-items-center">
                                            <i className="bx bx-send font-size-16 me-2 dropdown-menu-icon" id="sendtooltip"></i>
                                            <span className="dropdown-menu-text">Send</span>
                                        </DropdownItem>}
                                        <DropdownItem
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                const quoteData = cellProps.row.original;
                                                getQuoteDetails(quoteData);
                                            }} className="d-flex align-items-center"
                                        >
                                            <i className="dripicons-duplicate font-size-16 me-2 dropdown-menu-icon" id="previewtooltip"></i>
                                            <span className="dropdown-menu-text">Duplicate</span>
                                        </DropdownItem>
                                        {(cellProps.row.original.client_name && cellProps.row.original.client_email) && <DropdownItem
                                            onClick={(e) => {
                                                const quoteData = cellProps.row.original;
                                                onClickAction(e, quoteData, false, 'approved');
                                            }} className="d-flex align-items-center">
                                            <i className={`font-size-16 me-2 dropdown-menu-icon ${cellProps.row.original.quote_status !== 'approved' ? 'far fa-thumbs-up' : 'far fa-thumbs-down'}`} id="deletetooltip"></i>
                                            <span className="dropdown-menu-text">{cellProps.row.original.quote_status !== 'approved' ? 'Mark as approved' : 'Mark as unapproved'}</span>
                                        </DropdownItem>}
                                        <DropdownItem
                                            onClick={(e) => {
                                                const quoteData = cellProps.row.original;
                                                onClickDownload(e, quoteData);
                                            }} className="d-flex align-items-center">
                                            <i className="bx bx-download font-size-16 me-2 dropdown-menu-icon" id="downloadtooltip"></i>
                                            <span className="dropdown-menu-text">Download</span>
                                        </DropdownItem>
                                        {
                                            (cellProps.row.original.quote_status === 'approved' && cellProps.row.original.invoice_uuid === null) && (
                                                <DropdownItem
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        navigate('/preview-invoice', { state: { quote_uuid: cellProps.row.original.quote_uuid, mode: 'create-from-list' } });
                                                    }}
                                                    className="d-flex align-items-center">
                                                    <i className="mdi mdi-plus  font-size-16 me-2 dropdown-menu-icon" id="downloadtooltip"></i>
                                                    <span className="dropdown-menu-text">Create Invoice</span>
                                                </DropdownItem>
                                            )
                                        }
                                        {cellProps.row.original.quote_status !== 'approved' && <DropdownItem
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                navigate('/quote-calculator', { state: { iseditMode: true, quote_uuid: cellProps.row.original.quote_uuid, showMiddleware: false } });
                                            }} className="d-flex align-items-center">
                                            <i className="mdi mdi-pencil font-size-16 me-2 text-success dropdown-menu-icon" id="edittooltip"></i>
                                            <span className="dropdown-menu-text">Edit</span>
                                        </DropdownItem>}
                                        <DropdownItem
                                            onClick={(e) => {
                                                const quoteData = cellProps.row.original;
                                                onClickAction(e, quoteData, false, 'delete');
                                            }} className="d-flex align-items-center">
                                            <i className="mdi mdi-trash-can font-size-16 me-2 text-danger dropdown-menu-icon" id="deletetooltip"></i>
                                            <span className="dropdown-menu-text">Delete</span>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </>
                        }
                        {isLoading && <Skeleton className="mb-1" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" width={20}></Skeleton>}
                    </div>
                }
            },
        ],
        [isLoading, isExtraSmallDevice, isExtraExtraSmallDevice, isMediumDevice]
    );

    // on search
    const onSearchHandler = (search) => {
        getQuoteListInfo(search);
    }

    // get quote list
    const getQuoteListInfo = (searchedText) => {
        setLoading(true);
        quoteService.getQuoteList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid, searchedText).then((response) => {
            if (response.status === 200) {
                // add is expired in list
                response.data.forEach(obj => {
                    obj.isExpired = moment(obj.quote_expires_at).isBefore(new Date());
                });
                setAllQuoteList(response.data);
                setLoading(false);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.errors[0]?.message, { autoClose: 4000 });
            setLoading(false);
        });
    }

    const handleDropdownToggleClick = (event) => {
        // Prevent the event from propagating to the table row
        event.stopPropagation();
    };

    // for preview qute
    const previewQuote = (event, data) => {
        event.stopPropagation();
        // navigate(`/preview-quote/${data.quote_uuid}`);
        navigate(`/preview-quote`, { state: { mode: 'previewQuote', quote_uuid: data.quote_uuid } });
    }

    // for delete quote
    const onClickAction = (event, data, isSend, action) => {
        event.stopPropagation();
        setAfterAction(false);
        setDeleteModal(true);
        setQuoteId(data.quote_uuid);
        setIsSendQuote(isSend);
        setActionQuote(action);
        setQuote(data);
    }

    // Utility function to convert HTML string to PDF and download it
    const downloadPdf = async (htmlString, file_name) => {
        // Create a temporary container to hold the HTML content
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
         
        document.body.appendChild(tempDiv);

        // Find all images in the HTML and convert them to base64
        const images = tempDiv.querySelectorAll('img');
        const imagePromises = Array.from(images).map(async (img) => {
            const url = img.src;
            if (url.startsWith('http')) {
                const base64 = await ImageURLTOBase64Convertor.convertImageUrlToBase64(url);
                img.src = base64;
            }
        });

        // Wait for all images to be converted
        await Promise.all(imagePromises);

        // Set up options for html2pdf
        const opt = {
            margin: [0.2, 0.1, 0.2, 0.1],
            filename: file_name,
            html2canvas: {
                scale: 2,
            },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
            enableLinks: true
        };

        // Convert the HTML to PDF
        try {
            await html2pdf().from(tempDiv).set(opt).save();
        } catch (error) {
            console.error("PDF generation error:", error);
        } finally {
            document.body.removeChild(tempDiv);
        }
    };

    // for download quote
    const onClickDownload = (event, selectedQuote) => {
        event.stopPropagation();
        setIsDownloadLoading(true);
        const quote = {
            "quote_uuid": selectedQuote.quote_uuid
        }
        quoteService.downloadQuote(quote).then(async (response) => {
            if (response.status === 200) {
                setTimeout(async () => {
                    await downloadPdf(response.data , `quote_${selectedQuote.job_name}_${selectedQuote.quote_number}.pdf`);
                    setIsDownloadLoading(false);
                }, 2000);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.errors[0]?.message, { autoClose: 4000 });
            setIsDownloadLoading(false);
        });
    }

    const openNoteModel = (event, data) => {
        event.stopPropagation();
        setIsNoteModel(true);
        setJobNotes(data.job_notes);
    }

    // delete quote api call 
    const isDeleteHandle = () => {
        quoteService.deleteQuote(quoteId).then((response) => {
            if (response.status === 200) {
                getQuoteListInfo();
            }
        }).finally(() => {
            setAfterAction(true);
        });
    }
    // send quote api call
    const isSendHandle = () => {
        const sendQuoteId = {
            "quote_uuid": quote.quote_uuid
        }
        quoteService.sendQuote(sendQuoteId).then((response) => {
            if (response.status === 200) {
                getQuoteListInfo();
                setIsSendQuote(false);
            }
        }).finally(() => {
            setAfterAction(true);
        });
    }
    // mark as approved quote api call 
    const isApprovedHandle = () => {
        const data = {
            "quote_uuid": quote.quote_uuid,
            "quote_status": quote.quote_status !== 'approved' ? 'approved' : 'unapproved'
        }
        quoteService.Change_quote_status(data).then((response) => {
            if (response.status === 200) {
                getQuoteListInfo();
            }
        }).finally(() => {
            setAfterAction(true);
        });
    }

    // for get quote details
    const getQuoteDetails = (quote) => {
        setIsDownloadLoading(true);
        quoteService.getQuoteDetailsByQuoteId(quote.quote_uuid).then((response) => {
            if (response.status === 200) {
                saveQuote(response.data);
            }
        });
    }

    // for convert image url to base64
    const fetchImageAsBase64 = async (url) => {
        try {
            const mimeType = 'image/jpeg';
            const response = await fetch(url);
            const arrayBuffer = await response.arrayBuffer();
            const base64String = btoa(
                new Uint8Array(arrayBuffer)
                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            return `data:${mimeType};base64,${base64String}`;
        } catch (error) {
            console.error("Error fetching image:", error);
            return null;
        }
    }

    // for duplicate quote
    const saveQuote = async (quoteDetails) => {
        try {
            let quoteItems = quoteDetails.quote_items;

            // Map over quoteItems and create an array of promises
            let imagePromises = quoteItems.map(async obj => {
                if (obj.quote_item_details.item_image_url) {
                    // Fetch base64 string and assign it to obj
                    obj.quote_item_details.item_image_base64 = await fetchImageAsBase64(AWS_S3_URL + obj.quote_item_details.item_image_url);
                }
                return obj;
            });

            // Wait for all promises to resolve
            quoteItems = await Promise.all(imagePromises);
            let saveQuoteItems = quoteItems.map(obj => {
                let updated_garments = obj.quote_item_details.quote_item_garments_details.map(val => {
                    const { quote_item_garments_uuid, ...newObject } = val;
                    return newObject;
                });
                let updated_markup = obj.quote_item_details.quote_item_markup_details.map(val => {
                    const { quote_item_markup_details_uuid, ...newObject } = val;
                    return newObject;
                });
                let updated_setup = obj.quote_item_details.quote_item_setup_details.map(val => {
                    const { quote_item_setup_uuid, ...newObject } = val;
                    return newObject;
                });
                let updated_stitch_count = obj.quote_item_details.quote_item_stitch_counts_details.map(val => {
                    const { quote_item_stitch_count_uuid, ...newObject } = val;
                    return newObject;
                });
                let updated_technical_specification = obj.quote_item_details.quote_item_technical_specification_details.map(val => {
                    const { quote_item_technical_specification_uuid, ...newObject } = val;
                    return newObject;
                });

                return {
                    quote_item_details: {
                        cost_per_item: obj.quote_item_details.cost_per_item,
                        gross_cost: obj.quote_item_details.gross_cost,
                        gross_hourly_rate: obj.quote_item_details.gross_hourly_rate,
                        gross_sale: obj.quote_item_details.gross_sale,
                        item_description: obj.quote_item_details.item_description,
                        item_name: obj.quote_item_details.item_name,
                        item_image_base64: obj.quote_item_details.item_image_base64,
                        item_quantity: obj.quote_item_details.item_quantity,
                        net_hourly_rate: obj.quote_item_details.net_hourly_rate,
                        net_profit: obj.quote_item_details.net_profit,
                        quoted_sales_percentage: obj.quote_item_details.quoted_sales_percentage,
                        quoted_sales_price: obj.quote_item_details.quoted_sales_price,
                    },
                    quote_item_garments_details: updated_garments,
                    quote_item_markup_details: updated_markup,
                    quote_item_setup_details: updated_setup,
                    quote_item_stitch_counts_details: updated_stitch_count,
                    quote_item_tax_details: obj.quote_item_details.quote_item_tax_details === null ? [] : obj.quote_item_details.quote_item_tax_details.map(({ quote_item_tax_mapping_uuid, ...rest }) => rest),
                    quote_item_technical_specification_details: updated_technical_specification,
                };
            });
            setIsDownloadLoading(true);
            let saveQuoteReq = {
                "user_uuid": JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid,
                "quote_details": {
                    "job_name": quoteDetails?.job_name,
                    "job_notes": quoteDetails?.job_notes,
                    "quote_expires_at": new Date(quoteDetails?.quote_expires_at)
                },
                "quote_items": saveQuoteItems
            }
            quoteCalculatorService.saveQuote(saveQuoteReq).then((response) => {
                if (response.status === 200) {
                    setIsDownloadLoading(false);
                    navigate('/preview-quote', { state: { mode: 'editMode', quote_uuid: response.data.quote_uuid } });
                }
            }).finally(() => {
                setIsDownloadLoading(false);
            });
        } catch (error) {
            console.error("Error saving quote:", error);
        }
    }

    return (
        <React.Fragment>
            {/* delete quote modal */}
            <ConfirmationModal
                show={deleteModal}
                onCloseClick={() => setDeleteModal(false)}
                title={actionQuote === 'send' ? 'Send quote' : actionQuote === 'delete' ? 'Delete quote' : actionQuote === 'approved' && quote.quote_status !== 'approved' ? 'Mark as approved' : actionQuote === 'approved' && quote.quote_status === 'approved' ? 'Mark as unapproved' : ''}
                description={actionQuote === 'send' ? '' : actionQuote === 'delete' ? 'Are you sure you want to delete this quote?' : actionQuote === 'approved' && quote.quote_status !== 'approved' ? 'Are you sure you want to mark this quote as approved?' : actionQuote === 'approved' && quote.quote_status === 'approved' ? 'Are you sure you want to mark this quote as unapproved?' : ''}
                onAction={() => actionQuote === 'send' ? isSendHandle() : actionQuote === 'delete' ? isDeleteHandle() : actionQuote === 'approved' ? isApprovedHandle() : ''}
                isQuoteDelete={true}
                afterAction={afterAction}
                isSendQuote={isSendQuote}
                actionName={actionQuote === 'send' ? 'Send' : actionQuote === 'delete' ? 'Delete' : actionQuote === 'approved' && quote.quote_status !== 'approved' ? 'Approve' : actionQuote === 'approved' && quote.quote_status === 'approved' ? 'Unapprove' : ''}
                quote={quote}
            />
            {/* quote list table */}
            <div className="page-content quote-list-page pb-0">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb breadcrumbItem="Quotes" buttonName="Create Quote" handleCreate={() => { navigate('/quote-calculator'); }} />
                    <Row>
                        <Col xs="12" className="quote-list">
                            <Card className="position-relative mb-0 list-card">
                                {<CardBody>
                                    {/* list table */}
                                    {<TableContainer className='w-100'
                                        columns={columns}
                                        data={quoteList.length < 6 && isLoading ? [0, 1, 2, 3] : quoteList}
                                        isPagination={false}
                                        isAddOptions={false}
                                        searchPlaceholder={'Search by quote id, job name, client name, client company name & email'}
                                        tableClass="align-middle table-nowrap"
                                        onSearch={onSearchHandler}
                                        selectedValue={onSelectedExpirationStatus}
                                        selectedClientValue={onSelectedClient}
                                        selectedStatusValue={onSelectedStatus}
                                        isLoading={isLoading}
                                        isQuoteList={true}
                                        customerQuoteClientList={customerQuoteClientList}
                                        listName={'quote-list'}
                                    />}
                                </CardBody>}
                                {isDownloadLoading && <Spinner className='me-2 position-absolute download-quote-spinner' />}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal className='delete-customer-modal' size="md" isOpen={isNoteModel} centered={true}>
                <ModalHeader toggle={() => setIsNoteModel(false)} tag="h4">
                    Job notes
                </ModalHeader>
                <ModalBody>
                    {
                        jobNotes && (
                            <div dangerouslySetInnerHTML={{ __html: jobNotes.toString('html') }}></div>
                        )
                    }
                </ModalBody>
            </Modal>
            {/* toast message */}
            <ToastContainer />
        </React.Fragment>
    );
}

export default QuoteList;