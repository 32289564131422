import { ApiService } from '../../shared/services/api.service';
import { Quote, SHOP_TAX } from "../../shared/services/api.config";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export class QuoteService {
    apiService = new ApiService();

    // get customer list
    async getQuoteList(userId, serchText) {
        return this.apiService.get(`${Quote.QUOTE}/${userId}/quote-list`, { 'searchedText': serchText }).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    // get customer quote client list
    async getCustomerQuoteClientList(userId, serchText) {
        return this.apiService.get(`${Quote.QUOTE}/${userId}/customer-quote-client-list`, { 'searchedText': serchText }).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    // get quote details by quote id
    async getQuoteDetailsByQuoteId(quoteId) {
        return this.apiService.get(`${Quote.QUOTE}/${quoteId}/get-quote-details`).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    // delete quote
    async deleteQuote(quoteId) {
        return this.apiService.delete(`${Quote.QUOTE}/${quoteId}`).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    // download quote
    async downloadQuote(quote) {
        return this.apiService.post(`${Quote.QUOTE}/download-quote`, quote).then(response => {
            if (response.status === 200) {
                return response;
            } else {
                return response;
            }
        }).catch(error => {
            return error;
        });
    }

    // send quote
    async sendQuote(quote) {
        return this.apiService.post(`${Quote.QUOTE}/send-quote`, quote).then(response => {
            if (response.status === 200) {
                toast.success(response.data.message, { autoClose: 4000 });
                return response;
            }
            return response;
        }).catch(error => {
            toast.error(error.response.data.errors[0].message, { autoClose: 4000 });
            return error;
        });
    }

    // for edit quote in preview mode
    async editQuote(quote_uuid, updatedQuoteInfo) {
        return this.apiService.put(Quote.EDIT_QUOTE.replace(/{quote_uuid}/, quote_uuid), updatedQuoteInfo).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response
        }).catch(error => {
            return error;
        });
    }

    // for get sent quote's client identity info by quote id
    async getIdentityInfo(userId) {
        return this.apiService.get(`${Quote.Get_sent_quote_client_identity_info_by_Quote_ID}/${userId}`).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async submitClientIdentity(data) {
        return this.apiService.post(`${Quote.Submit_sent_quote_client_identity_info}`, data).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async addTaxInfo(taxInfo) {
        return this.apiService.post(SHOP_TAX.ADD_TAX, taxInfo).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async getTaxInfo(customer_shop_info_uuid) {
        return this.apiService.get(SHOP_TAX.TAX_LIST.replace(/{customer_shop_info_uuid}/, customer_shop_info_uuid)).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async editTaxInfo(EditedTaxInfo) {
        return this.apiService.put(SHOP_TAX.EDIT_TAX, EditedTaxInfo).then(response => {
            if (response.data === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async deleteTaxInfo(customer_shop_tax_master_uuid) {
        return this.apiService.delete(SHOP_TAX.DELETE_TAX.replace(/{customer_shop_tax_master_uuid}/, customer_shop_tax_master_uuid)).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async Change_quote_status(data) {
        return this.apiService.post(Quote.Change_quote_status, data).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }
}