export class CustomValidator{
    static passwordValidation = async (password) => {
        if (password && password.length >= 8) {
            const regexes = ['[A-Z]', '[a-z]', '[0-9]', '[^a-zA-Z0-9]'];
            let matchCount = 0;
            regexes.forEach((exp) => {
                if (password.match(exp)) {
                    matchCount = matchCount + 1;
                }
            });
            if (matchCount >= 3) {
                return {
                    isValid: true,
                };
            } else {
                return {
                    isValid: false,
                    errorMessage:
                      'Please enter valid password',
                };
            }
        }
    }
}