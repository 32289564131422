import React, { useState } from "react";
import { Row, Col, CardBody, Card, Container, Input, Label, Form, FormFeedback } from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
// navigation
import { useNavigate } from "react-router-dom";
// style
import './resetPassword.scss';
// validation error
import { registerValidationErr, resetPswValidationErr } from '../../../shared/static/constants/constant';
// auth service
import { AuthenticationService } from '../../service/authentication.service';
// toast message
import { ToastContainer } from "react-toastify";
// loader
import Spinners from '../../../shared/components/common/spinner';
import {CustomValidator} from '../../../shared/helpers/customValidators'

const ResetPassword = props => {
    // loader
    const [isLoading, setLoading] = useState(false);
    // toggle eye icon
    const [visibleNewPassword, setVisibleNewPassword] = useState(false);
    const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);
    // auth service
    const authenticationService = new AuthenticationService();
    // navigation
    const navigate = useNavigate();
    // form
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            verificationCode: '',
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object({
            verificationCode: Yup.string().required(resetPswValidationErr.ERR_VERIFICATION_CODE_REQUIRED)
                .length(6, resetPswValidationErr.ERR_VERIFICATION_CODE_INVALID),
            newPassword: Yup.string()
                .required(resetPswValidationErr.ERR_PASSWORD_REQUIRED)
                .min(8, resetPswValidationErr.ERR_PASSWORD_INVALID)
                .test(
                    'validator-custom-password',
                    async function (value) {
                        const validation = await CustomValidator.passwordValidation(value);;
                        if (!validation?.isValid) {
                            return this.createError({
                                path: this.path,
                                message: validation?.errorMessage,
                            });
                        } else {
                            return true;
                        }
                    }
                ),
            confirmPassword: Yup.string().required(resetPswValidationErr.ERR_CONFIRM_PASSWORD_REQUIRED)
                .oneOf([Yup.ref('newPassword'), null], resetPswValidationErr.ERR_CONFIRM_PASSWORD_INVALID)
        }),
        // submit form
        onSubmit: (values) => {
            resetPassword(values);
        }
    });

    // call reset password api API 
    const resetPassword = (values) => {
        setLoading(true);
        authenticationService.resetPassword({
            email: JSON.parse(localStorage.getItem('user_email') || ''),
            verification_code: values.verificationCode.toString(),
            password: values.newPassword
        }).then(response => {
            if (response.status === 200) {
                navigate("/login");
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <React.Fragment>
            <div className="reset-password-page">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={6}>
                            <Card className="overflow-hidden mb-0 py-5 px-3 px-lg-5">
                                {/* title */}
                                <div className="bg-white">
                                    <Row className="justify-content-center">
                                        <Col className="col-5 my-auto text-center col">
                                            <img src="/assets/images/Print_Shop_Master_svg_logo.svg" alt="logo" className="img-fluid logo-img" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="p-0">
                                    <div className="header-text">
                                        <h3 className="header-text text-center my-4">Reset Password</h3>
                                    </div>
                                    <div className="p-0">
                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            {/* verification code */}
                                            <div className="mb-3">
                                                <Label className="form-label">Verification code <span className='text-danger'>*</span> <i className="bx bx-info-circle info-icon position-relative" id="verificationCodeInfo">
                                                    <small className='info_tooltip' id="verificationCodeTooltip">Enter the verification code you have received in the email.</small>
                                                </i></Label>
                                                <Input
                                                    id="verificationCode"
                                                    name="verificationCode"
                                                    autoComplete='off'
                                                    className="form-control"
                                                    placeholder="Enter verification code"
                                                    type="number"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.verificationCode || ""}
                                                    invalid={
                                                        validation.touched.verificationCode && validation.errors.verificationCode ? true : false
                                                    }
                                                />
                                                {validation.touched.verificationCode && validation.errors.verificationCode ? (
                                                    <FormFeedback type="invalid">{validation.errors.verificationCode}</FormFeedback>
                                                ) : null}
                                            </div>
                                            {/* new password */}
                                            <div className="mb-3 position-relative">
                                                <Label className="form-label">New Password <span className='text-danger'>*</span> <i className="bx bx-info-circle info-icon position-relative" id="newPasswordInfo">
                                                    <div className='info_tooltip'>
                                                        <ul className='mt-2 me-3'>
                                                            <li className="mb-2">Your password length must be greater than or equal to 8</li>
                                                            <li className="mb-2">Any three among below four conditions should match,</li>
                                                            <ul>
                                                                <li className="mb-2">Must contain at least 1 uppercase character</li>
                                                                <li className="mb-2">Must contain at least 1 lowercase character</li>
                                                                <li className="mb-2">Must contain at least 1 numeric value</li>
                                                                <li className="mb-2">Must contain at least 1 special character</li>
                                                            </ul>
                                                        </ul>
                                                    </div></i></Label>
                                                <Input
                                                    id="newPassword"
                                                    name="newPassword"
                                                    className="form-control"
                                                    placeholder="Enter password"
                                                    type={visibleNewPassword ? 'text' : 'password'}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.newPassword || ""}
                                                    invalid={
                                                        validation.touched.newPassword && validation.errors.newPassword ? true : false
                                                    }
                                                />
                                                {validation.values.newPassword.length > 0 && (<i className={`bi login-eye ${!(validation.touched.newPassword && validation.errors.newPassword) ? 'right-5px' : ''}  ${visibleNewPassword ? 'bi-eye' : 'bi-eye-slash'}`} onClick={() => { setVisibleNewPassword(!visibleNewPassword) }}></i>)}
                                                {validation.touched.newPassword && validation.errors.newPassword ? (
                                                    <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                                                ) : null}
                                            </div>
                                            {/* confirm password */}
                                            <div className="mb-3 position-relative">
                                                <Label className="form-label">Confirm Password <span className='text-danger'>*</span></Label>
                                                <Input
                                                    name="confirmPassword"
                                                    type={visibleConfirmPassword ? 'text' : 'password'}
                                                    placeholder="Enter Password"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.confirmPassword || ""}
                                                    invalid={
                                                        validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                                                    }
                                                />
                                                {validation.values.confirmPassword?.length > 0 && (<i className={`bi login-eye ${!(validation.touched.confirmPassword && validation.errors.confirmPassword) ? 'right-5px' : ''}  ${visibleConfirmPassword ? 'bi-eye' : 'bi-eye-slash'}`} onClick={() => { setVisibleConfirmPassword(!visibleConfirmPassword) }}></i>)}
                                                {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                                                    <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                                                ) : null}
                                            </div>
                                            {/* reset button */}
                                            <div className="pt-3 text-end">
                                                <button
                                                    className="btn btn-fill btn-block"
                                                    type="submit"
                                                    disabled={!validation?.isValid || !validation.dirty}
                                                >
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        {/* loader */}
                                                        {isLoading ? <Spinners className="" setLoading={setLoading} /> : ''}
                                                        Reset
                                                    </div>
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* toast message */}
            <ToastContainer />
        </React.Fragment>
    );
};

export default ResetPassword;
