import { Modal, ModalBody } from "reactstrap";
import './subscriptionModal.scss';
import { PRINTSHOP_SUPPORT_EMAIL } from "../../static/constants/constant";

const SubscriptionPassModal = ({ show, onCloseClick, description, actionName }) => {

    return (
        <Modal className='subscription-modal' size="md" isOpen={show} centered={true}>
            <div className="modal-content border-0">
                <ModalBody>
                    <div className="text-center">
                        <div className="avatar-md mx-auto  mt-3 mb-4">
                            <div className=" icon-sub-container rounded-circle h1">
                                <i className="mdi mdi-calendar-check text-success calendor-icon" />
                            </div>
                        </div>
                        <p className="text-success font-size-20">Subscribed</p>
                    </div>
                    <p className="text-muted mb-2 font-size-16">{description} <a href={`mailto:${PRINTSHOP_SUPPORT_EMAIL}`} className="email-anchor">{PRINTSHOP_SUPPORT_EMAIL}</a>.</p>
                    {/* footer */}
                    <div className="hstack justify-content-end mb-0">
                        <button type="button" onClick={onCloseClick} className={`btn btn-fill  d-flex`} >
                            {actionName}
                        </button>
                    </div>
                </ModalBody>
            </div>
        </Modal>
    )
}

export default SubscriptionPassModal;