import { SHOP_OWNER_PAYMENT } from "../../shared/services/api.config";
import { ApiService } from "../../shared/services/api.service";

export class ShopOwnerGetPaid {
    apiService = new ApiService();

    async generateLink(accountDetail) {
        return this.apiService.post(SHOP_OWNER_PAYMENT.GENERATE_STRIPE_CONNECT_ACCOUNT_ONBOARDING_LINK, accountDetail).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async verifyStripeAccountStatus(user_uuid) {
        return this.apiService.get(SHOP_OWNER_PAYMENT.VERIFY_STRIPE_ACCOUNT_ONBOARDING.replace(/{user_uuid}/, user_uuid)).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async unlinkAccount(user_uuid){
        return this.apiService.delete(SHOP_OWNER_PAYMENT.UNLINK_ACCOUNT.replace(/{user_uuid}/, user_uuid)).then(response => {
            if(response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async updateAccount(accountDetails){
        return this.apiService.post(SHOP_OWNER_PAYMENT.UPDATE_BANK_ACCOUNT, accountDetails).then(response => {
            if( response.status === 200 ){
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }
}