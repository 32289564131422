import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from 'moment';
// Import menuDropdown
import ProfileMenu from "../shared/components/common/profileMenu";
// Redux Store
import { toggleLeftmenu } from "../../store/actions";
import { Role } from '../authentication/model/authentication.model';
import { UncontrolledTooltip } from "reactstrap";
import './header.scss';
import { CustomerService } from '../customers/service/customer.service';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { authenticatedUserInfo } from '../../atoms';
import { SUBSCRIPTION_PLAN_UUID } from '../shared/static/constants/constant';

const Header = props => {
  const customerService = new CustomerService();
  const navigate = useNavigate()
  const [loginUserInfo, setLoginUserInfo] = useState(JSON.parse(sessionStorage.getItem('userLogin'))?.data);
  const [sessiondata, setsessiondata] = useAtom(authenticatedUserInfo)

  useEffect(() => {
    setLoginUserInfo(JSON.parse(sessionStorage.getItem('userLogin'))?.data)
  }, [JSON.parse(sessionStorage.getItem('userLogin'))?.data?.subscription_details]);

  useEffect(() => {
    if (loginUserInfo.role_name === Role.Customer) {
      customerService.getSubscriptionplanDetailByUserId(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
        if (response.status === 200) {
          let userLogin = JSON.parse(sessionStorage.getItem('userLogin'));
          let subscriptionApiDetail = response.data;
          // here if session storage has info that it should refresh or when user has logged in that time subscription was not craeted but admin has updated subscription that time or after editting subscription it should update the session storage and navigate accordingly
          if (JSON.parse(sessionStorage.getItem('shouldRefresh')) || (userLogin.data?.subscription_details?.subscription_plan_details === null && subscriptionApiDetail?.subscription_plan_details !== null) || (userLogin.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid !== subscriptionApiDetail?.subscription_plan_details?.subscription_plan_uuid) || (userLogin.data?.subscription_details?.subscription_plan_details?.start_date !== subscriptionApiDetail?.subscription_plan_details?.start_date) || (userLogin.data?.subscription_details?.subscription_plan_details?.end_date !== subscriptionApiDetail?.subscription_plan_details?.end_date)) {
            userLogin.data.subscription_details = subscriptionApiDetail;
            sessionStorage.setItem('userLogin', JSON.stringify(userLogin));
            userLogin.data.subscription_details.subscription_plan_details.subscription_plan_uuid ===  SUBSCRIPTION_PLAN_UUID.STANDARD_PLAN ? navigate("/dashboard") : navigate('/quote-calculator');
            setsessiondata(userLogin);
            sessionStorage.setItem('shouldRefresh', JSON.stringify(false));
          }
        }
      });
    }
  }, []);

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  const UTCtoLocal = (utcDate) => {
    const date = new Date(utcDate);
    const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    const options = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Dynamic time zone based on user's location
    };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(localDate);
    return formattedDate;
  }

  return (
    <React.Fragment>
      <header id="page-topbar" className='header-container'>
        <div className="navbar-header">
          <div className="d-flex">
            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex align-items-center ">
            {
              loginUserInfo.role_name === Role.Customer && (
                <div className=''>
                  {
                    loginUserInfo?.subscription_details.subscription_plan_details?.start_date && loginUserInfo?.subscription_details.subscription_plan_details.start_date !== null ? (
                      moment(UTCtoLocal(loginUserInfo?.subscription_details.subscription_plan_details.end_date)).endOf('date').diff(moment(), 'days') <= 15 && (
                        <span id="time-subscription-left" className="d-flex plan-container cursor-pointer remaining-days-countdown justify-content-end align-items-center">
                          <i className="mdi me-1 font-size-20 cursor-pointer mdi-clock-alert-outline"></i>
                          <UncontrolledTooltip style={{ backgroundColor: 'white', boxShadow: ' rgba(0, 0, 0, 0.2) 0px 18px 50px -10px', width: '130px', opacity: '1', marginTop: '10px' }} target={'time-subscription-left'}>
                            <div className='mb-2 d-flex align-items-center justify-content-between'>
                              <p className="text-start text-dark mb-0">{loginUserInfo.subscription_details.subscription_plan_details.plan_name}  </p>
                              <i className="mdi mdi-timer font-size-20 remaining-days-countdown mb-0"></i>
                            </div>
                            <p className='text-start mb-0 text-dark fw-bold font-size-12'>Expiry date</p>
                            <p className='text-start text-dark mb-0'> {moment(UTCtoLocal( loginUserInfo?.subscription_details.subscription_plan_details.end_date)).format('MMMM DD, YYYY')}</p>
                          </UncontrolledTooltip>
                          <span> {moment(UTCtoLocal( loginUserInfo?.subscription_details.subscription_plan_details.end_date)).endOf('date').fromNow(true)}</span>
                        </span>
                      )
                    ) : (
                      <span id="time-left-for-trial" className="d-flex cursor-pointer justify-content-end align-items-center plan-container">
                        <i className="mdi me-1 font-size-20 cursor-pointer remaining-days-countdown mdi-clock-alert-outline"></i>
                        <UncontrolledTooltip className={'customtooltip'} fade={false} style={{ backgroundColor: 'white', boxShadow: ' rgba(0, 0, 0, 0.2) 0px 18px 50px -10px', width: '135px', opacity: '1', marginTop: '10px' }} target={'time-left-for-trial'}>
                          <div className='d-flex mb-0 align-items-center justify-content-between'>
                            <p className='text-start text-dark mb-0'>Trial Period</p>
                            <i className="mdi mdi-timer font-size-20 remaining-days-countdown mb-0"></i>
                          </div>
                          <p className='text-start mb-0 text-dark fw-bold font-size-12'>Expiry Date</p>
                          <p className="text-start text-dark mb-0"><span className="">{moment(moment(UTCtoLocal(loginUserInfo?.created_at))).add(6, 'days').format('MMMM DD YYYY')}</span></p>
                        </UncontrolledTooltip>
                        <span className='remaining-days-countdown'>{moment.duration(moment(moment(UTCtoLocal(loginUserInfo?.created_at))).add(6, 'days').endOf('day').diff(moment().endOf('day').format('YYYY-MM-DD'))).humanize()}</span></span>
                    )
                  }
                </div>
              )
            }
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const {
    layoutType,
    leftMenu,
    leftSideBarType,
  } = state.Layout;
  return { layoutType, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  toggleLeftmenu,
})(Header);