import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
// Import Routes all
import { authProtectedRoutesAdmin, publicRoutes, routesForAllFeatures, routesForBasicPlan } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import VerticalLayout from "./module/layout";
import NonAuthLayout from "./module/shared/components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

import { useAtom } from 'jotai';
import { authenticatedUserInfo } from './atoms';
import { Role } from './module/authentication/model/authentication.model';
import { SUBSCRIPTION_PLAN_UUID } from './module/shared/static/constants/constant';

const App = () => {
  const [getUserInfo, setUserInfo] = useAtom(authenticatedUserInfo);
  useEffect(() => {
    sessionStorage.getItem('userLogin') ? setUserInfo(JSON.parse(sessionStorage.getItem('userLogin') || '')) : setUserInfo(null);
  }, [setUserInfo]);

  return (
    <React.Fragment>
      <Routes>
        {/* for customer role if user is on trial period or has Premium subscription */}
        {getUserInfo && getUserInfo.data.role_name === Role.Customer && (getUserInfo.data.subscription_details.subscription_plan_details === null || getUserInfo.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.STANDARD_PLAN) && routesForAllFeatures.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <VerticalLayout>{route.component}</VerticalLayout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}
        {/* for customer role and if user has starter subscription */}
        {getUserInfo && getUserInfo.data.role_name === Role.Customer && getUserInfo.data.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.BASIC_PALN && routesForBasicPlan.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <VerticalLayout>{route.component}</VerticalLayout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}
        {/* for admin role */}
        {getUserInfo && getUserInfo.data.role_name === Role.Admin && authProtectedRoutesAdmin.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <VerticalLayout>{route.component}</VerticalLayout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}
        {/* for authentication routes */}
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout role={[Role.Admin]}>
                {route.component}
              </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;