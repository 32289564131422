import React, { useEffect, useState, useMemo } from "react";
import withRouter from "../../../shared/components/common/withRouter";
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from "reactstrap";
import TableContainer from "../../../shared/components/common/tableContainer";
import './customerList.scss';
import '../create-customer/createCustomer.scss';
import ConfirmationModal from '../../../shared/components/common/confirmationModal';
import { CustomerService } from '../../service/customer.service';
import { AWS_S3_URL } from '../../../shared/static/constants/constant';
import Skeleton from 'react-loading-skeleton';
import CreateCustomer from '../create-customer/createCustomer';
import Breadcrumb from "../../../shared/components/common/breadcrumb";
import ImagePreviewModal from '../../../shared/components/common/imagePreviewModal';
import CreateSubscription from "../create-subscription/createSubscription";
import { ToastContainer, toast } from "react-toastify";

const CustomerList = () => {
  const [customerList, setCustomerList] = useState([]);
  // for modal data
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [customer, setCustomer] = useState(null);
  // loader
  const [isLoading, setLoading] = useState(false);
  // check device
  const [isMediumDevice, setIsMediumDevice] = useState(false);
  const [isExtraSmallDevice, setIsExtraSmallDevice] = useState(false);
  const [isExtraExtraSmallDevice, setIsExtraExtraSmallDevice] = useState(false);
  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);
  const [customerUuid, setCustomerUuid] = useState(null);
  const [user_subscription_plan_uuid, setuser_subscription_plan_uuid] = useState();
  const [isDeleted, setIsDeleted] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  // for subscription modal
  const [isSubscriptionModal, setisSubscriptionModal] = useState(false);
  const [isSubscriptionEdit, setIsSubscriptionEdit] = useState(false);

  useEffect(() => {
    function handleResize() {
      window.innerWidth <= 1024 ? setIsMediumDevice(true) : setIsMediumDevice(false);
      window.innerWidth <= 610 ? setIsExtraSmallDevice(true) : setIsExtraSmallDevice(false);
      window.innerWidth <= 281 ? setIsExtraExtraSmallDevice(true) : setIsExtraExtraSmallDevice(false);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // service
  const customerService = new CustomerService();

  useEffect(() => {
    // for get customer list
    getCustomerListInfo('');
  }, []);

  // on search
  const onSearchHandler = (search) => {
    getCustomerListInfo(search);
  }

  // get customer list
  const getCustomerListInfo = (searchedText) => {
    setLoading(true);
    customerService.getCustomerList(searchedText).then((response) => {
      const modifiedCustomerList = response.data.map((customer, index) => {
        let expirdSubscription = '';
        if (customer.user_subscription_plan_uuid) {
          const subscriptionEndDate = new Date(UTCtoLocal(customer.subscription_end_date));
          const currentDate = new Date();
          subscriptionEndDate.setHours(0, 0, 0, 0);
          currentDate.setHours(0, 0, 0, 0);
          expirdSubscription = subscriptionEndDate < currentDate;
        }

        let trialDate = '';
        if (customer.user_subscription_plan_uuid == null) {
          const createDate = new Date(UTCtoLocal(customer.created_at));
          const currentDate = new Date();
          // Subtract 6 days from the current date
          const dateSixDaysAgo = new Date();
          dateSixDaysAgo.setDate(currentDate.getDate() - 6);

          // Check if the createDate is greater than the dateSixDaysAgo
          trialDate = createDate > dateSixDaysAgo;
        }

        return {
          ...customer,
          subscription: customer.user_subscription_plan_uuid ? expirdSubscription ? 'Expired' : customer.plan_name : !trialDate ? 'Expired' : 'Trial'
        };
      });
      setCustomerList(modifiedCustomerList);
      setLoading(false);
    }).catch(error => {
      toast.error(error?.response?.data?.errors[0]?.message, { autoClose: 4000 });
      setLoading(false);
    });
  }

  const UTCtoLocal = (utcDate) => {
    const date = new Date(utcDate);
    const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    const options = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Dynamic time zone based on user's location
    };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(localDate);
    return formattedDate;
  }

  // edit customer data
  useEffect(() => {
    handleUpdateCustomer();
  }, [customer]);

  const handleUpdateCustomer = arg => {
    if (arg) {
      customerService.getCustomerDetail(arg.user_uuid).then(response => {
        setCustomer(
          response['data'][0],
        );
      }).finally(() => {
        setLoading(false);
      });
      setIsEdit(true);
      toggle();
    }
  };

  const handleClickOnSubscription = (data) => {
    toggleSubscriptionModal();
    setCustomerUuid(data.user_uuid);
    setIsSubscriptionEdit(data.user_subscription_plan_uuid !== null);
    setuser_subscription_plan_uuid(data.user_subscription_plan_uuid);
  }

  // Customber list Column
  const columns = useMemo(
    () => [
      {
        Header: ' ',
        accessor: 'profile_pic',
        Cell: (cellProps) => {
          return <div>
            {
              !isLoading && (cellProps.row.original.profile_pic == null || cellProps.row.original.profile_pic === 'null' || cellProps.row.original.profile_pic === 'undefined') ? <div className="avtar-x">
                <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                  {cellProps.row.original.name.split(" ")[1] ? cellProps.row.original.name.split(" ")[0].charAt(0) + '' + cellProps.row.original.name.split(" ")[1].charAt(0) : cellProps.row.original.name.split(" ")[0].slice(0, 2)}
                </div>
              </div> :
                !isLoading ?
                  <img
                    src={`${AWS_S3_URL + cellProps.row.original.profile_pic}`}
                    alt=""
                    className="avatar-sm rounded-circle img-thumbnail cursor-pointer"
                    onClick={() => setPreviewImage(AWS_S3_URL + cellProps.row.original.profile_pic)}
                  /> : ''}
            {isLoading && <div className="avtar-x">
              <Skeleton baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="h-100 bg-soft rounded-circle" />
            </div>}
          </div>
        }
      },
      {
        Header: 'Name',
        accessor: 'name',
        filterable: true,
        maxWidth: 400,
        minWidth: 140,
        width: 200,
        Cell: (cellProps) => {
          return <> {(!isLoading && cellProps.row.original.name) && <span
            className={`customer ${cellProps.row.original.name.length > (isExtraExtraSmallDevice ? 11 : (isExtraSmallDevice ? 11 : (isMediumDevice ? 11 : 23))) ? 'customer_ellipsis' : ''}`}
            data-toggle='tooltip' data-title={cellProps.row.original.name}>{cellProps.row.original.name}</span>}
            {isLoading &&
              <div><Skeleton className='mb-0' width={120} height={20} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton></div>
            }
          </>
        }
      },
      {
        Header: 'Email',
        accessor: 'email',
        filterable: true,
        Cell: (cellProps) => {
          return <>
            {!isLoading && <p className={`mb-0 customer-email-text ${cellProps.row.original.email.length > (isExtraExtraSmallDevice ? 13 : (isExtraSmallDevice ? 15 : (isMediumDevice ? 16 : 23))) ? 'customer_ellipsis' : ''}`} id="emailTooltip"
              data-toggle='tooltip' data-title={cellProps.row.original.email}>{cellProps.row.original.email}
            </p>}
            {isLoading && <Skeleton className='mb-0' width={160} height={20} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton>}
          </>;
        }
      },
      {
        Header: 'Shop name',
        accessor: 'shop_name',
        filterable: true,
        Cell: (cellProps) => {
          return <div>
            {!isLoading && cellProps.row.original.shop_name && <div className='d-flex'>
              <span className={`mb-0 customer-shop-name-text ${cellProps.row.original.shop_name.length > (isExtraExtraSmallDevice ? 13 : (isExtraSmallDevice ? 15 : (isMediumDevice ? 16 : 23))) ? 'customer_ellipsis' : ''}`}
                data-toggle='tooltip' data-title={cellProps.row.original.shop_name}>{cellProps.row.original.shop_name}
              </span>
              {/* shop info tooltip */}
              {cellProps.row.original.shop_address || cellProps.row.original.shop_city || cellProps.row.original.shop_state || cellProps.row.original.shop_country || cellProps.row.original.shop_zip_code ? <i className="bx bx-info-circle cursor-pointer text-yellow info-icon position-relative">
                <div className="text-start info_tooltip">
                  <p className="mb-1">Address: {cellProps.row.original.shop_address}</p>
                  <p className="mb-1">City: {cellProps.row.original.shop_city}</p>
                  <p className="mb-1">State: {cellProps.row.original.shop_state}</p>
                  <p className="mb-1">Country: {cellProps.row.original.shop_country}</p>
                  <p className="mb-1">Zip code: {cellProps.row.original.shop_zip_code}</p>
                </div>
              </i> : ''}
            </div>
            }
            {(!isLoading && !cellProps.row.original.shop_name) && <span> --- </span>}
            {isLoading &&
              <div><Skeleton className='mb-0' width={120} height={20} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton></div>
            }
          </div >;
        }
      },
      {
        Header: 'Shop phone & email',
        accessor: 'shop_contact_number',
        filterable: true,
        Cell: (cellProps) => {
          return <>
            {!isLoading && (cellProps.row.original.shop_contact_number || cellProps.row.original.shop_email) && <> <p className="mb-1">
              {cellProps.row.original.shop_contact_number.slice(0, 3) + "-" + cellProps.row.original.shop_contact_number.slice(3, 6) + '-' + cellProps.row.original.shop_contact_number.slice(6)}</p>
              <p className={`mb-0 customer-email-text ${cellProps.row.original.shop_email.length > (isExtraExtraSmallDevice ? 13 : (isExtraSmallDevice ? 15 : (isMediumDevice ? 16 : 23))) ? 'customer_ellipsis shop-email-tooltip' : ''}`} id="emailTooltip"
                data-toggle='tooltip' data-title={cellProps.row.original.shop_email}>{cellProps.row.original.shop_email}
              </p>
            </>
            }
            {!isLoading && !(cellProps.row.original.shop_contact_number && cellProps.row.original.shop_email) && <> <div> --- </div></>
            }
            {isLoading && <> <Skeleton className="mb-1" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton>
              <Skeleton className="mb-0" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton></>
            }
          </>;
        }
      },
      {
        Header: 'Subscription',
        accessor: 'subscription',
        filterable: true,
        Cell: (cellProps) => {
          return <>
            {!isLoading && cellProps.row.original.subscription && <Badge className={` ${cellProps.row.original.subscription === 'Trial' ? 'draft-status' : cellProps.row.original.subscription === 'Starter' ? 'sent-status' : cellProps.row.original.subscription === 'Expired' ? 'declined-status' : cellProps.row.original.subscription === "Premium" ? 'approved-status' : ''}   me-1 quote-date-div status-badge text-dark w-100`}>
              {cellProps.row.original.subscription}
            </Badge>}
            {isLoading && <Skeleton className="mb-1" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)"></Skeleton>
            }
          </>;
        }
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return <>
            {!isLoading && <UncontrolledDropdown>
              <DropdownToggle tag="a" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18 text-yellow"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => {
                  const customerData = cellProps.row.original;
                  handleClickOnSubscription(customerData);
                }} className="d-flex align-items-center">
                  <i className={`mdi ${(cellProps.row.original.user_subscription_plan_uuid === null) ? 'mdi-calendar-plus' : 'mdi-calendar-edit'} font-size-16 me-2 dropdown-menu-icon`} id="edit_subscription"></i>
                  <span className="dropdown-menu-text">{cellProps.row.original.user_subscription_plan_uuid === null ? 'Create' : 'Edit'} subscription</span>
                  <UncontrolledTooltip placement="top" target="edit_subscription">
                    {cellProps.row.original.user_subscription_plan_uuid === null ? 'Create' : 'Edit'} subscription
                  </UncontrolledTooltip>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    handleUpdateCustomer(customerData);
                  }} className="d-flex align-items-center"
                >
                  <i className="mdi mdi-pencil font-size-16 text-success me-2 dropdown-menu-icon" id="edittooltip"></i>
                  <span className="dropdown-menu-text">Edit</span>
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    onClickDelete(customerData);
                  }} className="d-flex align-items-center">
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-2 dropdown-menu-icon" id="deletetooltip"></i>
                  <span className="dropdown-menu-text">Delete</span>
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            }
            {isLoading && <Skeleton className="mb-1" baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" width={20}></Skeleton>}
          </>
        }
      },
    ],
    [isLoading, isExtraSmallDevice, isExtraExtraSmallDevice, isMediumDevice]
  );

  // for toggle modal
  const toggle = () => {
    modal ? setModal(false) : setModal(true);
  };

  // for toggle 
  const toggleSubscriptionModal = () => {
    isSubscriptionModal ? setisSubscriptionModal(false) : setisSubscriptionModal(true);
  }

  // on create customer
  const handleCreateCustomerClicks = () => {
    setIsEdit(false);
    toggle();
  };

  // delete customer
  const onClickDelete = (customer) => {
    setIsDeleted(false);
    setDeleteModal(true);
    setCustomerUuid(customer.user_uuid);
  };

  // delete customer api call
  const isDeleteHandle = () => {
    customerService.deleteCustomer(customerUuid).then((response) => {
      if (response.status === 200) {
        setIsDeleted(true);
        getCustomerListInfo();
      }
    });
  }

  return (
    <React.Fragment>
      {/* delete customer modal */}
      <ConfirmationModal
        show={deleteModal}
        onCloseClick={() => setDeleteModal(false)}
        title={'Delete customer'}
        description={'Are you sure you want to delete this customer?'}
        onAction={() => isDeleteHandle()}
        afterAction={isDeleted}
        actionName={'Delete'}
      />
      <div className="page-content pb-0">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb breadcrumbItem="Customers" buttonName="Add customer" handleCreate={handleCreateCustomerClicks} />
          <Row>
            <Col xs="12" className="customer-list">
              <Card className="position-relative mb-0 list-card">
                {<CardBody>
                  {/* list table */}
                  {<TableContainer className='w-100'
                    columns={columns}
                    data={customerList.length < 6 && isLoading ? [0, 1, 2, 3, 5, 6] : customerList}
                    isPagination={false}
                    isAddOptions={true}
                    searchPlaceholder={'Search by name, email, shop name & email'}
                    tableClass="align-middle table-nowrap"
                    onSearch={onSearchHandler}
                    isLoading={isLoading}
                    listName={'customer-list'}
                  />}
                </CardBody>}
              </Card>
            </Col>
          </Row>
          {/* create customer modal */}
          <CreateCustomer isOpen={modal} isEdit={isEdit} close={() => setModal(false)} isSubmitted={() => getCustomerListInfo()} customer={customer} resetCustomerDetail={() => setCustomer('')}></CreateCustomer>
          <CreateSubscription user_subscription_plan_uuid={user_subscription_plan_uuid} isOpen={isSubscriptionModal} close={() => { setisSubscriptionModal(false); setIsSubscriptionEdit(false); }} isSubmitted={() => getCustomerListInfo()} user_uuid={customerUuid} isEdit={isSubscriptionEdit} />
        </Container>
      </div>
      {/* Modal for image preview */}
      <ImagePreviewModal imgSrc={previewImage} onCloseClick={() => setPreviewImage(null)}></ImagePreviewModal>
      <ToastContainer />
    </React.Fragment >
  );
}

export default withRouter(CustomerList);