import React from 'react';
import withRouter from './common/withRouter';

const NonAuthLayout = (props) => {

  return (
    <React.Fragment>{props.children}</React.Fragment>
  );
};

export default withRouter(NonAuthLayout);
