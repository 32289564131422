import React from "react";
import { useState } from "react";
import { Row, Col, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import withRouter from "../../../shared/components/common/withRouter";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
// navigation
import { Link, useNavigate } from "react-router-dom";
// validation error
import { forgotPswValidationErr } from '../../../shared/static/constants/constant';
// auth service
import { AuthenticationService } from '../../service/authentication.service';
// toast message
import { ToastContainer } from "react-toastify"
// loader
import Spinners from '../../../shared/components/common/spinner';
import './forgetPassword.scss';
import 'react-toastify/dist/ReactToastify.css';

const ForgetPasswordPage = props => {
  // loader
  const [isLoading, setLoading] = useState(false);
  // auth service
  const authenticationService = new AuthenticationService();
  // navigation
  const navigate = useNavigate();
  // form
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required(forgotPswValidationErr.ERR_EMAIL_REQUIRED)
        .email(forgotPswValidationErr.ERR_EMAIL_INVALID),
    }),
    // submit form
    onSubmit: (values) => {
      forgotPassword(values);
    }
  });

  // call forgot password api API 
  const forgotPassword = (user) => {
    setLoading(true);
    authenticationService.forgotPassword(user).then(response => {
      if (response.status === 200) {
        navigate("/reset-password");
        localStorage.setItem('user_email', JSON.stringify(user.email));
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <React.Fragment>
      <div className="forgot-password-page">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={6}>
              <Card className="overflow-hidden mb-0 py-5 px-3 px-lg-5">
                {/* title */}
                <div className="bg-white">
                  <Row className="justify-content-center">
                    <Col className="col-5 my-auto text-center col">
                      <img src="/assets/images/Print_Shop_Master_svg_logo.svg" alt="logo" className="img-fluid logo-img" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="py-0 px-0">
                  <div className="color-primary">
                    <h3 className="color-primary text-center mt-4">Forgot your password?</h3>
                    <h6 className="text-center mb-4 desc-text">Enter your email to get verification code</h6>
                  </div>
                  <div className="p-0">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {/* email */}
                      <div className="mb-3">
                        <Label className="form-label">Email <span className='text-danger'>*</span></Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                      {/* submit button */}
                      <div className="d-flex justify-content-between  align-items-center pt-3">
                        <p className='mb-0 '>

                          <Link to="/login" className="fw-medium  d-flex align-items-center back-to-login color-secondary">
                            <i className="bx bx-caret-left font-size-20"></i>
                            <span className="back-login-link">Back to Login</span>
                          </Link>{" "}
                        </p>
                        <div>
                          <button
                            className="btn btn-fill w-md "
                            type="submit"
                            disabled={!validation.isValid || !validation.dirty}
                          >
                            <div className='d-flex justify-content-center align-items-center'>
                              {/* loader */}
                              {isLoading ? <Spinners className="" setLoading={setLoading} /> : ''}
                              Submit
                            </div>
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* toast message */}
      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(ForgetPasswordPage);
