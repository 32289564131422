import { ApiService } from "../../shared/services/api.service";
import { DASHBOARD } from "../../shared/services/api.config";
export class DashboardService {
    apiservice = new ApiService()

    async adminDashboardStatisctics(user_uuid) {
        return this.apiservice.get(DASHBOARD.ADMIN_DASHBOARD_STATISCRICS.replace(/{user_uuid}/, user_uuid)).then((response) => {
            if (response.status === 200) {
                return response;
            }
            return response;
        })
    }

    async adminTotalSubscribersStatistics(user_uuid, year) {
        return this.apiservice.get(DASHBOARD.TOTAL_SUBSCRIBERS_STATISCTICS.replace(/{user_uuid}/, user_uuid) + `?year=${year}`).then((response) => {
            if (response.status === 200) {
                return response;
            }
            return response;
        })
    }

    // get customer dashboard statistic
    async getCustomerDashboardStatistic(user_uuid) {
        return  this.apiservice.get(DASHBOARD.GET_CUSTOMER_DASHBOARD_STATISTIC.replace(/{user_uuid}/, user_uuid)).then((response) => {
            if (response.status === 200) {
                return response;
            }
            return response;
        })
    }
    // get customer sent quote and invoice statistic
    async customerQuoteInvoiceStatistics(user_uuid, duration) {
        return  this.apiservice.get(DASHBOARD.GET_CUSTOMER_SENT_QUOTE_AND_INVOICE.replace(/{user_uuid}/, user_uuid) + `?durationFilter=${duration}`).then((response) => {
            if (response.status === 200) {
                return response;
            }
            return response;
        })
    }
}