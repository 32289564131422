
import { INVOICE, SUBSCRIPTION_PLAN, USERS, USER_SUBSCRIPTION_PLAN } from "../../shared/services/api.config";
import { ApiService } from '../../shared/services/api.service';
import { PASSWORD_SYMBOL } from '../../shared/static/constants/constant';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export class CustomerService {
    apiService = new ApiService();

    // get customer list
    async getCustomerList(serchText) {
        return await this.apiService.get(USERS.USER, { 'searchedText': serchText }).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    // for auto generate password
    randomGeneratePassword = async () => {
        let randomPassword = Math.random().toString(36).substring(3);
        const addSymbol = PASSWORD_SYMBOL[this.randomIntFromInterval(1, 5)];
        randomPassword = `${randomPassword.slice(0, 4)}${addSymbol || await this.randomGeneratePassword()}${randomPassword.slice(4)}`;
        return randomPassword;
    }

    randomIntFromInterval = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    // add customer
    async addCustomer(user) {
        return this.apiService.post(USERS.USER, user).then(response => {
            if (response.status === 200) {
                return response;
            } else {
                toast.error(response.response.data.errors[0].message, { autoClose: 4000 });
            }
            return response;
        }).catch(error => {
            toast.error(error.response.data.errors[0].message, { autoClose: 4000 });
            return error;
        });
    }

    // edit customer
    async editCustomer(uuid, data) {
        return this.apiService.put(`${USERS.USER}/${uuid}`, data).then(response => {
            if (response.status === 200) {
                return response;
            } else {
                toast.error(response.response.data.errors[0].message, { autoClose: 4000 });
            }
            return response;
        }).catch(error => {
            toast.error(error.response.data.errors[0].message, { autoClose: 4000 });
            return error;
        });
    }

    // get customer detail
    async getCustomerDetail(uuid) {
        return this.apiService.get(`${USERS.USER}/${uuid}`).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    // delete customer
    async deleteCustomer(uuid) {
        return this.apiService.delete(`${USERS.USER}/${uuid}`).then(response => {

            if (response.status === 200) {
                return response;
            } else {
                toast.error(response.response.data.errors[0].message, { autoClose: 4000 });
            }
            return response;
        }).catch(error => {
            toast.error(error.response.data.errors[0].message, { autoClose: 4000 });
            return error;
        });
    }

    // validate password
    async validatePassword(data) {
        return this.apiService.post(USERS.VALID_PASSWORD, data).then(response => {
            if (response.status === 200) {
                return response;
            } else {
                toast.error(response.response.data.errors[0].message, { autoClose: 4000 });
            }
            return response;
        }).catch(error => {
            toast.error(error.response.data.errors[0].message, { autoClose: 4000 });
            return error;
        });
    }

    async createUserSubscriptionPlan(subscriptionInfo) {
        return this.apiService.post(USER_SUBSCRIPTION_PLAN.CREATE_USER_SUBSCRIPTION_PLAN, subscriptionInfo).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async createUserSubscriptionPayment(subscriptionPaymentInfo) {
        return this.apiService.post(USER_SUBSCRIPTION_PLAN.CREATE_SUBSCRIPTION_PAYMENT, subscriptionPaymentInfo).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async getSubscriptionplanDetailByUserId(user_uuid) {
        return this.apiService.get(USER_SUBSCRIPTION_PLAN.GET_SUBSCRIPTION_DETAIL_BY_USER_ID.replace(/{user_uuid}/, user_uuid)).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async getSubscriptionPlanList() {
        return this.apiService.get(SUBSCRIPTION_PLAN.GET_SUBSCRIPTION_PLAN_LIST).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async getSubscriptionDiscountListbySubscriptionPanId(subscription_uuid) {
        return this.apiService.get(SUBSCRIPTION_PLAN.GET_DISCOUNT_INFO_BY_SUBSCRIPTION_ID.replace(/{subscription_uuid}/, subscription_uuid)).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async updateSubscriptionPlan(user_uuid, updatedSubscriptionInfo) {
        return this.apiService.put(USER_SUBSCRIPTION_PLAN.UPDATE_USER_SUBSCRIPTION_PLAN.replace(/{user_uuid}/, user_uuid), updatedSubscriptionInfo).then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;
        }).catch(error => {
            return error;
        });
    }

    async createCustomerCardPayment(credit_card_information) {
        return this.apiService.post(USERS.CREATE_CUSTOMER_CARD_PAYMENT_METHOD, credit_card_information).then((response) => {
            if (response.status === 200) {
                return response;
            }
            return response;
        });
    }

    async customerCardPaymentMethod(user_uuid) {
        return this.apiService.get(USERS.CUSTOMER_PAYMENT_METHOD_LIST.replace(/{user_uuid}/, user_uuid)).then((response) => {
            if (response.status === 200) {
                return response;
            }
            return response;
        });
    }

    async deleteCustomerCardPayment(credit_card_information) {
        return this.apiService.post(USERS.DELETE_CARD_PAYMENT_METHOD, credit_card_information).then((response) => {
            if (response.status === 200) {
                return response;
            }
            return response;
        });
    }

    async payInvoice(customer_info) {
        return this.apiService.post(INVOICE.PAY_INVOICE, customer_info).then((response) => {
            if(response.status === 200) {
                return response;
            }
            return response;
        });
    }
}