export class ImageURLTOBase64Convertor{
    // Utility function to convert an image URL to base64
    static convertImageUrlToBase64 = async (url) => {
        // Decode the URL and replace spaces with '+'
        const decodedUrl = decodeURIComponent(url).replace(/ /g, '+');
    
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                const blob = xhr.response;
                const reader = new FileReader();
                reader.onloadend = function () {
                    const base64Data = reader.result;
    
                    // Extract data type from the base64 data
                    const match = base64Data.match(/^data:(.*?);base64,(.*)$/);
                    if (match) {
                        const mimeType = match[1];
                        const data = match[2];
    
                        // Check if the MIME type is an image type
                        if (mimeType.startsWith('image/')) {
                            // Correct MIME type for images, so resolve with the base64 data
                            resolve(base64Data);
                        } else {
                            // If not an image, replace MIME type with the correct image type based on the URL extension
                            const extension = decodedUrl.split('.').pop().toLowerCase();
                            const imageMimeType = `image/${extension}`;
                            const correctedBase64Data = `data:${imageMimeType};base64,${data}`;
                            resolve(correctedBase64Data);
                        }
                    } else {
                        reject('Invalid base64 data format');
                    }
                };
                reader.readAsDataURL(blob);
            };
            xhr.onerror = () => reject(xhr.statusText);
            xhr.open("GET", decodedUrl);
            xhr.responseType = "blob";
            xhr.send();
        });
    };
}