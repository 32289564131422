import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Row, Col, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Switch from "react-switch";
import '../../../quoteCalculator/component/quoteCalculator/quoteCalculator.scss';
import Flatpickr from "react-flatpickr";
import 'flatpickr/dist/plugins/monthSelect/style.css';
import { invoiceCSVDownload } from "../../../invoices/model/invoice.model";

const Breadcrumb = props => {
    const [checked, setChecked] = useState(true);
    // for handle switch toggle
    const handleChange = nextChecked => {
        setChecked(nextChecked);
        props.onToggle(nextChecked);
    };

    // for download invoice csv button
    const [customDateRange, setCustomDateRange] = useState([]);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [selectedDownloadCSVDuration, setSelectedDownloadCSVDuration] = useState();

    // click on download csv menu
    const handleClickDownloadCSVMenu = (selectedDuration) => {
        setSelectedDownloadCSVDuration(selectedDuration);
        if (selectedDuration === 'Custom date') {
            setMenuIsOpen(true);
        } else {
            props.onselectedDuartionForDownload(selectedDuration);
            setSelectedDownloadCSVDuration(null);
        }
    }

    // toggle download csv dropdown menu
    const onToggleDownloadCSVDropdown = () => {
        if (selectedDownloadCSVDuration !== 'Custom date') {
            setMenuIsOpen(!menuIsOpen);
        }
    }

    return (
        <Row className={`${props.toggleView ? 'py-2' : ''}`}>
            <Col className="col-12">
                <div className={`page-title-box d-flex align-items-center justify-content-between ${props.toggleView ? 'pb-0' : ''} ${props.downloadInvoiceCSVButton ? 'page-title-invoice-list' : ''}`}>
                    <h4 id="step1" className="my-2 font-size-18">{props.breadcrumbItem}</h4>
                    <div className="page-title-right py-1">
                        <ol className={`breadcrumb m-0 d-flex align-items-center position-relative ${props.downloadInvoiceCSVButton ? 'breadcrumb-invoice-list' : ''}`}>
                            {props.buttonName && <Button
                                type="button"
                                color="success"
                                className="add-customer py-1"
                                onClick={props.handleCreate}
                            >
                                {props.buttonName !== 'Quick Tour' && <i className="mdi mdi-plus me-1" />}
                                {props.buttonName}
                            </Button>}
                            {/* download invoice csv button */}
                            {
                                props.downloadInvoiceCSVButton && <UncontrolledDropdown toggle={() => { onToggleDownloadCSVDropdown(); }} isOpen={menuIsOpen}>
                                    <DropdownToggle type="button" className="btn download-csv-btn ms-3">
                                        Download CSV <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-md mt-2">
                                        <DropdownItem onClick={() => { handleClickDownloadCSVMenu(invoiceCSVDownload.Today) }}>
                                            {invoiceCSVDownload.Today}
                                        </DropdownItem>
                                        <DropdownItem onClick={() => { handleClickDownloadCSVMenu(invoiceCSVDownload.Week) }}>
                                            {invoiceCSVDownload.Week}
                                        </DropdownItem>
                                        <DropdownItem onClick={() => { handleClickDownloadCSVMenu(invoiceCSVDownload.Year) }}>
                                            {invoiceCSVDownload.Year}
                                        </DropdownItem>
                                        <DropdownItem onClick={() => { handleClickDownloadCSVMenu('Custom date') }} toggle={false}>
                                            Custom date range
                                        </DropdownItem>
                                        {selectedDownloadCSVDuration === 'Custom date' && (
                                            <div className="pb-2 px-3 pt-0">
                                                <Flatpickr
                                                    value={customDateRange}
                                                    placeholder="Select range"
                                                    onChange={date => {
                                                        if (date.length === 2) {
                                                            setCustomDateRange(date);
                                                            props.onselectedDuartionForDownload(date);
                                                            setMenuIsOpen(false);
                                                            setSelectedDownloadCSVDuration(null);
                                                            setCustomDateRange([]);
                                                        }
                                                    }}
                                                    options={{
                                                        mode: 'range',
                                                        maxDate: new Date(),
                                                        altInput: true,
                                                        altFormat: "F j, Y",
                                                    }}
                                                    className="form-control mt-2"
                                                />
                                            </div>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            }
                            {/* quick tour button */}
                            {
                                props.quickTour &&
                                <>
                                    <i className="bx bx-info-circle me-1 quick-tour-icon" />
                                    <button type="button"
                                        color="success"
                                        className='py-1 quick-tour-btn pe-1 ps-3'
                                        onClick={props.handleQuickTour}>
                                        Quick Tour
                                    </button>
                                </>
                            }
                            {/* switch */}
                            {
                                props.toggleView &&
                                <Switch className="d-none d-md-block ms-3" onChange={handleChange}
                                    height={40}
                                    width={70}
                                    checked={checked}
                                    uncheckedIcon={
                                        <i className="bx bx-grid-vertical switch-icon"></i>
                                    }
                                    checkedIcon={
                                        <i className="bx bx-grid-horizontal switch-icon"></i>
                                    }
                                    handleDiameter={28}
                                />
                            }
                        </ol>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

Breadcrumb.propTypes = {
    breadcrumbItem: PropTypes.string,
    title: PropTypes.string,
    buttonName: PropTypes.string,
}

export default Breadcrumb
