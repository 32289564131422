const loginValidationErr = {
    ERR_EMAIL_REQUIRED: "Email is required!",
    ERR_EMAIL_INVALID: "Please enter valid email",
    ERR_PASSWORD_REQUIRED: "Password is required!",
    ERR_PASSWORD_INVALID: "Please enter valid password"
}

const forgotPswValidationErr = {
    ERR_EMAIL_REQUIRED: "Email is required!",
    ERR_EMAIL_INVALID: "Please enter valid email"
}

const resetPswValidationErr = {
    ERR_VERIFICATION_CODE_REQUIRED: 'Verification code is required!',
    ERR_VERIFICATION_CODE_INVALID: 'Please enter valid 6 digits verification code',
    ERR_PASSWORD_REQUIRED: "Password is required!",
    ERR_PASSWORD_INVALID: 'Please enter valid password',
    ERR_CONFIRM_PASSWORD_INVALID: 'New password and Confirm Password does not match',
    ERR_CONFIRM_PASSWORD_REQUIRED: 'Confirm password is required!',
    RESETPASSWORD_SUCCSSFULLY: 'Reset password successfully.'
}

const registerValidationErr = {
    ERR_EMAIL_REQUIRED: 'Email is required!',
    ERR_EMAIL_INVALID: "Please enter valid email",
    ERR_NAME_REQUIRED: 'Name is required!',
    ERR_NAME_INVALID: 'Please enter valid name',
    ERR_CITY_INVALID: 'Please enter valid city name',
    ERR_STATE_INVALID: 'Please enter valid state name',
    ERR_COUNTRY_INVALID: 'Please enter valid country name',
    ERR_PASSWORD_REQUIRED: "Password is required!",
    ERR_PASSWORD_INVALID: 'Please enter valid password',
    ERR_SHOPNAME_INVALID: 'Please enter valid shop name',
    ERR_CONTACT_NO_INVALID: 'Please enter valid contact number',
    ERR_SHOP_NAME_REQUIRED: 'Shop name is required!',
    ERR_SHOP_EMAIL_REQUIRED: 'Shop email is required!',
    ERR_SHOP_CONTACT_NUMBER_REQUIRED: 'Shop contct number is required!',
    ERR_INVALID_ZIP_CODE: 'Plaese enter a valid zip code!'
}

const saveQuoteModalErr = {
    ERR_JOB_NAME_REQUIRED: 'Job name is required!',
    ERR_CLIENT_REQUIRED:'Client name is required!',
    ERR_INVALID_CLIENT_NAME:'Please enter valid client name!',
    ERR_JOB_NAME_INVALID: 'Please enter valid Job name!',
    ERR_EXPIRED_AT_REQUIRED: 'Expired date is required!',
    ERR_TAX_REQUIRED: 'Tax is required!',
    ERR_NOTES_REQUIRED: 'Notes is required!',
    ERR_CLIENT_NAME_REQUIRED: 'Company name is required!',
    ERR_CLIENT_NAME_INVALID: 'Please enter valid name',
    ERR_CLIENT_EMAIL_REQUIRED: 'Company Email is required!',
    ERR_CLIENT_EMAIL_INVALID: "Please enter valid email",
    ERR_SALECT_CLIENT_NAME_REQUIRED: 'Please select client name!',
    ERR_JOB_NAME_LENGTH: 'Job name is too long it should be less then 40 characters!',
    ERR_ADD_LENGTH: 'Address is too long it should be less then 80 characters!',
}

const identityModalErr = {
    ERR_NAME_REQUIRED: 'Name is required!',
    ERR_NAME_INVALID: 'Please enter valid name',
    ERR_EMAIL_REQUIRED: 'Email is required!',
    ERR_EMAIL_INVALID: "Please enter valid email"
}

const Regex = {
    ONLY_CHAR: /^([aA-zZ]+(\s?[aA-zZ]+)+)$/,
    SHOP_NAME: /^(?![\s-])[^]+$/,
    MINIMUM_ONE_CHARACTER_STRING: /[a-zA-Z]/,
    ZIP_CODE: /(^\d{5}$)|(^\d{5}-\d{4}$)/
}

const AWS_S3_URL = "https://printshopmaster-media.s3.us-east-1.amazonaws.com/";

const PASSWORD_SYMBOL = ['!', '@', '#', '%', '&'];

const QUOTE_CALCULATOR = {
    ERR_INVALID_ITEM_NAME: 'Item name can not be only numeric.',
    ITEM_ADDED_TO_QUOTE: 'Item added to quote successfully!',
    QUOTE_CONFIRMATION_MODAL_MESSAGE: "Are you sure you want to close without saving quote? items added to the quote won't get saved.",
    ITEM_ADDED_TO_SAVE_QUOTE: 'Item added to {job_name} successfully!',
    NAVIGATE_AWAY_EDIT_MODE: 'Are you sure you want to close without Adding item? Information added to quote calculator won’t get saved.',
    NAVIGATE_AWAY_ADD_MODE: 'Are you sure you want to close without saving quote? Items added to the quote won’t get saved.',
    NAVIGATE_AWAY_WITH_NO_ITEM_IN_QUOTE: "You haven't added any items to the quote. Are you sure you want to leave this page without adding item(s)?"
}

const NEW_TAX = {
    ERR_TAX_NAME_REQUIRED: 'Tax Name is required!',
    ERR_TAX_RATE_REQUIRED: 'Tax Rate is required!',
    ERR_INVALID_TAX_VALUE: 'Tax Rate must be greater than or equal to 0!',
    ERR_INVALID_TAX_NAME: 'Please enter valid Tax Name. it can not be only numeric!',
    ERR_INVALID_TAX_MAX_LENGTH: 'Tax Rate must be less then 100 or equal to 100!'
}

const NEW_RECIPIENT = {
    ERR_NAME_REQUIRED: 'Company name is required!',
    ERR_INVALID_NAME: 'Company name is not valid!',
    ERR_EMAIL_REQUIRED: 'email is required!',
    ERR_INVALID_EMAIL: 'email is not valid!',
    ERR_ADD_LENGTH: 'Address is too long it should be less then 80 characters!',
    ERR_CLIENT_NAME_REQUIRED: 'Client name is required!',
    ERR_INVALID_CLIENT_NAME: 'Client name is not valid!'
}

const UPDATE_BANK_ACCOUNT = {
    ERR_INVALID_ACCOUNT_HOLDER_NAME: 'Please enter valid account holder name!',
    ERR_ACCOUNT_NUMBER_REQUIRED: 'Account number is required!',
    ERR_INVALID_ACCOUNT_NUMBER: 'Please enter valid account number!',
    ERR_COUNTRY_CODE_REQUIRED: 'Country code is required!',
    ERR_INVALID_COUNTRY_CODE: 'Please enter valid country code!',
    ERR_INVALID_ROUTING_NUMBER: 'Please enter valid routing number!'
}

const PREVIEW_INVOICE = {
    ERR_ITEM_NAME_REQUIRED: 'Item name is required!',
    ERR_REQUIRED_ITEM_QUANTITY: 'Item quantity is required!',
    ERR_INVALID_ITEM_QUANTITY: 'Item quantity must be greater then 0!',
    ERR_REQUIRED_ITEM_RATE: 'Item rate is required!',
    ERR_INVALID_ITEM_RATE: 'Item rate must be greater then 0!',
}

const PRINTSHOP_SUPPORT_EMAIL = 'info@theprintshopmaster.com';

const PREVIEW_QUOTE = {
    ERR_REQUIRED_JOB_NAME: 'Job Name is required!',
    ERR_INVALID_JOB_NAME_LENGTH: 'Job name is too long it should be less then 40 characters!',
    ERR_REQUIRED_ITEM_DESCRIPTION: 'Item name is required!',
    ERR_REQUIRED_ITEM_QUANTITY: 'Item quantity is required!',
    ERR_INVALID_ITEM_QUANTITY: 'Item quantity must be greater then 0!',
    ERR_REQUIRED_ITEM_RATE: 'Item rate is required!',
    ERR_INVALID_ITEM_RATE: 'Item rate must be greater then 0!',
}

const calculatorQuickTourSteps = {
    step1: `<p class="mb-0">To make quoting embroidery jobs easier, the pricing for the calculations below is set to industry standards with the options of customizing the pricing to what works best for your shop.</p></br><span>Enjoy inputting your information with ease and knowing exactly what your profit is on each embroidery job.</span>`,
    step2: `Input the garment name.`,
    step3: `Input the number of items for what is being quoted.`,
    step4: `Add your tax rate according to your location and tax regulations.`,
    step5: `<p class="mb-0">Input the Garment Quantity and use additional tables for items that cost more and their individual prices.</p></br><p class="mb-0">This will gather your total garment cost.</p></br><span>Use the overages section to add any garments that you may need in case of a machine / or error in your shop.</span>`,
    step6: `<p class="mb-0">Pre-defined stitch count pricing is based on the industry standard.</p></br><p class="mb-0">Formula is setup to account for .25/1000 stitches.</p></br><p class="mb-0">Input the estimated (or exact) stitch count for your job.</p></br><p class="mb-0">If you have multiple locations, use the additional tables to input each locations stitch count.</p></br><span>For specialty items such as 3D Puff, Applique and a possible Custom Charge, it is intended that you put in your item quantity (not stitch count) with the pre-defined pricing.</span>`,
    step7: `<p class="mb-0">Set your rate for your Digitizing fee (per design or can use as hourly input) and artwork fee.</p></br><p class="mb-0"><strong>IMPORTANT:</strong> Your RPM is important for the calculator to calculate your machine run time.</p></br><p class="mb-0">Locations will also be calculated within your Hooping fee that is based on a price scale based on industry standard pricing for hooping fees.</p></br><span>Please note that at the end of your quote you will be able to adjust your pricing.</span>`,
    step8: `<p class="mb-0">If you run different embroidery machines with different number of heads you will change that in our Embroidery Head specification cell.</p></br><p class="mb-0">This will change your garments/hour in your job.</p></br><p class="mb-0">You will see how long a job will take you.</p></br><span>This is based off your stitch count, RPM, Location and Embroidery Heads.</span>`,
    step9: `<p class="mb-0">Change the markup percentages to what works best for your shop.</p></br><span>This calculator is setup for the "standard" pricing to be a 50% markup.</span>`,
    step10: `<p class="mb-0">See in real time your markup percentage when you enter in your sales price.</p></br><span>When the sales price is entered you will be able to see the top table change to see the Gross, Net and Hourly Rate for the job.</span>`,
    step11: `Calculates materials cost/garment used in the job.`,
    step12: `Calculates all the materials cost used in the job.`,
    step13: `What the customer will be charged.`,
    step14: `Gross sale minus the cost of the job.`,
    step15: `The gross divided by the amount of hours your job will take.`,
    step16: `The net divided by the amount of hours your job will take.`,
    step17: `<p class="mb-0">Once you have entered your desired sales price click "add item to quote".</p></br><span>Once you add the item if you have multiple different items in a job you can quote your next item and add it to the quote that has already been created.</span>`,
    step18: `The total of line items you have added to the final quote that you will send to client.`,
    step19: `Once you have added all items to your quote select "Save Quote" This will appear in your Quotes section ready to review and send to client.`,
    resetCalculator: `clear information added to quote calculator.`,
    description: `Input the garment description.`
}

const CREDIT_CARD_INFO = {
    ERR_REQUIRED_CARD_NUMBER: 'Card number is required!',
    ERR_INVALID_CARD_NUMBER: 'Please enter valid card number!',
    ERR_REQUIRED_CVC_NUMBER: 'CVC number is required!',
    ERR_INVALID_CVC_NUMBER: 'CVC number should be minimum 3 digit long!',
}

const TAX_RATE = 8.25;

const TAX_NAME = 'Sales Tax';

const SUBSCRIBERS_EVOLUTION_START_YEAR = '2024';

const PRICINGPAGE_DESCRIPTION = "Discover the subscription option that suits you best! Whether you prefer the freedom of monthly payments or the savings of an annual commitment, we've got you covered.";

const paymentMessage = {
    SUBSCRIPTION_SUCCESSFULL: 'Congratulations!! Your subscription created successfully.',
    SUBSCRIPTION_PAYMENT_FAILED: 'Hi {customer_name}, {payment_failure_message}',
    SUBSCRIPTION_PAYMENT_FAILED_MESSAGE: 'Unfortunetly we were unable charge your {card_type} card ending in {card_number} for your subscription payment.',
    INVOICE_PAYMENT_SUCCESSFULL: 'Congratulations on the successful payment of your invoice! We appreciate your business with the Print Shop.',
    INVOICE_PAYMENT_FAILD: 'Hi {customer_name}, {payment_failure_message}',
    INVOICE_PAYMENT_FAILED_MESSAGE: 'Unfortunetly we were unable charge your {card_type} card ending in {card_number} for your invoice payment.'
}

const SUBSCRIPTION_PLAN_UUID = {
    BASIC_PALN: '413472f1-caf2-4977-bc96-a1ac46ec38ad',
    STANDARD_PLAN: 'e055e1b0-92b9-4e31-96b4-e4c0b4731145'
}

export {
    loginValidationErr,
    forgotPswValidationErr,
    resetPswValidationErr,
    registerValidationErr,
    Regex,
    AWS_S3_URL,
    PASSWORD_SYMBOL,
    saveQuoteModalErr,
    QUOTE_CALCULATOR,
    NEW_TAX,
    calculatorQuickTourSteps,
    PREVIEW_QUOTE,
    identityModalErr,
    NEW_RECIPIENT,
    PREVIEW_INVOICE,
    CREDIT_CARD_INFO,
    PRINTSHOP_SUPPORT_EMAIL,
    SUBSCRIBERS_EVOLUTION_START_YEAR,
    PRICINGPAGE_DESCRIPTION,
    paymentMessage,
    UPDATE_BANK_ACCOUNT,
    TAX_RATE,
    TAX_NAME,
    SUBSCRIPTION_PLAN_UUID
}