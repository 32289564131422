import React from "react";
import { Navigate } from "react-router-dom";

// Profile
import UserProfile from "../module/profile/component/userProfile/userProfile";

// Authentication related pages
import Login from "../module/authentication/component/login/login";
import Register from "../module/authentication/component/register/register";
import ForgetPwd from "../module/authentication/component/forgetPassword/forgetPassword";
import ResetPassword from "../module/authentication/component/resetPassword/resetPassword";

// Dashboard
import Dashboard from "../module/dashboard/dashboard";
// customer list
import CustomerList from "../module/customers/component/customer-list/customerList"
import QuoteCalculator from "../module/quoteCalculator/component/quoteCalculator/quoteCalculator";
import PreviewQuote from "../module/quotes/component/previewQuote/previewQuote";
import QuoteList from "../module/quotes/component/quoteList/quoteList";
import PricingPage from "../module/shared/components/common/pricingPage";
import PeopleList from "../module/customersClientList/component/peopleList/peopleList";
import GetPaid from "../module/shopOwnerGetPaid/component/getPaid/getPaid";

// for standalone
import StandaloneQuotePreview from "../module/standalone/quote/standaloneQuotePreview";
import InvoiceList from "../module/invoices/component/invoiceList/invoiceList";
import PreviewInvoice from "../module/invoices/component/previewInvoice/previewInvoice";
import StandaloneInvoicePreview from "../module/standalone/invoice/standaloneInvoicePreview";
import PaymentPage from "../module/shared/components/common/paymentPage";
import ShopOwnerStripeConnectRefresh from "../module/standalone/ShopOwnerStripeConnectRefresh/ShopOwnerStripeConnectRefresh";
import ShopOwnerStripeConnectReturn from "../module/standalone/return-payment/ShopOwnerStripeConnectReturn";
import TaxDeductionList from "../module/taxdeduction/component/collctedTaxAmountList/taxDeductionList";

// for landing page
import LandingPage from "../module/landingPage/component/landingPage";

const routesForAllFeatures = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/quote-calculator", component: <QuoteCalculator /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/preview-quote", component: <PreviewQuote /> },
  { path: "/quote-list", component: <QuoteList /> },
  { path: "/pricing", component: <PricingPage /> },
  { path: "/pay-subscription", component: <PaymentPage /> },
  { path: "/people", component: <PeopleList /> },
  { path: "/invoice-list", component: <InvoiceList /> },
  { path: "/preview-invoice", component: <PreviewInvoice /> },
  { path: "/get-paid", component: <GetPaid /> },
  { path: "*", component: <Navigate to="/quote-calculator" /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/quote-calculator" />,
  },
];

const routesForBasicPlan = [
  { path: "/quote-calculator", component: <QuoteCalculator /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/pricing", component: <PricingPage /> },
  { path: "/pay-subscription", component: <PaymentPage /> },
  { path: "*", component: <Navigate to="/quote-calculator" state={{ showMiddleware: true }} /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/quote-calculator" />,
  },
]

// for admin role
const authProtectedRoutesAdmin = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/customer-list", component: <CustomerList /> },
  { path: "/tax-deduction", component: <TaxDeductionList /> },
  { path: "*", component: <Navigate to="/dashboard" /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

// for authentication route
const publicRoutes = [
  // {
  //   path: "/",
  //   exact: true,
  //   component: <LandingPage />,
  // },
  { path: "/", component: <LandingPage /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/reset-password", component: <ResetPassword /> },
  { path: "*", component: <Navigate to="/" /> },
  { path: "/quote/:quoteID", component: <StandaloneQuotePreview /> },
  { path: '/invoice/:invoiceID', component: <StandaloneInvoicePreview /> },
  { path: '/connect-refresh/:user_uuid', component: <ShopOwnerStripeConnectRefresh /> },
  { path: '/connect-return/:user_uuid', component: <ShopOwnerStripeConnectReturn /> }
];

export { authProtectedRoutesAdmin, publicRoutes, routesForBasicPlan, routesForAllFeatures };
