// for quote expiration status
const QuoteExpirationStatus = {
    All: 'All',
    Expired: 'Expired',
    NotExpired: 'Not expired'
}

// for quote status
const QuoteStatus = {
    All: 'All statuses',
    Draft: 'Draft',
    Sent: 'Sent',
    Read: 'Read',
    Approved: 'Approved',
    Declined: 'Declined',
}

export {
    QuoteExpirationStatus,
    QuoteStatus
}


