import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import { useTable, useExpanded, useSortBy } from "react-table";
import Select from "react-select";
import { Table, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css';
// style
import '../../../customers/component/customer-list/customerList.scss';
import '../../../quotes/component/quoteList/quoteList.scss';
import '../../../customersClientList/component/peopleList/peopleList.scss';
import { QuoteExpirationStatus } from "../../../quotes/model/quote.model";
import { QuoteStatus } from "../../../quotes/model/quote.model";
import { invoiceStatus } from "../../../invoices/model/invoice.model";
import { PeopleType } from "../../../customersClientList/model/people.model";


const TableContainer = ({
  columns,
  data,
  onSearch,
  selectedValue,
  selectedStatusValue,
  TOTAL_COLLECTED_AMOUNT,
  selectedClientValue,
  selectedPeopleTypeValue,
  tableClass,
  theadClass,
  isLoading,
  searchPlaceholder,
  customerQuoteClientList,
  listName
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [],
      },
    },
    useSortBy,
    useExpanded,
    hooks => {
      hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        headerGroups.forEach(headerGroup => {
          headerGroup.headers.forEach(column => {
            column.getSortByToggleProps = () => ({
              onClick: (e) => {
                e.preventDefault();
                toggleSortByOriginal(column);
              },
              style: {
                cursor: 'pointer',
              },
            });
          });
        });
      });
    }
  );

  const toggleSortByOriginal = (column) => {
    if (listName === 'invoice-list' && column.id !== 'due_date') {
      const isCurrentlySorted = column.isSorted;
      const isCurrentlySortedDesc = column.isSortedDesc;
      const sortState = isCurrentlySorted ? (isCurrentlySortedDesc ? 'desc' : 'asc') : 'none';

      // Cycle through the sort states: 'none' -> 'asc' -> 'desc'
      let nextSortState;
      switch (sortState) {
        case 'none':
          nextSortState = 'asc';
          break;
        case 'asc':
          nextSortState = 'desc';
          break;
        case 'desc':
        default:
          nextSortState = 'none';
          break;
      }

      // Apply the new sort state
      if (nextSortState === 'none') {
        column.clearSortBy();
      } else {
        column.toggleSortBy(nextSortState === 'desc');
      }
    }
  };


  // navigation
  const navigate = useNavigate();
  // search  
  const [searchText, setSearchText] = useState('');
  const handleSearchCustomer = (event) => {
    onSearch(event.target.value);
    (listName && listName === 'quote-list') && selectedValue(selectedExpiredStatus);
    (listName && listName === 'quote-list') && selectedStatusValue(selectedExpiredStatus);
  };

  const handleChange = (selectedDates) => {
    if (selectedDates.length > 0) {
      const selectedMonth = selectedDates[0].getMonth() + 1;
      const selectedYear = selectedDates[0].getFullYear();
      selectedValue({ month: selectedMonth, year: selectedYear });
      onSearch(searchText);
    }
  }

  // for filter
  const [selectedExpiredStatus, setselectedExpiredStatus] = useState({ label: QuoteExpirationStatus.NotExpired, value: QuoteExpirationStatus.NotExpired });
  const [selectedClient, setselectedClient] = useState({ label: 'All client', value: "All client" });
  const [selectedStatus, setselectedStatus] = useState(listName === 'quote-list' ? { label: QuoteStatus.All, value: QuoteStatus.All } : { label: invoiceStatus.All, value: invoiceStatus.All });
  const [selectedPeopleType, setselectedPeopleType] = useState({ label: PeopleType.All, value: PeopleType.All });

  (listName && listName === 'quote-list') && selectedValue(selectedExpiredStatus);
  (listName && listName === 'quote-list') && selectedStatusValue(selectedStatus);
  // on select expired status
  function handleSelectGroup(selectedGroup) {
    setselectedExpiredStatus(selectedGroup);
    selectedValue(selectedGroup);
  }

  // on select client
  function handleSelectedClient(selectedClient) {
    setselectedClient(selectedClient);
    selectedClientValue(selectedClient);
  }

  // on select status
  function handleSelectStatusGroup(selectedGroup) {
    setselectedStatus(selectedGroup);
    selectedStatusValue(selectedGroup);
  }

  // on select status
  function handlePeopleType(selectedGroup) {
    setselectedPeopleType(selectedGroup);
    selectedPeopleTypeValue(selectedGroup);
  }

  return (
    <Fragment>
      <Row className="mb-2">
        <Col className="col-12">
          {
            (listName && listName !== 'tax-list') ? (
              <Row>
                <Col className="col-12 col-md-3">
                  {/* search */}
                  <div className='position-relative'>
                    <input type="search" className="form-control list-search mb-1 mb-md-0 d-lg-inline" value={searchText} placeholder={'Search'} onChange={e => { setSearchText(e.target.value); handleSearchCustomer(e) }} />
                    <i className="bx bx-info-circle cursor-pointer text-yellow position-absolute search-info-icon" id="search-bar">
                      <div className="search-tooltip">
                        {searchPlaceholder}
                      </div>
                    </i>
                  </div>
                </Col>
                {/* for filter */}
                <Col className="col-12 col-md-9">
                  {(listName && listName === 'quote-list') &&
                    <Row className="select-section">
                      <Col className="col-12 col-md-4">
                        <Select
                          value={selectedExpiredStatus}
                          onChange={(selectedGroup) => { handleSelectGroup(selectedGroup); }}
                          options={[{ label: QuoteExpirationStatus.All, value: QuoteExpirationStatus.All },
                          { label: QuoteExpirationStatus.Expired, value: QuoteExpirationStatus.Expired },
                          { label: QuoteExpirationStatus.NotExpired, value: QuoteExpirationStatus.NotExpired }]}
                          className="select2-selection quote-list-select"
                        />
                      </Col>
                      <Col className="col-12 mt-1 mt-md-0 col-md-4">
                        <div className="quote-list-select d-block d-md-flex">
                          <Select
                            value={selectedClient}
                            onChange={(selectedClient) => { handleSelectedClient(selectedClient); }}
                            options={customerQuoteClientList?.map((e, key) => {
                              return { label: e?.client_name, value: e?.client_name }
                            })}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col className="col-12 mt-1 mt-md-0 col-md-4">
                        <div className="quote-list-select d-block d-md-flex">
                          <Select
                            value={selectedStatus}
                            onChange={(selectedStatus) => { handleSelectStatusGroup(selectedStatus); }}
                            options={[{ label: QuoteStatus.All, value: QuoteStatus.All },
                            { label: QuoteStatus.Draft, value: QuoteStatus.Draft },
                            { label: QuoteStatus.Sent, value: QuoteStatus.Sent },
                            { label: QuoteStatus.Read, value: QuoteStatus.Read },
                            { label: QuoteStatus.Approved, value: QuoteStatus.Approved },
                            { label: QuoteStatus.Declined, value: QuoteStatus.Declined },]}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row>}
                  {(listName && listName === 'invoice-list') && <Row className="select-section">
                    <Col className="col-12 mt-1 mt-md-0 col-md-4">
                      <div className="quote-list-select d-block d-md-flex">
                        <Select
                          value={selectedClient}
                          onChange={(selectedClient) => { handleSelectedClient(selectedClient); }}
                          options={customerQuoteClientList?.map((e, key) => {
                            return { label: e?.client_name, value: e?.client_name }
                          })}
                          className="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col className="col-12 mt-1 mt-md-0 col-md-4">
                      <div className="quote-list-select d-block d-md-flex">
                        <Select
                          value={selectedStatus}
                          onChange={(selectedStatus) => { handleSelectStatusGroup(selectedStatus); }}
                          options={[{ label: invoiceStatus.All, value: invoiceStatus.All },
                          { label: invoiceStatus.Paid, value: invoiceStatus.Paid },
                          { label: invoiceStatus.Sent, value: invoiceStatus.Sent },
                          { label: invoiceStatus.Overdue, value: invoiceStatus.Overdue },
                          { label: invoiceStatus.Read, value: invoiceStatus.Read },
                          { label: invoiceStatus.Draft, value: invoiceStatus.Draft },
                          { label: invoiceStatus.Cancelled, value: invoiceStatus.Cancelled },]}
                          className="select2-selection"
                        />
                      </div>
                    </Col>
                  </Row>}
                  {(listName && listName === 'people-list') &&
                    <Row className="select-section">
                      <Col className="col-12 mt-1 mt-md-0 col-md-4">
                        <div className="people-list-select quote-list-select d-block d-md-flex">
                          <Select
                            value={selectedPeopleType}
                            onChange={(selectedPeopleType) => { handlePeopleType(selectedPeopleType); }}
                            options={[{ label: PeopleType.All, value: PeopleType.All },
                            { label: PeopleType.Lead, value: PeopleType.Lead },
                            { label: PeopleType.Client, value: PeopleType.Client },
                            { label: PeopleType.Other, value: PeopleType.Other },]}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row>
                  }
                </Col>
              </Row>
            )
              : (
                <Row>
                  <Col className="col-12 col-md-3">
                    {/* search */}
                    <div className='position-relative'>
                      <input type="search" className="form-control list-search mb-1 mb-md-0 d-lg-inline" value={searchText} placeholder={'Search'} onChange={e => { setSearchText(e.target.value); handleSearchCustomer(e) }} />
                      <i className="bx bx-info-circle cursor-pointer text-yellow position-absolute search-info-icon" id="search-bar">
                        <div className="search-tooltip">
                          {searchPlaceholder}
                        </div>
                      </i>
                    </div>
                  </Col>
                  <Col className="col-12 col-md-9">
                    <Row className="mt-3 mt-md-0">
                      <Col className="col-12 col-md-1 col-lg-3"></Col>
                      <Col className=" col-12 col-lg-6 mb-3 mb-md-0 col-md-6 d-flex align-items-center justify-content-md-end justify-content-start">
                        <p className="m-0"><span className="text-muted">Total collected tax amount:</span>  <span className="fw-bold text-success">${TOTAL_COLLECTED_AMOUNT}</span>  </p>
                      </Col>
                      <Col className="col-12  col-lg-3 col-md-5">
                        <Flatpickr
                          className="form-control duration"
                          options={{
                            plugins: [
                              new monthSelectPlugin({
                                shorthand: true,
                                dateFormat: 'M Y',
                                altFormat: 'F Y',
                              })
                            ],
                            minDate: '2024-01-01',
                            defaultDate: new Date(new Date().getFullYear(), new Date().getMonth()),
                            maxDate: new Date(new Date().getFullYear(), new Date().getMonth()),
                            onChange: handleChange,
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )
          }
        </Col>
      </Row >
      {/* customer list table */}
      <div div className="table-responsive" >
        <Table {...getTableProps()} className={tableClass}>
          <thead className={`table-sticky-header ${theadClass}`}>
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id} className={column.isSort ? `sorting table-body-font col-${column.id}` : `table-body-font col-${column.id}`} >
                    <div className="m-0 d-flex align-items-center"  {...(listName === 'invoice-list' && column.id === 'due_date' ? column.getSortByToggleProps() : {})}>
                      {column.render("Header")}
                      {column.id === 'due_date' && listName === 'invoice-list' && (
                        <span className="sort-icons ms-2 d-flex flex-column align-items-center mb-1">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <i className="bx bx-caret-down sort-icon-height text-dark"></i>
                            ) : (
                              <i className="bx bx-caret-up sort-icon-height text-dark"></i>
                            )
                          ) : (
                            <>
                              <i className="bx bx-caret-up sort-icon-height text-muted"></i>
                              <i className="bx bx-caret-down sort-icon-height text-muted"></i>
                            </>
                          )}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {/* table body */}
          {
            <>
              {(
                <tbody {...getTableBodyProps()}>
                  {rows.map(row => {
                    prepareRow(row)
                    return (
                      <Fragment key={row.getRowProps().key}>
                        <tr className={`${(listName && listName === 'quote-list') || (listName && listName === 'invoice-list') ? 'cursor-pointer' : ''}`} onClick={() => {
                          const prefix = "row_";
                          if (listName && listName === 'quote-list') {
                            // navigate(`/preview-quote/${data[parseInt(row.getRowProps().key.substring(prefix.length), 10)].quote_uuid}`);
                            navigate('/preview-quote', { state: { mode: 'previewQuote', quote_uuid: data[parseInt(row.getRowProps().key.substring(prefix.length), 10)].quote_uuid } })
                          } if (listName && listName === 'invoice-list') {
                            navigate('/preview-invoice', { state: { mode: 'previewInvoice', invoice_uuid: data[parseInt(row.getRowProps().key.substring(prefix.length), 10)].invoice_uuid } })
                          }
                        }}>
                          {row.cells.map(cell => {
                            return (
                              <td className={`table-body-font text-wrap position-relative col-${cell.column.id}`} key={cell.id} {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            )
                          })}
                        </tr>
                      </Fragment>
                    )
                  })}
                </tbody>
              )}
            </>
          }
        </Table>
        {/* handle case when customer is not available */}
        {
          (data.length === 0 && !isLoading) && (
            <>
              <div className="nouser_avaibale_container">
                <i className="bi bi-info-circle me-2"></i>
                <span>No Records Found.</span>
              </div>
            </>
          )
        }
      </div >
    </Fragment >
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer