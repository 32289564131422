// for quote status
const invoiceStatus = {
    All: 'All statuses',
    Paid: 'Paid',
    Sent: 'Sent',
    Read: 'Read',
    Draft: 'Draft',
    Cancelled: 'Cancelled',
    Overdue: 'Overdue'
}

const invoiceCSVDownload = {
    Today: 'Today',
    Week: 'Week',
    Month: 'Month',
    Year: 'Year'
}

export {
    invoiceStatus,
    invoiceCSVDownload
}
