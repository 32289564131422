import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
// css
import './pricingPage.scss'
import { CustomerService } from "../../../customers/service/customer.service";
import { useNavigate } from "react-router-dom";
import { PRICINGPAGE_DESCRIPTION, SUBSCRIPTION_PLAN_UUID } from "../../static/constants/constant";

const PricingPage = props => {
    const customerservice = new CustomerService();
    const [isLoading, setisLoading] = useState(false);
    const [subscriptionPlanList, setsubscriptionPlanList] = useState([]);
    const [selectedPlanDuration, setSelectedPlanDuration] = useState('yearly');
    const navigate = useNavigate();

    useEffect(() => {
        setisLoading(true);
        customerservice.getSubscriptionplanDetailByUserId(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
            if (response.status === 200) {
                if (response.data.subscription_expired === 1) {
                    getDetails();
                } else {
                    if ((response.data.subscription_exists === 1 && response.data.subscription_payment_succeeded === 1) || (response.data.subscription_exists === 1 && response.data.subscription_payment_succeeded === 0)) {
                        response.data.subscription_plan_details.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.STANDARD_PLAN ? navigate("/dashboard") : navigate('/quote-calculator');
                    } else {
                        getDetails();
                    }
                }
            }
        })
    }, []);

    const getDetails = () => {
        customerservice.getSubscriptionPlanList().then((response) => {
            if (response.status === 200) {
                setsubscriptionPlanList(response.data);
            }
        }).finally(() => {
            setisLoading(false);
        });
    }

    return (
        <React.Fragment>
            <div className="page-content pb-0 pricing-page">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <div className="text-center mb-5">
                                {/* heading */}
                                <h4>Choose your Pricing plan</h4>
                                {/* description */}
                                <p className="text-muted mb-0 mt-4">
                                    {PRICINGPAGE_DESCRIPTION}
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md={12} xl={6}>
                            <div className="d-sm-flex justify-content-end align-items-center mb-4">
                                <div className="d-flex justify-content-end">
                                    <div className={`p-2 ${selectedPlanDuration === 'monthly' ? 'selected-duration' : 'duration-label'} cursor-pointer `} onClick={() => { setSelectedPlanDuration('monthly'); }} >
                                        <span>Monthly</span>
                                    </div>
                                    <div className={`p-2 ${selectedPlanDuration === 'yearly' ? 'selected-duration' : 'duration-label'} cursor-pointer`} onClick={() => { setSelectedPlanDuration('yearly'); }}>
                                        <span>Yearly</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {
                        !isLoading ? (
                            <Row className="justify-content-center">
                                {subscriptionPlanList && subscriptionPlanList.map(plan => (
                                    <Col key={plan.subscription_plan_uuid} className="mb-4 mb-md-0" xxl="3" xl="4" md="6">
                                        <Card className={`plan-box mb-0 pricing-card ${plan.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.BASIC_PALN ? '' : 'pricing-standard-card'}`}>
                                            <CardBody className="p-4">
                                                <div>
                                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                                        <h5 className="d-inline mb-0">{plan.plan_name}</h5>
                                                        <i className={`bx ${plan.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.BASIC_PALN ? 'bx-walk' : 'bx-run'}  h1 text-yellow mb-0`} style={{ lineHeight: 1 }} />
                                                    </div>
                                                    <div className={`${plan.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.BASIC_PALN ? 'plan-description-container' : ''} `}>
                                                        <p className="text-muted mt-2 mb-0">{plan.plan_description}</p>
                                                    </div>
                                                </div>
                                                <div className="py-4 mt-1">
                                                    <h2 className="price">
                                                        ${selectedPlanDuration === 'monthly' ? plan.price_per_month : plan.price_per_year}/{" "}
                                                        <span className="font-size-13">{selectedPlanDuration === 'monthly' ? 'month' : 'year'} </span>
                                                    </h2>
                                                </div>
                                                <div className="plan-features text-muted mt-1 pt-2">
                                                    {
                                                        plan.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.BASIC_PALN ? <><p className="mb-1">
                                                            <i className="bx bx-check color-primary me-2" />{" "}
                                                            Quote calculator
                                                        </p></> : <>
                                                            <p className="mb-1"><i className="bx bx-check color-primary me-2" />{" "} Dashboard</p>
                                                            <p className="mb-1"><i className="bx bx-check color-primary me-2" />{" "} Quote calculator </p>
                                                            <p className="mb-1"><i className="bx bx-check color-primary me-2" />{" "} Quotes </p>
                                                            <p className="mb-1"><i className="bx bx-check color-primary me-2" />{" "} Invoices </p>
                                                        </>
                                                    }
                                                </div>
                                                <div className="text-center plan-btn mt-4">
                                                    <button className="btn btn-fill btn-sm waves-effect waves-light pay-now-btn" onClick={() => {
                                                        navigate('/pay-subscription', { state: { planInfo: plan, selectedDuration: selectedPlanDuration, isPaymentMode: false } })
                                                    }}>Choose plan</button>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        ) : (
                            <Spinner className='me-2 position-absolute download-quote-spinner' />
                        )
                    }
                </Container>
            </div>
        </React.Fragment >
    )
}


export default PricingPage;